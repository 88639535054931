import React from 'react'
import "./ExchangeDataCard.css"
import ExchangeDataRow from '../ExchangeDataRow/ExchangeDataRow'
import starIcon from "../../../../Assets/new-exchange/Exchange-sports-images/starIcon.png"
import MatchOddsDataRow from '../MatchOddsDataRow/MatchOddsDataRow'
const ExchangeDataCard = ({ exchangeDataCardTitle, showCashout, showIcon, rowData, gameCode, gameId, marketType }) => {
    return <>

        <div className="exchange-data-card">
            {/* card header --------------------------- */}
            <div className="exchange-data-card-header">
                <div className="exchange-data-card-content">
                    <div className="exchange-data-card-label">
                        {showIcon && <img className='starIcon' src={starIcon} alt='icon is loading' />}
                        <div className="exchange-data-title">{exchangeDataCardTitle}</div>
                        {showCashout && <button className='exchange-data-cashout-btn'>cashout</button>}
                    </div>
                </div>
                <div className="exchange-data-card-back-lay-labels">
                    <div className="exchange-data-back-label">back</div>
                    <div className="exchange-data-lay-label">lay</div>
                </div>
            </div>
            {/* end of card header ---------------------- */}

            {/* exchange data rows ---------------------- */}

            {
                marketType === "ODDS" ?
                    rowData?.map((match) => {
                        
                        return <MatchOddsDataRow gameId={gameId} match={match} />
                    })

                    :
                    rowData?.map((match) => {
                        return <ExchangeDataRow gameId={gameId} match={match} />
                    })

            }





        </div>




    </>
}

export default ExchangeDataCard
