import React, { useContext, useEffect, useState } from 'react'
import { Various_APIs } from '../../API/Various_APIs';
import { decryptData } from '../../Utilities/DecryptData';
import Logout_User from '../../Utilities/Logout';
import { User_Context } from '../../Contexts/User';
import { useNavigate } from "react-router-dom";
import "./Exchange.css"


function Exchange() {
    const { User, Set_User } = useContext(User_Context);
    const [gameArray, setGameArray] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        Various_APIs.Get_Landing_Page_Data()
            .then((response) => {
                const data = decryptData(response.data.data);
                setGameArray(data.payload.bannerGames);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    Logout_User(Set_User)
                    navigate("/")
                }
            });
    }, [])
    const navigateFun = (url) => {
        navigate(`/${url}`, { state: { Category: new URLSearchParams(window.location.search).get('data') || '' } });
    };
    return (
        <>
            <div className='col-12 exchange-type' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {gameArray.map((item, index) => {
                    return item.category == "exchange" && item.status == true ?
            
                      <div key={index}
                            onClick={(e) => {
                                navigateFun(item.iframeUrl);
                            }} className='col-10 col-md-4 col-sm-6 mb-2' style={{ display: 'flex', justifyContent: 'center' }}>
                            <img className='w-100' src={item.imagePath}></img>
                        </div> : null
                })}
            </div>
        </>
    )
}

export default Exchange