import React, { useContext } from 'react';
import { Translation_Table } from "../../Configuration/Translation";
import { Language_Context } from '../../Contexts/Language';


// Assets
import { ReactComponent as Bethistory } from "../../images/profile-images/updated-2.svg";

// Styles
import './BetHistory.css'

function BetHistoryNoData() {
    const { Language } = useContext(Language_Context)
    return (
        <div className="container text-center" style={{ marginTop: "5rem" }}>
            <Bethistory className="betimage" />
            <p className="nodata">{Translation_Table.nodata[Language]}</p>
        </div>
    )
}

export default BetHistoryNoData;