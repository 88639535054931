import React, { useContext } from 'react';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Language_Context } from '../../Contexts/Language';
import { Translation_Table } from '../../Configuration/Translation';

// Components
import GeneralButton from '../Buttons/GeneralButton';

// Assets
import gpayimage from "../../images/svg/google-pay.svg";
import phonepeimage from "../../images/svg/phone-pe.svg";
import bhimimage from "../../images/svg/BHIM.svg";
import paytmimage from "../../images/svg/PAYtm.svg";
import qrimage from "../../images/svg/QR.svg";

// Styles
import "../../css/deposite.css";

function DepositMainCard({ name, onClick}) {
    const { Language, Set_Language } = useContext(Language_Context)
    return (
        <Card className="card-depo" sx={{ minWidth: 200 }}>
            <CardContent className="cardcont">
                <Typography
                    className="pdeposit"
                    color="text.secondary"
                    gutterBottom
                >
                    {name}
                </Typography>
                <Typography variant="h5" component="div">
                    <div className="allimg">
                        <div
                            onClick={() => onClick(name, 'gpay')}
                            style={{ cursor: 'pointer' }}
                        >
                            <img className="image-deposit" src={gpayimage} />
                        </div>

                        <div
                            onClick={() => onClick(name, 'phonepe')}
                            style={{ cursor: 'pointer' }}
                        >
                            <img className="image-deposit" src={phonepeimage} />
                        </div>

                        <div
                            onClick={() => onClick(name, 'paytm')}
                            style={{ cursor: 'pointer' }}
                        >
                            <img className="image-deposit" src={paytmimage} />
                        </div>

                        {/* {name === "AB-UPI" && (
                            <div
                                onClick={() => onClick(name, 'qr')}
                                style={{ cursor: 'pointer' }}
                            >
                                <img className="image-deposit" src={qrimage} />
                            </div>
                        )} */}

                        {/* 
                        <div
                             onClick={()=> onClick(name, 'gpay')}
                        >
                            <img className="image-deposit" src={bhimimage} />
                        </div> */}

                    </div>
                </Typography>
            </CardContent>
            <CardActions className="depositbutton">
                {/* <GeneralButton
                    showText={true}
                    onClick={onClick}
                    text={Translation_Table.deposit[Language]}
                /> */}
            </CardActions>
        </Card>
    )
}

export default DepositMainCard;