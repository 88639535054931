import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode, Autoplay, Pagination } from 'swiper/modules'


// Import Swiper styles

import "./Carousel.css"
function MainCarousel({ children, slidesPerGroup, noModules, data, loading }) {

    return (
        <div className='swiper-container main'>
            
            <Swiper
                 slidesPerView={1} // Show a bit of the next slide
                 spaceBetween={5} // Adjust the space between slides
                 id='main-banner-swiper'
                 className='mySwiper'  
                 freeMode={true}
                 loop={true}
                 autoplay={{
                   delay: 3000
                 }}
                 slidesPerGroup={slidesPerGroup}
                 navigation={noModules ? false : true}
                 pagination={{ clickable: true }}
                 modules={[FreeMode, Navigation, Autoplay, Pagination]}
            >
                {data?.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <SwiperSlide key={index} style={{maxHeight:'320px'}}>
                                {children({ item, index, loading })}
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </div>
    )
}

export default MainCarousel