export class SessionHelper {
    static activeEchangeProvider = '';

    static getActiveExchangeProvider(){
        return SessionHelper.activeEchangeProvider;
    }

    static setActiveExchangeProvider(exchangeProvider = ''){
        SessionHelper.activeEchangeProvider = exchangeProvider;
    }
}