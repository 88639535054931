import React, { useContext } from 'react';
import { User_Context } from '../../Contexts/User';
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

// Assets
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";

// Components
import GeneralButton from '../Buttons/GeneralButton';

function BonusWalletModal(props) {
    const { User, Set_User } = useContext(User_Context);

    const handleWalletTransfer = () => {
        if (User.data.balance <= 100 && User.data.bonusWalletBalance > 0) {
            props.bonusWalletTransferRequest()
        } else {
            toast.error('You already have enough balance!')
            handleClose();
        }
    }

    const handleClose = () => {
        props.onHide();
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
            id="login-modal"
        >
            <Modal.Header className="hdr">

                {'Bonus Wallet'}
                <Crosssmall
                    onClick={() => {
                        handleClose()
                    }}
                    className="cross-kyc"
                />
            </Modal.Header>
            {User.data.balance <= 100 &&
                User.data.bonusWalletBalance > 0 &&
                User.data.exposer === 0 &&
                <div>
                    <div className='bonus-wallet-modal-container'>
                        <p className="bonus-wallet-text">{`Bonus Wallet Balance: ${Math.floor(User.data.bonusWalletBalance)}`}</p>
                        <GeneralButton
                            showText={true}
                            text={'Transfer'}
                            onClick={() => handleWalletTransfer()}
                        />
                    </div>
                    <div className='bonus-wallet-modal-container-footer'>
                        <p className="bonus-wallet-text-alt">{`You are eligible to transfer if your balance is less than 100 and Exposer should be 0, Your Balance : ${Math.floor(User.data.balance)}`}</p>
                    </div>
                </div>
            }
            {User.data.balance <= 100 &&
                User.data.bonusWalletBalance > 0 &&
                User.data.exposer > 0 &&
                <div>
                    <div className='bonus-wallet-modal-container'>
                        <p className="bonus-wallet-text">{`Bonus Wallet Balance: ${Math.floor(User.data.bonusWalletBalance)}`}</p>
                    </div>
                    <div className='bonus-wallet-modal-container-footer'>
                        <p className="bonus-wallet-text-alt">{`You are eligible to transfer if your balance is less than 100 and Exposer should be 0, Your Balance : ${Math.floor(User.data.balance)}`}</p>
                    </div>
                </div>
            }
            {User.data.balance > 100 &&
                <div>
                    <div className='bonus-wallet-modal-container'>
                        <p className="bonus-wallet-text">{`Bonus Wallet Balance: ${Math.floor(User.data.bonusWalletBalance)}`}</p>
                    </div>
                    <div className='bonus-wallet-modal-container-footer'>
                        <p className="bonus-wallet-text-alt">{`You are eligible to transfer if your balance is less than 100 and Exposer should be 0, Your Balance : ${Math.floor(User.data.balance)}`}</p>
                    </div>
                </div>

            }
            {User.data.balance <= 100 && User.data.bonusWalletBalance === 0 &&
                <div>
                    <div className='bonus-wallet-modal-container'>
                        <p className="bonus-wallet-text">{`Bonus Wallet Balance: ${Math.floor(User.data.bonusWalletBalance)}`}</p>
                    </div>
                    <div className='bonus-wallet-modal-container-footer'>
                        <p className="bonus-wallet-text-alt">{`You are eligible to transfer if your balance is less than 100 and Exposer should be 0, Your Balance : ${Math.floor(User.data.balance)}`}</p>
                    </div>
                </div>
            }

        </Modal>
    )
}

export default BonusWalletModal;