import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Casino_APIs } from '../../API/Casino_APIs';
import { User_Context } from '../../Contexts/User';
import { useNavigate } from 'react-router-dom';
import Logout_User from '../../Utilities/Logout';
import { decryptData } from '../../Utilities/DecryptData';
import { Game_Context } from '../../Contexts/Games';

// Components
import CasinoCarousel from '../Carousel/CasinoCarousel';
import Loader from '../Loader/Loader';
import QTechGameCard from '../QTechGameCard/QTechGameCard';
import ProvidersGameCard from '../ProvidersGameCard/ProvidersGameCard';
import Game_Card from '../Game_Card/Game_Card';
import { SessionHelper } from '../../Utilities/SessionHelper';


function TopProvidersSlider() {
    const { User, Set_User } = useContext(User_Context);

    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    const bannerArray = [
        { type: 'evolution' },
        { type: 'ezugi' },
        { type: 'sa' },
        { type: 'spribe' },
        { type: 'beter' },
        { type: 'mojos' },
        { type: 'vivo' },
        { type: 'betGames' }
    ]
    
    const wacBannerArray = [
        { type: 'evolution' },
        { type: 'ezugi' },
        { type: 'spribe' },
        { type: 'mojos' },
        { type: 'vivo' },
        { type: 'betGames' }
    ]

    const renderItem = useCallback(({ item, index }) => {
        return (
            <ProvidersGameCard
                key={index}
                data={item}
                isCarousel={true}
                user={User}
            />
        )
    }, [])

    return (
        <div>

            <div className='top-games-slider-header'>
                <p className='top-games-slider-header-text'>Top Providers</p>

                {/* <div className='top-games-slider-show-more-button'>
                    <p className='top-games-slider-button-text'>Show More</p>
                </div> */}
            </div>


            <CasinoCarousel
                data={activeCasinoProvider === "QTECH" ? bannerArray : wacBannerArray}
                noModules={false}
                children={renderItem}
            />




        </div>
    )
}

export default TopProvidersSlider;