export const Providers_Enums =
    Object.freeze({
        SoftGaming: 'SoftGaming',
        Jetfair: 'Jetfair',
        Nandi: 'Nandi',
        Betby: 'Betby',
        Wacs: 'Wacs',
        Falcon: 'Falcon',
        QTech: 'QTech',
        CAPITAL_QTECH: 'QTECH',
        CAPITAL_WACS: 'WACS'
    });