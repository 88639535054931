import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Casino_APIs } from '../../API/Casino_APIs';

// Assets
import Thumbnail_Fallback_Image from '../../Assets/Images/Thumbnail_Fallback_Image.webp';

function QTechGameCard({ data, isCarousel }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOnRedirect = () => {
        if(location.pathname === '/casino-category-mobile'){
            navigate('/qtech-casino-game-mobile', { state: { gameId: data.gameId } });
            return;
        }
        navigate('/qtech-casino-game', { state: { gameId: data.gameId } });
    }

    return (
        <div className={'col-6 col-md-2 crv-csn'}>
            <div className='member-img' onClick={handleOnRedirect}>
                <img
                    alt={data.Name}
                    className={isCarousel ? 'carousel-image-qtech' : 'img-fluid indian-game-image'}
                    src={data?.images?.[2] ? data?.images?.[2]?.url : Thumbnail_Fallback_Image}
                    loading='lazy'
                ></img>
            </div>
        </div>
    )
}

export default QTechGameCard;
