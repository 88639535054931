import { useEffect, useRef } from "react";

export const Use_Previous_Value = (Value) =>
{
	const Previous_Value_Reference = useRef ();
	useEffect(() => 
	{
		Previous_Value_Reference.current = Value;
	}, [Value]);
	return Previous_Value_Reference.current;
}