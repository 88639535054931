import React, {useState, useEffect, useContext} from "react";
import TransactionCard from "../../components/Transaction/TransactionCard";
import Grid from "@mui/material/Grid";
import { Payment_APIs } from '../../API/Payment_APIs';
import { User_Context } from "../../Contexts/User";
import { decryptData } from "../../Utilities/DecryptData";
import Logout_User from "../../Utilities/Logout";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

// Styles
import "../../css/withdrawmodal.css";
import '../Profile/Account-Statement/account-statement.css'
import './WithdrawalRequest.css'

const WithdrawalRequest = () => {
    const [childState, setChildState] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [showData, setShowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();

    const handleChildStateChange = (newState) => {
		setChildState(newState);
	};

    useEffect(() => {
       
		let data = { userId: User.data._id };
        setLoading(true)
		Payment_APIs.Get_Transactions(data, User.token)
			.then((response) => {
				const data = decryptData(response.data.data);
				setTransactions(data.payload);
				setShowData(data.payload.withdrawals);
                
			})
			.catch((error) => {
				if (error.response.status === 401) {
					Logout_User(Set_User)
					navigate("/")
				}
			});
        setLoading(false)
          
	}, [childState]);

        useEffect(() => {
		        setShowData(transactions.withdrawals)
            }, [])

  return (
    <>
    <div className="profile-detail dw" id="profilinfo">
    {loading && <Loader />}
						<div className="profilepg">
							<Grid container spacing={2}>
								{transactions.withdrawals?.map((transaction) => (
									<Grid item xs={12} sm={4}>
										<TransactionCard
											key={Math.random()}
											transactions={transaction}
											onChange={handleChildStateChange}
										/>
									</Grid>
								))}
							</Grid>
						</div>
					</div>
    </>
  )
}

export default WithdrawalRequest
