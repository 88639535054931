import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";
import Eyeslash from "../../images/eye-slash.svg";
import Eyefill from "../../images/eye-fill.svg";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import jwtDecode from "jwt-decode";

// Components
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import OTPModal from "./OTPModal";

// Styles
import "../../css/forgetpassword.css"

function ForgotPassword(props) {
	const [passwordVisible1, setPasswordVisible1] = useState(false);
	const [passwordVisible2, setPasswordVisible2] = useState(false);
	const [validateMobileNo, setValidateMobileNo] = useState(true);
	const [mobile, setMobile] = useState("");
	const [otp, setOtp] = useState("");
	const [remainingMinutes, setRemainingMinutes] = useState(0);
	const [remainingSeconds, setRemainingSeconds] = useState(0);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [otpVerification, setOtpVerification] = useState(true);
	const [showOtpForm, setShowOtpForm] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [verifyConfirmPassword, setVerifyConfirmPassword] = useState(true);
	const [otpVerify, setOtpverify] = useState(true);
	const [verifyPassword, setVerifyPassword] = useState(true);
	const [showPasswordField, setShowPasswordField] = useState(true);
	const [idError, setIdError] = useState(false);
	const [id, setId] = useState("");
	const { User, Set_User } = useContext(User_Context);
	const navigate = useNavigate()
	const [token, setToken] = useState("")

	const [showNewOtp, setShowNewOtp] = useState(false);

	function countdown(minutes) {
		let seconds = minutes * 60;

		const timer = setInterval(() => {
			const Minutes = Math.floor(seconds / 60);
			setRemainingMinutes(Minutes);
			const Seconds = seconds % 60;
			setRemainingSeconds(Seconds);
			seconds--;

			if (seconds < 0) {
				clearInterval(timer);
				setOtpVerification(false);
			}
		}, 1000);
	}
	const notify = () => {
		toast.success("Password was successfully updated");
	};
	const validatMobile = (mobile) => {
		const emailRegex = /^[6-9]\d{9}$/;
		return emailRegex.test(mobile);
	};
	const validatePassword = (password) => {
		const emailRegex = /(?=.{8,})/;
		return emailRegex.test(password);
	};
	const handleToggle1 = () => {
		setPasswordVisible1(!passwordVisible1);
	};
	const handleToggle2 = () => {
		setPasswordVisible2(!passwordVisible2);
	};
	const ForgotPassword = async () => {
		if (validateMobileNo) {
			setIsLoading(true);
			let data = {
				mobile: mobile,
			};
			User_APIs.Request_OTP_for_Forgotten_Password(data).then((response) => {
				setIsLoading(false);
				countdown(5)
				// setShowForgotPassword(false);
				setShowPasswordField(false);
				setShowNewOtp(true)

			})
				.catch((error) => {
					setIsLoading(false);
					if (error.response.status === 401) {
						Logout_User(Set_User)
						navigate("/")
					} else {
						toast.error("There has been an error, while sending an OTP, please try again later")
						setTimeout(() => {
							window.location.reload()
						}, 1000)
						setShowForgotPassword(false);
					}
					return error;
				});


		}
	};
	const verifyOtp = async () => {
		setIsLoading(true);
		validatMobile(mobile);
		let data = {
			mobile: mobile,
			otp: otp,
		};
		User_APIs.Verify_OTP(data, User.token)
			.then((response) => {
				if (response.data.token && (jwtDecode(response.data.token)).id === mobile) {
					setToken(response.data.token)
					setIsLoading(false);
					setShowForgotPassword(true);
					setShowOtpForm(false);
					setShowPasswordField(false);
				}
				else {
					throw new Error({ response: { status: 400 } })
				}
				toast.success(response)
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.response && error.response.status === 403) {
					toast.error(error.response.data.message)
				}
				if (error.response.status === 401) {
					Logout_User(Set_User)
					navigate("/")
				}
			});
	};
	const updatePassword = async () => {
		setIsLoading(true);
		let data = {
			mobile: mobile,
			otp: otp,
			password: password,
		};
		User_APIs.Forget_Password(data, User.token ? User.token : token)
			.then((response) => {
				setIsLoading(false);
				notify();
				setShowOtpForm(false);
				setShowForgotPassword(false);
				setShowPasswordField(false);
				props.onHide();
			}
			)
			.catch((error) => {
				setIsLoading(false);
				if (error.response.status === 403) {
					setOtpverify(false);
					toast.error('Invalid OTP entered');
				}
				if (error.response.status === 401) {
					Logout_User(Set_User)
					navigate("/")
				}
			});
	};
	const closeOtpForm = () => {
		// navigate("/?login=1")
		setShowOtpForm(false)
	}
	return (
		<div>
			{showForgotPassword ? (
				<>
					<Modal
						{...props}
						//show={modalChangePassword}
						size="md"
						aria-labelledby="contained-modal-title-vcenter"
						centered
						onHide={props.onHide}
					>
						<Modal.Header className="kyc-hdr">
							Forget Password
							<Crosssmall
								onClick={() => {
									props.onHide();
								}}
								className="cross-kyc"
							/>
						</Modal.Header>
						<Modal.Body>
							<div>
								<div className="container login Display_Flex Flex_Direction_Column Align_Items_Center">
									{isLoading && <Loader />}
									<div className="col-12">
										<div className="row">
											<div className="col-12">
												<div className="username-input mt-3">
													{showPasswordField ? (
														<>
															{validateMobileNo && !idError ? (
																<GeneralTextfield
																	onChange={(e) => {
																		if (mobile.length >= 10) {
																			setValidateMobileNo(
																				validatMobile(e.target.value)
																			);
																		}
																		setMobile(e.target.value);
																	}}
																	//value={}
																	label={"Enter Registered Mobile Number *"}
																	type={'number'}
																/>
															) : (
																<GeneralTextfield
																	error={true}
																	onChange={(e) => {
																		setIdError(false);
																		setValidateMobileNo(
																			validatMobile(e.target.value)
																		);
																		setMobile(e.target.value);
																	}}
																	type={'number'}
																	label={
																		!idError
																			? "enter valid mobile"
																			: "please try again after some time"
																	}
																/>
															)}
														</>
													) : (
														<>
															{verifyPassword ? (
																<>
																	{ /* Fake input field that disables autofill */ <input type="password" name='fake-password' autoComplete='new-password' tabIndex='-1' style={{ opacity: 0, float: 'left', border: 'none', height: '0', width: '0' }} /> /* Fake input field that disables autofill */}
																	<GeneralTextfield
																		onChange={(e) => {
																			setPassword(e.target.value);
																		}}
																		label={"Enter New Password"}
																		type={passwordVisible1 ? "text" : "password"}
																	/>
																	<div
																		className="eye1"
																		style={{ top: "-40px" }}
																		onClick={handleToggle1}
																	>
																		{passwordVisible1 ? (
																			<img src={Eyefill} />
																		) : (
																			<img src={Eyeslash} />
																		)}
																	</div>
																</>
															) : (
																<div>
																	<GeneralTextfield
																		error={true}
																		onChange={(e) => {
																			setVerifyPassword(true);
																			setPassword(e.target.value);
																		}}
																		label={"Enter minimum 6 characters password"}
																		type={passwordVisible1 ? "text" : "password"}
																	/>
																	<div
																		className="eye1"
																		style={{ top: "-40px" }}
																		onClick={handleToggle1}
																	>
																		{passwordVisible1 ? (
																			<img src={Eyefill} />
																		) : (
																			<img src={Eyeslash} />
																		)}
																	</div>
																</div>

															)}

															{verifyConfirmPassword ? (
																<>
																	<GeneralTextfield
																		onChange={(e) => {
																			setConfirmPassword(e.target.value);
																		}}
																		label={"Confirm New Password"}
																		type={passwordVisible2 ? "text" : "password"}
																	/>
																	<div
																		className="eye1"
																		style={{ top: "-40px" }}
																		onClick={handleToggle2}
																	>
																		{passwordVisible2 ? (
																			<img src={Eyefill} />
																		) : (
																			<img src={Eyeslash} />
																		)}
																	</div>
																</>

															) : (
																<>
																	<GeneralTextfield
																		error={true}
																		onChange={(e) => {
																			setVerifyConfirmPassword(true);
																			setConfirmPassword(e.target.value);
																		}}
																		label={"passwords not match"}
																		type={passwordVisible2 ? "text" : "password"}
																	/>
																	<div
																		className="eye1"
																		style={{ top: "-40px" }}
																		onClick={handleToggle2}
																	>
																		{passwordVisible2 ? (
																			<img src={Eyefill} />
																		) : (
																			<img src={Eyeslash} />
																		)}
																	</div>
																</>
															)}

															{otpVerify ? (
																<GeneralTextfield
																	onChange={(e) => {
																		setOtp(e.target.value);
																	}}
																	label={"Enter OTP"}
																	type={"number"}
																/>
															) : (
																<GeneralTextfield
																	error={true}
																	onChange={(e) => {
																		setOtpverify(true);
																		setOtp(e.target.value)
																	}}
																	label={"Enter the valid OTP"}
																	type={"number"}
																/>
															)}
														</>
													)}
													<div className="clrBoth" />
												</div>
											</div>
										</div>
									</div>

									<div className="py-2">
										{showPasswordField ? (
											<GeneralButton
												showText={true}
												text={'Submit'}
												onClick={(e) => {
													ForgotPassword();
												}}
											/>
										) : (
											<GeneralButton
												showText={true}
												text={'Submit'}
												onClick={(e) => {
													setVerifyPassword(validatePassword(password));
													if (
														confirmPassword === password &&
														validatePassword(password)
													) {
														updatePassword();
													} else {
														setVerifyConfirmPassword(false);
													}
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</>
			) : (
				<>
					{showOtpForm && (
						<OTPModal
							closeButton={true}
							closeOtpForm={closeOtpForm}
							value={otp}
							onChange={setOtp}
							validOtp={otpVerification}
							onClick={verifyOtp}
							remainingMinutes={remainingMinutes}
							remainingSeconds={remainingSeconds}
						/>
					)}
				</>
			)}
		</div>
	);
}

export default ForgotPassword;
