import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Cookies from "universal-cookie";
import { toast, ToastContainer } from "react-toastify";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { KYC_APIs } from "../../API/KYC_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { decryptData } from "../../Utilities/DecryptData";
import { encryptData } from "../../Utilities/EncryptData";

// Components
import Loader from "../Loader/Loader";
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import KYCForm from "./KYCForm";

// Assets
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";

// Styles
import "../../css/kyc.css";

function Kyc(props) {
    const [kycVerifyForm, setKycVerifyForm] = useState("");
    const [showKycForm, setShowKycForm] = useState(false);
    const [accountNumber, setAccountNumber] = useState("");
    const [accError, setAccError] = useState(false);
    const [accHelperText, setAccHelperText] = useState("");
    const [ifscErr, setIfscErr] = useState(false);
    const [ifscHelperText, setIfscHelperText] = useState("");
    const [aadharErr, setAadharErr] = useState(false);
    const [aadharHelperText, setAadharHelperText] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [aadhaarNumber, setAadhaarNumber] = useState("");
    const [name, setName] = useState("");
    const [verifyAadhar, setVerifyAadhar] = useState(true);
    const [verifyIfsc, setVerifyIfsc] = useState(true);
    const [verifyAccount, setVerifyAccount] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [remainingSeconds, setRemainingSeconds] = useState(0);
    const [error, setError] = useState("");
    const [otpVerification, setOtpVerification] = useState(true);
    const cookies = new Cookies();
    const { User, Set_User, Previous_User } = useContext(User_Context);
    const navigate = useNavigate();
    const { Language, Set_Language } = useContext(Language_Context);

    const [accountError, setAccountError] = useState(false);
    const [aadharError, setAadharError] = useState(false);
    const [ifscError, setIfscError] = useState(false);
    const [upiId, setUpiId] = useState("");
    const [upiIdError, setUpiIdError] = useState(false);
    const [upiHelperText, setUpiHelperText] = useState("");
    const handleSetUpiId = (e) => {
        const id = e.target.value;
        setUpiId(id);
    };

    const notify = () => {
        toast.error(
            "You have exceeded the limit of 3 attempts per day. Please try again after 24 hours."
        );
    };

    function countdown(minutes) {
        let seconds = minutes * 60;

        const timer = setInterval(() => {
            const Minutes = Math.floor(seconds / 60);
            setRemainingMinutes(Minutes);
            const Seconds = seconds % 60;
            setRemainingSeconds(Seconds);
            seconds--;

            if (seconds < 0) {
                clearInterval(timer);
                setShowKycForm(false);
            }
        }, 1000);
    }

    function handleVerifyKycForm() {
        setShowKycForm(true);
    }

    const accountNumberReg = (accountNumber) => {
        const emailRegex = /^\d{9,18}$/;
        return emailRegex.test(accountNumber);
    };
    const ifscCodeReg = (ifscCode) => {
        const emailRegex = /^[A-Za-z]{4}\d{7}$/;
        return emailRegex.test(ifscCode);
    };

    const aadhaarNumberReg = (aadhaarNumber) => {
        const emailRegex = /^[2-9]{1}[0-9]{11}$/;
        return emailRegex.test(aadhaarNumber);
    };

    const kycbody = async (e) => {
        if (!accountNumber) {
            setAccError(true);
            setAccHelperText("Enter Account number");
        } else if (!ifscCode) {
            setIfscError(true);
            setIfscHelperText("Enter IFSC Number");
        } else if (!aadhaarNumber) {
            setAadharErr(true);
            setAadharHelperText("Enter Aadhar Number");
        } else {
            setIsLoading(true);
            let data = {
                accountNumber: accountNumber,
                ifsc: ifscCode,
                aadhaarNumber: aadhaarNumber,
            };
            KYC_APIs.Provide_KYC_Information(data, User.token)
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        response.data.data = decryptData(response.data.data);
                        setIsLoading(false);
                        countdown(5);
                        const { kycCount, name } = response.data.data.payload;
                        setName(name);
                        const kycAttemptsLeft = 3 - parseInt(kycCount);
                        toast.info(`Please note: You have ${kycAttemptsLeft} attempts left.`);
                        handleVerifyKycForm();
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response.data.message === "Aadhaar number not valid.") {
                        setAadhaarNumber("");
                        setVerifyAadhar(false);
                        setAadharError(true);
                        setAadharHelperText(
                            "Please Enter Aadhaar Number Linked with same Bank Account"
                        );
                        // setAadharError("Please Enter Valid Aadhar Number")
                    } else if (error.response.data.message === "Account number not valid.") {
                        setAccountNumber("");
                        setVerifyAccount(false);
                        setAccountError(true);
                        setAccHelperText("Please Enter Valid Account Number");
                    } else if (error.response.data.message === "Bank Verification Failed.") {
                        setAccountNumber("");
                        setVerifyAccount(false);
                        setAccountError(true);
                        setAccHelperText("Please Enter Valid Account Number");
                    } else if (error.response.data.message === "IFSC code not valid") {
                        setIfscCode("");
                        // setVerifyIfsc(false)
                        setIfscHelperText("Please Enter Valid IFSC code");
                        setIfscError(true);
                    } else if (
                        error.response.data.message ===
                            "you have tried many times... try after 24 hrs" ||
                        error.response.data.message ===
                            "you have completed your 3 attempt... try after 1 hrs"
                    ) {
                        notify();
                        props.onHide();
                        setAccountNumber("");
                        setIfscCode("");
                        setAadhaarNumber("");
                    } else if (error.response.status === 401) {
                        Logout_User(Set_User);
                        navigate("/");
                    } else {
                        toast.error(error.response.data.message);
                    }
                });
        }
    };
    const handleHide = () => {
        setAadhaarNumber("");
        setAccountNumber("");
        setIfscCode("");
        setIfscHelperText("");
        setAadharHelperText("");
        setAccHelperText("");
        setIfscError(false);
        setAccountError(false);
        setAadharError(false);
        setVerifyAadhar(true);
        setVerifyIfsc(true);
        setVerifyAccount(true);
        setKycVerifyForm("");
        setVerifyAccount(true);
        setOtpVerification(true);
        props.onHide();
    };

    const verifyOtp = async () => {
        setIsLoading(true);
        if (!upiId) {
            setUpiIdError(true);
            setUpiHelperText("Please Enter UPI ID");
        }
        let data = {
            accountNumber: accountNumber,
            otp: parseInt(kycVerifyForm),
            aadhaarNumber: aadhaarNumber,
            _id: User.data._id,
            upiId: upiId,
        };
        KYC_APIs.Submit_KYC_OTP(data, User.token)
            .then((response) => {
                if (response && response.data && response.data.success) {
                    const Copy_of_User = structuredClone(User);
                    Copy_of_User.data.kyc = true;
                    response.data.data = decryptData(response.data.data);
                    Copy_of_User.data.aadhaarNumber = response.data.data.payload.aadhaarNumber;
                    Copy_of_User.data.ifsc = response.data.data.payload.ifsc;
                    Copy_of_User.data.bankName = response.data.data.payload.bankName;
                    Copy_of_User.data.accountNumber = response.data.data.payload.accountNumber;
                    Set_User(Copy_of_User);
                    cookies.set("userData", encryptData(Copy_of_User));
                    setIsLoading(false);
                    setShowKycForm(false);
                    handleHide();
                    toast.info(
                        "You have successfully completed the KYC. You can now withdraw your funds."
                    );
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                if (error && error.response && error.response.data && error.response.data.message) {
                    setIsLoading(false);
                    setError(error.response.data.message);
                    if (error.response.data.message === "Verification Failed.") {
                        setOtpVerification(false);
                        setKycVerifyForm("");
                    }
                } else if (error.response.status === 401) {
                    Logout_User(Set_User);
                    navigate("/");
                } else {
                    toast.error(error.response.data.message);
                }
            });
    };

    return (
        <>
            {isLoading && <Loader />}
            <ToastContainer autoClose={2000} position="top-center" />
            <div>
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={handleHide}
                    //show={showModal}
                >
                    <Modal.Header className="kyc-hdr">
                        {Translation_Table.kyc[Language]}
                        <Crosssmall onClick={handleHide} className="cross-kyc" />
                    </Modal.Header>
                    <Modal.Body className="kycbody">
                        <div>
                            {isLoading && <Loader />}
                            <div className="container login kycnew">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="username-input">
                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        const enteredAccNumber = e.target.value;
                                                        if (enteredAccNumber.length === 0) {
                                                            setAccountError(false);
                                                        }
                                                        if (enteredAccNumber.length <= 17) {
                                                            setAccountNumber(enteredAccNumber);
                                                            setAccountError(false);
                                                            setAccHelperText("");
                                                        } else {
                                                            setAccountError(true);
                                                            setAccHelperText(
                                                                "Account number should not exceed 18 digits"
                                                            );
                                                        }
                                                    }}
                                                    type={"number"}
                                                    value={accountNumber}
                                                    error={accountError}
                                                    // helperText={accHelperText}
                                                    label={
                                                        accountError
                                                            ? accHelperText
                                                            : Translation_Table.AccountNo[Language]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="username-input mt-2">
                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        let enteredValue = e.target.value;
                                                        enteredValue = enteredValue.toUpperCase();
                                                        if (enteredValue.length === 0) {
                                                            setIfscError(false);
                                                        }
                                                        const alphanumericPattern =
                                                            /^[A-Za-z0-9]{1,11}$/;
                                                        if (
                                                            alphanumericPattern.test(
                                                                enteredValue
                                                            ) ||
                                                            enteredValue === ""
                                                        ) {
                                                            setIfscCode(enteredValue);
                                                            setIfscError(false);
                                                            setIfscHelperText("");
                                                        } else {
                                                            setIfscError(true);
                                                            setIfscHelperText(
                                                                "Please enter a valid IFSC code"
                                                            );
                                                        }
                                                    }}
                                                    value={ifscCode}
                                                    error={ifscError}
                                                    // helperText={ifscHelperText}
                                                    type={"email"}
                                                    label={
                                                        ifscError
                                                            ? ifscHelperText
                                                            : Translation_Table.IFSC[Language]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="username-input mt-2">
                                                <GeneralTextfield
                                                    onChange={(e) => {
                                                        const enteredAadhaarNumber = e.target.value;
                                                        if (enteredAadhaarNumber.length === 0) {
                                                            setAadharError(false);
                                                        }
                                                        if (enteredAadhaarNumber.length <= 12) {
                                                            setAadhaarNumber(enteredAadhaarNumber);
                                                            setAadharError(false);
                                                            setAadharHelperText("");
                                                        } else {
                                                            setAadharError(true);
                                                            setAadharHelperText(
                                                                "Aadhaar number should not exceed 12 digits"
                                                            );
                                                        }
                                                    }}
                                                    value={aadhaarNumber}
                                                    error={aadharError}
                                                    helperText={aadharHelperText}
                                                    type={"number"}
                                                    label={
                                                        aadharError
                                                            ? aadharHelperText
                                                            : Translation_Table.aadharnumber[
                                                                  Language
                                                              ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="newsignup kycsign">
                                        <GeneralButton
                                            onClick={() => {
                                                kycbody();
                                            }}
                                            className={"button-login"}
                                            showText={true}
                                            text={Translation_Table.submit[Language]}
                                        />
                                    </div>

                                    {showKycForm && (
                                        <KYCForm
                                            name={name}
                                            value={kycVerifyForm}
                                            onChange={setKycVerifyForm}
                                            otpVerification={otpVerification}
                                            onClick={() => {
                                                verifyOtp();
                                            }}
                                            remainingMinutes={remainingMinutes}
                                            remainingSeconds={remainingSeconds}
                                            hideForm={() => setShowKycForm(false)}
                                            closeButton={true}
                                            click={handleSetUpiId}
                                            upiId={upiId}
                                            errorId={upiIdError}
                                            helperText={upiHelperText}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default Kyc;
