import React from 'react';

// Styles
import './Textfields.css'

function DateField({ title, placeholder, name, id, value, onChange }) {
    return (
        <div>
            <label className="label-bet">{title}</label>
            <input
                className="form-control inpt-bet"
                id={id}
                name={name}
                placeholder={placeholder}
                type="date"
                onChange={onChange}
                value={value}
            />
        </div>
    )
}

export default DateField