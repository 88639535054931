import { API_Handler } from "./API_Handler";

export const Various_APIs = {
    Get_Landing_Page_Data: async () => {
        const token = localStorage.getItem("token");
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        return await API_Handler.get('/', { headers });
    },
    Get_Version: async () => await API_Handler.get('/version'),
    // Get_App_Config: async () => await API_Handler.get('/configs')
};