import React from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// Assets
import { ReactComponent as Search_Icon } from "../../Assets/Icons/Search_Icon.svg";
import { useLocation, useNavigate} from "react-router-dom"
// Styles
import '../../Pages/Casino/Casino.css'
import { ReactComponent as Homesvg } from "../../Assets/sidebarIcons/new-home-icon.svg";
import HomeIcon from '@mui/icons-material/Home';
function SearchBox({ handleSearchGames, searchGames }) {
   const navigate=useNavigate();
    return (
        <div className="casOuter">
            <button onClick={()=>navigate(-1)}>
                <KeyboardBackspaceIcon style={{fontSize:"30",color:"#040f2e"}}/>
                <HomeIcon  style={{fontSize:"30px",color:"#040f2e",marginLeft:"5px"}}/>
               
                </button>
            <div className="container1-casino">
                <div className="searchOuterbs">
                    <div className="sercottr">
                        <input
                            type="text"
                            id="box"
                            placeholder="Search All Games"
                            className="searxhin"
                            value={searchGames}
                            onChange={(e) => handleSearchGames(e)}
                        />
                        <span className="search-icon">
                            <Search_Icon />
                        </span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SearchBox;