import React, { useState, useEffect, useContext } from 'react'
import { User_APIs } from '../../../API/User_APIs'
import { decryptData } from '../../../Utilities/DecryptData'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { User_Context } from '../../../Contexts/User';
import { Card, CardContent } from "@mui/material";
import { Grid } from "@mui/material";
import config from "../../../Utilities/Data_Formatting";

import "./bet-history.css"

function PendingBetHistory() {

    const { User } = useContext(User_Context)
    const [totalPendingBet, setTotalPendingBet] = useState([])
    useEffect(() => {
        let data = {
            _id: User.data._id
        };
        User_APIs.Get_Pending_Bet(data)
            .then((response) => {
                response.data.data = decryptData(response.data.data)
                let arr = [...response.data.data.payload.falconPendingBet, ...response.data.data.payload.jetfairPendingBet, ...response.data.data.payload.sattaMatkaPendingBet]
                setTotalPendingBet(arr)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
            })
    }, [])
 
    return (
        <>        
            <div className="pending-bets">
            {!totalPendingBet.length && (<div className='d-flex justify-content-center pt-4'><h6>No bets available!</h6></div>)}
                <div className="profilepg">
                    <Grid container spacing={2}>
                        {totalPendingBet.map((item, index) => (
                            <Grid key={index} item xs={12} sm={4}>
                                <Card>
                                    <CardContent className='content-bet-pending'>
                                        <div className="bethis-card-pending">
                                            <span>{item.gameName} Bets</span>
                                        </div>
                                        <div className="amount-bethis">
                                            <span>
                                                {item.gameName === "jetfair" ? item.amount :
                                                    item.gameName === "sattaMatka" ? item.point :
                                                        item.gameName === "falcon" ? item.Amount :
                                                            null}
                                            </span>

                                        </div>
                                        <div className="bet-content-container">
                                            {item.marketName && (
                                                <div>
                                                    <p className='bet-market-name'>{`Market Name : ${item.marketName}`}</p>
                                                </div>
                                            )}
                                            {item.Marketname && (
                                                <div>
                                                    <p className='bet-market-name'>{`Market Name : ${item.Marketname}`}</p>
                                                </div>
                                            )}
                                            {item.bazar_name && (
                                                <div>
                                                    <p className='bet-market-name'>{`Bazar Name : ${item.bazar_name}`}</p>
                                                </div>
                                            )}
                                            {item.game_name && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Game Name : ${item.market_code === "701" ? "INSTANT WORLI" : item.game_name}`}</p>
                                                </div>
                                            )}
                                            {item.bazar_id && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bazar Id : ${item.bazar_id}`}</p>
                                                </div>
                                            )}
                                            {item.game_id && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Game Id : ${item.game_id}`}</p>
                                                </div>
                                            )}
                                            {item.runnerName && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Runner Name : ${item.runnerName}`}</p>
                                                </div>
                                            )}
                                            {item.Runnername && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Runner Name : ${item.Runnername}`}</p>
                                                </div>
                                            )}
                                            {item.game_type && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Game Type : ${item.game_type}`}</p>
                                                </div>
                                            )}
                                            {item.rate && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Rate : ${item.rate}`}</p>
                                                </div>
                                            )}
                                            {item.Rate && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Rate : ${item.Rate}`}</p>
                                                </div>
                                            )}
                                            {item.transactionId && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Transaction ID : ${item.transactionId}`}</p>
                                                </div>
                                            )}
                                            {item.TransactionID && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Transaction ID : ${item.TransactionID}`}</p>
                                                </div>
                                            )}
                                            {item.result_date && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Result Date : ${item.result_date}`}</p>
                                                </div>
                                            )}
                                            {item.marketId && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Market ID : ${item.marketId}`}</p>
                                                </div>
                                            )}
                                            {item.Markettype && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Market Type : ${item.Markettype}`}</p>
                                                </div>
                                            )}
                                            {item.RunnerID && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Runner ID : ${item.RunnerID}`}</p>
                                                </div>
                                            )}
                                            {item.MarketID && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Market ID : ${item.MarketID}`}</p>
                                                </div>
                                            )}
                                            {item.betType && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet Type : ${item.betType}`}</p>
                                                </div>
                                            )}
                                            {item.BetType && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet Type : ${item.BetType}`}</p>
                                                </div>
                                            )}
                                            {item._id && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet ID : ${item._id}`}</p>
                                                </div>
                                            )}
                                            {item.game && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet On : ${item.game}`}</p>
                                                </div>
                                            )}
                                            {item.createdOn && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet Made On : ${config.dateTimeComplete(item.createdOn)}`}</p>
                                                </div>
                                            )}
                                            {item.updatedOn && (
                                                <div className='mt-2'>
                                                    <p className='bet-market-name'>{`Bet Updated On : ${config.dateTimeComplete(item.updatedOn)}`}</p>
                                                </div>
                                            )}

                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>
                </div>
            </div>
        </>
    )
}

export default PendingBetHistory