import React from 'react'
import "./GamesHeaderMarker.css"
import {Link} from "react-router-dom"
import { ReactComponent as Viewall } from "../../images/svg/viewall.svg";
const GamesHeaderMarker = ({markerText,showBtn}) => {
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    return <>
        <div className="games-header-marker">
            <div className="marker">
                <span>{markerText}</span>
            </div>
            <div className={`view-all-btn ${isTabOrMobile ? '' : 'desktop'}`}>
              {showBtn && <button className='view-all-button' >
                <Link className='link' to={"/satta"}>{"view all"}</Link> <Viewall className='viewall-svg' /> </button>}  
            </div>
        </div>
    </>

}

export default GamesHeaderMarker
