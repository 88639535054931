import React, { useCallback } from "react";
import { CloseButton, Modal } from "react-bootstrap";

import "./RegisterPopup-styles.css";

export const RegisterPopup = (props) => {
    const { isVisible, setIsVisible, onRegisterPress } = props;

    const closeModal = useCallback(() => setIsVisible(), [setIsVisible]);

    return (
        <Modal
            size="md"
            centered
            onHide={closeModal}
            id="register-modal"
            onBackdropClick={closeModal}
            show={isVisible}
            dialogClassName="modal-content-ext"
        >
            <Modal.Body>
                <div>
                    <div className="close-btn-div">
                        <CloseButton onClick={closeModal} className="cross-btn-register" />
                    </div>
                    <img
                        src={require("../../images/register-popup.png")}
                        alt="Register Image"
                        onClick={onRegisterPress}
                        className="register-popup-img"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};
