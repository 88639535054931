import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ExchangeBar.css";
import { ReactComponent as Roulette } from "../../Assets/inplayTabsIcons/roulette.svg";
import { ReactComponent as Blackjack } from "../../Assets/inplayTabsIcons/blackjack.svg";
import { ReactComponent as Dragon } from "../../Assets/inplayTabsIcons/dragon.svg";
import { ReactComponent as Teenpatti } from "../../Assets/inplayTabsIcons/teenpatti.svg";
import { ReactComponent as Andarbahar } from "../../Assets/inplayTabsIcons/andarbahar.svg";
import "../../Pages/Home/Home.css";
function ExchangeBar() {
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const navigate = useNavigate();
    const handleGame = () => {
        navigate("/exchange-data");
    };

    return (
        <>
            <div className="new-exchange">
                <div className="game-name">
                    <Link className="link" to={"/selected-casino"} state={{ name: "Roulette" }}>
                        <Roulette className="exchange-icon" />
                        <span className="spn-clr">Roulette</span>
                    </Link>
                </div>
                <div className="game-name">
                    <Link className="link" to={"/selected-casino"} state={{ name: "BlackJack" }}>
                        <Blackjack className="exchange-icon" />
                        <span className="spn-clr">Black Jack</span>
                    </Link>
                </div>
                <div className="game-name">
                    <Link className="link" to={"/selected-casino"} state={{ name: "Dragon Tiger" }}>
                        <Dragon className="exchange-icon" />
                        <span className="spn-clr">Dragon</span>
                    </Link>
                </div>
                <div className="game-name">
                    <Link className="link" to={"/selected-casino"} state={{ name: "Teen Patti" }}>
                        <Teenpatti className="exchange-icon" />
                        <span className="spn-clr">TeenPatti</span>
                    </Link>
                </div>
                <div className="game-name">
                    <Link className="link" to={"/selected-casino"} state={{ name: "Andar Bahar" }}>
                        <Andarbahar className="exchange-icon" />
                        <span className="spn-clr">Andar Bahar</span>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default ExchangeBar;

// <div>
//         <div className="inplay">
//           <div >
//             <Play />
//           </div>
//           <div className="inner-inplay">
//             <span className="spn-inplay">In Play</span>
//           </div>
//           <div className="open-bet">
//             <span className="spn-openbet">Open Bets</span>
//             <div className="circle">
//               <span>1</span>
//             </div>
//           </div>
//         </div>
//         <div>
//           <div className="col-12 mt-2">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><Cric /></div>
//                         <div>Cricket</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           44 Ovr <br />
//                           Tgt 170 <br />
//                           139/6
//                         </span>
//                         <span onClick={handleGame} className="match pdd">
//                           Australia <br />
//                           India
//                         </span>
//                         <span>
//                           <Playbtn className="playbtn" />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">-</td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           25 Ovr <br />
//                           139/6
//                         </span>
//                         <span className="match pdd">
//                           Australia Women <br />
//                           India Women
//                         </span>
//                         <span>
//                           <Playbtn className="playbtn" />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">-</td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           19/11/2023 <br />
//                           14:00 <br />
//                         </span>
//                         <span className="match pdd">
//                           ICC Cricket World Cup <br />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         4.9 <br />
//                         31,111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//         <div>
//           <div className="col-12">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><FootBall /></div>
//                         <div>Football</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         44'
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='ftball'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         44'
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='ftball'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//         <div>
//           <div className="col-12">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><FootBall /></div>
//                         <div>Tennis</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         <div style={{marginLeft:"5px"}}>
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='tennis'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         <div style={{marginLeft:"5px"}}>
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='tennis'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>

//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//       </div>
