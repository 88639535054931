import React from 'react';

// Components
import { Translation_Table } from '../../Configuration/Translation';

// Assets
import { ReactComponent as Close_Icon } from "../../Assets/Icons/Close_Icon.svg";


function LanguagePopup({ setShowLanguagePopup, Language, Change_Language }) {

    return (
        <div className="popup d-flex align-items-center justify-content-center">
            <div className="popup-content-language">
                <div className="labelotp2">
                    <span className="enterotp-language">
                        <span>{Translation_Table.selectLanguage[Language]}</span>
                        <span
                            className="crosssvg pointer"
                            onClick={() => setShowLanguagePopup(false)}
                        >
                            <Close_Icon />
                        </span>
                    </span>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-evenly">
                            <div className="form-check">
                                <input
                                    className="form-check-input pointer"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="English"
                                    checked={Language === "en"}
                                    onChange={() => (Change_Language('en'), document.documentElement.lang = "en")}
                                />
                                <label className="form-check-label">
                                    {Translation_Table.english[Language]}
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input pointer"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="Hindi"
                                    checked={Language === "hi"}
                                    onChange={() => (Change_Language('hi'), document.documentElement.lang = "hi")}
                                />
                                <label className="form-check-label"> {Translation_Table.hindi[Language]}</label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-evenly">
                            <div className="form-check">
                                <input
                                    className="form-check-input pointer"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="Telugu"
                                    checked={Language === "te"}
                                    onChange={() => (Change_Language('te'), document.documentElement.lang = "te")}
                                />
                                <label className="form-check-label">{Translation_Table.telugu[Language]}</label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input pointer"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="Tamil"
                                    checked={Language === "ta"}
                                    onChange={() => (Change_Language('ta'), document.documentElement.lang = "ta")}
                                />
                                <label className="form-check-label">{Translation_Table.tamil[Language]}</label>
                            </div>
                        </div>
                    </div>
                    <div className="clrBoth" />
                </div>
            </div>
        </div>
    )
}

export default LanguagePopup