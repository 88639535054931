import React, { useEffect, useRef, useState } from "react";

// Components
import CasinoIcon from "./CasinoIcon";

// Assets
import LotteryIcon from "../../Assets/QtechCategoryIcons/LOTTERY.png";
import SlotsIcon from "../../Assets/QtechCategoryIcons/SLOT.png";
import VirtualSportIcon from "../../Assets/QtechCategoryIcons/VIRTUAL_SPORTS.png";
import VideoPokerIcon from "../../Assets/QtechCategoryIcons/VIDEO-POKER.png";
import LiveCasinoIcon from "../../Assets/QtechCategoryIcons/LIVE-CASINO.png";
import InstantWinIcon from "../../Assets/QtechCategoryIcons/INSTANTWIN.png";
import TableGameIcon from "../../Assets/QtechCategoryIcons/TABLE-GAME.png";
import CasinoImageIcon from "../../Assets/QtechCategoryIcons/CASINO.png";

// Styles
import "../../Pages/Casino/Casino.css";

const QTechCasinoTabs = ({ categories, selectedCategory, changeCategory }) => {
    const scrollingWrapperRef = useRef(null);

    const [iconWidth, setIconWidth] = useState(0);

    useEffect(() => {
        const selectedIndex = categories.findIndex((item) => item.Enum === selectedCategory);
        if (selectedIndex !== -1 && scrollingWrapperRef.current) {
            const scrollLeftValue = selectedIndex * iconWidth;
            scrollingWrapperRef.current.scrollLeft = scrollLeftValue;
        }
    }, [categories]);

    return (
        <>
            <div className="scrolling-wrapper-casio row" ref={scrollingWrapperRef}>
                {categories.map((item, index) => (
                    <CasinoIcon
                        key={index}
                        onClick={() => changeCategory(item.category)}
                        Category={item.Enum}
                        image={
                            item.category === "LOTTERY"
                                ? LotteryIcon
                                : item.category === "TABLEGAME"
                                ? TableGameIcon
                                : item.category === "INSTANTWIN"
                                ? InstantWinIcon
                                : item.category === "LIVECASINO"
                                ? LiveCasinoIcon
                                : item.category === "VIDEOPOKER"
                                ? VideoPokerIcon
                                : item.category === "VIRTUAL_SPORTS"
                                ? VirtualSportIcon
                                : CasinoImageIcon
                        }
                        imageNew={
                            item.category === "LOTTERY"
                                ? LotteryIcon
                                : item.category === "TABLEGAME"
                                ? TableGameIcon
                                : item.category === "INSTANTWIN"
                                ? InstantWinIcon
                                : item.category === "LIVECASINO"
                                ? LiveCasinoIcon
                                : item.category === "VIDEOPOKER"
                                ? VideoPokerIcon
                                : item.category === "VIRTUAL_SPORTS"
                                ? VirtualSportIcon
                                : CasinoImageIcon
                        }
                        title={item.category ? item.category : "SLOTS"}
                        Selection_Status={selectedCategory === item.Enum}
                        setIconWidth={setIconWidth}
                    />
                ))}
            </div>
        </>
    );
};

export default QTechCasinoTabs;
