import React from 'react';

// Styles
import './SideNavigationBlock.css'

function SideNavigationBlock({ text }) {
    return (
        <div className='lobby-btn'>
            {text}
        </div>
    )
}

export default SideNavigationBlock