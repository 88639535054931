import React, { useState } from 'react'
import "./MarketComponent.css"
import WhoWillWinCard from "../../../ExchangeReusableCards/WhoWillWinCard/WhoWillWinCard"
import FancyBetsSection from "./FancyBets/FancyBetsSection"
import ExchangeDataCard from '../../../ExchangeReusableCards/ExchangeDataCard/ExchangeDataCard'
const MarketComponent = ({ marketData, gameCode, gameId }) => {

  const [activeTeamInDetailsChart, setActiveTeamInDetailsChart] = useState("FIRST_TEAM")




  return <>

    <section className="sports-market-section">

      <div className="sport-bet-details">

        {
          marketData?.odds_market?.length && marketData?.odds_market ?
            <div className="match-odds">
              <ExchangeDataCard marketType={"ODDS"} gameId={gameId} gameCode={gameCode} rowData={marketData?.odds_market} exchangeDataCardTitle={"Match ODDS"} showCashout={false} showIcon={true} />
            </div>
            : ""
        }

        {
          marketData?.bookmaker?.length && marketData?.bookmaker ?
            <div className="bookmaker">
              <ExchangeDataCard marketType={"BOOKMAKER"} gameId={gameId} gameCode={gameCode} rowData={marketData?.bookmaker} exchangeDataCardTitle={"Book Maker"} showCashout={false} showIcon={true} />
            </div> : ""
        }

        {
          marketData?.extra_market?.length && marketData?.extra_market[0]?.marketName === "TOSS" && marketData?.extra_market[0]?.odds?.length ?
            <div className="toss">
              <ExchangeDataCard marketType={"TOSS"} gameId={gameId} gameCode={gameCode} rowData={marketData?.extra_market[0]?.odds} exchangeDataCardTitle={"Toss"} showCashout={false} showIcon={true} />
            </div> : ""
        }

        {
          marketData?.extra_market?.length && marketData?.extra_market[1]?.marketName === "WHO WILL WIN" && marketData?.extra_market[1]?.odds?.length ?
            <div className="suspended-match-details">
              <ExchangeDataCard marketType={"WHO WILL WIN"} gameId={gameId} gameCode={gameCode} rowData={marketData?.extra_market[1]?.odds} exchangeDataCardTitle={"Who will win the match?"} showCashout={false} showIcon={true} />
            </div> : ""
        }



        <div className="fancy-match-sectiono">
          <FancyBetsSection gameId={gameId} gameCode={gameCode} fancy={marketData?.fancy} />
        </div>


      </div>
    </section>

  </>
}

export default MarketComponent
