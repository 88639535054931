import React, { useState, useContext, useRef } from "react";
import './RightSideNavigation.css';
import { ReactComponent as User_Icon } from "../../Assets/Icons/user.svg";
import { ReactComponent as Balance_Info } from "../../Assets/Icons/info1.svg";
import { ReactComponent as Statement_Icon } from "../../Assets/Icons/statement.svg";
import { ReactComponent as Password_Icon } from "../../Assets/Icons/password3.svg";
import { ReactComponent as Logout_Icon } from "../../Assets/Icons/logout2.svg";
import { ReactComponent as Transfer_Icon } from "../../Assets/Icons/transfer1.svg";
import { ReactComponent as OpenBet_Icon } from "../../Assets/Icons/open_bet.svg";
import { User_Context } from "../../Contexts/User";
import { useNavigate } from "react-router-dom";
import Logout_User from "../../Utilities/Logout";
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";
import { ReactComponent as Cross } from "../../images/svg/cross-blk.svg";

function RightSideNavigation({closeRightSideMenu}) {
    const navigate = useNavigate();
    const { User, Set_User } = useContext(User_Context);
    const [modalChangePassword, setModalChangePassword] = useState(false);
    const handleAccountStatementClick = () => {
        // Handle click for Account Statement
        console.log('Account Statement clicked');
    };

    const handleChangePasswordClick = () => {
        // Handle click for Change Password
        console.log('Change Password clicked');
    };


    const logOut = () => {
		Logout_User(Set_User)
        navigate('/')
	}

    return (
        <div>
            <div className="financial-details">
                {/* <Cross
                        onClick={closeRightSideMenu}
                        className="crs-menu"
                        width="1.5rem"
                        height="1.5rem"
                /> */}
                <div className="name-header">My Account</div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <User_Icon className="menu-icon" />
                <h3 className="balance-info-heading">User Information</h3>
                </div>
                <div className="detail-row">
                    <div className="label">Name:</div>
                    <div className="value">{(User.data.name)}</div>
                </div>
                <div className="detail-row">
                    <div className="label">Mobile:</div>
                    <div className="value">{(User.data.mobile)}</div>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <Balance_Info className="menu-icon" />
                <h3 className="balance-info-heading">Balance Information</h3>
                </div>
                <div className="detail-row">
                    <div className="label">Available Credit:</div>
                    <div className="value">{(User.data.balance)?.toFixed(2)}</div>
                </div>
                <div className="detail-row">
                    <div className="label">Credit Limit:</div>
                    <div className="value">{(User.data.initialBalance)?.toFixed(2)}</div>
                </div>
                <div className="detail-row">
                    <div className="label">Winning:</div>
                    <div className="value" style={{ color: (User.data.initialBalance-(User.data.balance + User.data.exposer)) > 0 ? 'red' : '#14e114' }}>{(((User.data.balance + User.data.exposer) - User.data.initialBalance)).toFixed(2)}</div>
                </div>
                <div className="detail-row">
                    <div className="label">Net Exposure:</div>
                    <div className="value">{(User.data.exposer)?.toFixed(2)}</div>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <OpenBet_Icon className="menu-icon" />
                <h6 className="account-statement" onClick={()=>{navigate("/pending-bet");closeRightSideMenu()}}>Open Bets</h6>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <Statement_Icon className="menu-icon" />
                <h6 className="account-statement" onClick={()=>{navigate("/master-account-statement");closeRightSideMenu()}}>Account Statement</h6>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <Transfer_Icon className="menu-icon" />
                <h6 className="account-statement" onClick={()=>{navigate("/transfer-statement");closeRightSideMenu()}}>Transfer Statement</h6>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <Password_Icon className="menu-icon" />
                <h6 className="change-password" onClick={() =>{setModalChangePassword(true);closeRightSideMenu()}}>Change Password</h6>
                </div>
                <hr className="horizontal-line" />
                <div className="header-logo-container-mobile" style={{marginBottom:"10px"}}>
                <Logout_Icon className="menu-icon" />
                <h6 className="change-password" onClick={()=>{logOut();closeRightSideMenu()}}>Log Out</h6>
                {/* <p className="text-content" onClick={() => logOut()}>{Translation_Table.logout[Language]}</p> */}
                </div>
                <hr className="horizontal-line" />
                {/* modal for change password */}
                <ChangePasswordModal show={modalChangePassword} onHide={() =>{setModalChangePassword(false)}}
                />
            </div>
        </div>
    );

}

export default RightSideNavigation;