import React, {useContext} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { QTechUrlLobbyTypes, WacsUrlLobbyTypes } from '../../Configuration/Enums/QTechLobbyUrlValues';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, FreeMode, Pagination } from 'swiper/modules';
import { WACS_Endpoint } from '../../Configuration/Settings';
import { WACS_Payment_Modes } from '../../Configuration/Enums/WACS_Payment_Modes';
import { User_Context } from '../../Contexts/User';


// Assets
import EvolutionImage from '../../Assets/Images/home-page-lobby-banners/evolution.png';
import EzugiImage from '../../Assets/Images/home-page-lobby-banners/ezugi.png';
import SaImage from '../../Assets/Images/home-page-lobby-banners/sa.png';
import SpribeImage from '../../Assets/Images/home-page-lobby-banners/spribe.png';
import BeterImage from '../../Assets/Images/home-page-lobby-banners/beter.png';
import MojoImage from '../../Assets/Images/home-page-lobby-banners/mojos.png';
import VivoImage from '../../Assets/Images/home-page-lobby-banners/vivo.png';
import BetGamesImage from '../../Assets/Images/home-page-lobby-banners/bet-games.png';

// Styles
import './GameLobbyBanners.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "swiper/css/autoplay";
import { SessionHelper } from '../../Utilities/SessionHelper';

function GameLobbyBanners() {
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const navigate = useNavigate();
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const { User } = useContext(User_Context)
    const onClickImage = async (type) => {
        if (type === "evolution") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.Evolution } })
        } else if (type === "ezugi") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.Ezugi } })
        } else if (type === "sa") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.SaGaming } })
        } else if (type === "spribe") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.Spribe } })
        } else if (type === "beter") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.Beter } })
        } else if (type === "mojos") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.SevenMojos } })
        } else if (type === "vivo") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.Vivo } })
        } else if (type === "betGames") {
            navigate('/qtech-casino-game', { state: { gameId: QTechUrlLobbyTypes.BetGames } })
        }
    }
    const onClickWacs = async (type) => {
        if (type === "evolution") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Evolution}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (type === "ezugi") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Ezugi}&type=${WACS_Payment_Modes.Charged}` } })
        }
         else if (type === "spribe") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Spribe}&type=${WACS_Payment_Modes.Charged}` } })
        }
         else if (type === "mojos") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.SevenMojos}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (type === "vivo") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Vivo}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (type === "betGames") {
            navigate('/casino-game', { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.BetGames}&type=${WACS_Payment_Modes.Charged}` } })
        }
    }

    let bannerArray = [
        { image: EvolutionImage, type: 'evolution' },
        { image: EzugiImage, type: 'ezugi' },
        { image: SpribeImage, type: 'spribe' },
    ]

    if (activeCasinoProvider === "QTECH") {
        bannerArray.splice(2, 0, { image: SaImage, type: 'sa' });
    }

    let bannerArray1 = [
        { image: MojoImage, type: 'mojos' },
        { image: VivoImage, type: 'vivo' },
        { image: BetGamesImage, type: 'betGames' }
    ]
    
    if (activeCasinoProvider === "QTECH") {
        bannerArray1.unshift({ image: BeterImage, type: 'beter' });
    }



    return (
        <>
            <div className="topgms">
                <div className='component-heading-block' />
                <span className="spngame">
                    TOP PROVIDERS
                </span>
            </div>
            {isTabOrMobile ? <div className='swiper-container'>
                <Swiper
                    id='game-swiper'
                    slidesPerView={2}
                    slidesPerColumn={2}
                    className='myGameSwiper'
                    freeMode={true}
                    loop={false}
                    autoplay={{ delay: 2500 }}
                    slidesPerGroup={1}
                    navigation={true}
                    pagination={true}
                    modules={[FreeMode, Navigation, Pagination, Autoplay]}
                >
                    {bannerArray.map((item, index) => (
                        <SwiperSlide key={index} style={{ width: '220px' }}>
                            <div className="game-lobby-row">
                                <LazyLoadImage
                                    key={index}
                                    onClick={ activeCasinoProvider === "QTECH" ? () => onClickImage(item.type) : () => onClickWacs(item.type)}
                                    className="game-lobby-image"
                                    src={item.image}
                                    alt="Fairbets"
                                />
                            </div>
                            {bannerArray1[index] && (
                                <div className="game-lobby-row">
                                    <LazyLoadImage
                                        key={index}
                                        onClick={activeCasinoProvider === "QTECH" ? () => onClickImage(bannerArray1[index].type) : () => onClickWacs(bannerArray1[index].type)}
                                        className="game-lobby-image"
                                        src={bannerArray1[index]?.image}
                                        alt="Fairbets"
                                    />
                                </div>
                            )}

                        </SwiperSlide>
                    ))}
                </Swiper>
            </div> : <div className='swiper-container'>
                <Swiper
                    id='game-swiper'
                    slidesPerView={3}
                    slidesPerColumn={2}
                    className='myGameSwiper'
                    freeMode={true}
                    loop={false}
                    autoplay={{ delay: 2500 }}
                    slidesPerGroup={1}
                    navigation={true}
                    pagination={true}
                    modules={[FreeMode, Navigation, Pagination, Autoplay]}
                >
                    {bannerArray.map((item, index) => (
                        <SwiperSlide key={index} style={{ width: '220px' }}>
                            <div className="game-lobby-row">
                                <LazyLoadImage
                                    key={index}
                                    onClick={ activeCasinoProvider === "QTECH" ? () => onClickImage(item.type) : () => onClickWacs(item.type)}
                                    className="game-lobby-image"
                                    src={item.image}
                                    alt="Fairbets"
                                />
                            </div>
                            {bannerArray1[index] && (
                                <div className="game-lobby-row">
                                    <LazyLoadImage
                                        key={index}
                                        onClick={ activeCasinoProvider === "QTECH" ? () => onClickImage(bannerArray1[index].type) : () => onClickWacs(bannerArray1[index].type)}                                        
                                        className="game-lobby-image"
                                        src={bannerArray1[index]?.image}
                                        alt="Fairbets"
                                    />
                                </div>
                            )}

                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>}

        </>
    );
}

export default GameLobbyBanners;
