import React, { useCallback, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SessionHelper } from '../../Utilities/SessionHelper';
import { Various_APIs } from '../../API/Various_APIs';
import { Providers_Enums } from '../../Configuration/Enums/Providers';
import { useNavigate } from 'react-router-dom';

// Components
import MainCarousel from '../Carousel/MainCarousel';

// Styles
import './Banner.css'


function MainBanner({ data, navigateTo }) {
    const navigate = useNavigate()
    const getAppConfig = async () => {
        const appConfig = await Various_APIs.Get_Landing_Page_Data();
        SessionHelper.setActiveExchangeProvider(
            appConfig?.data?.data?.payload?.activeCasinoProvider ??
            Providers_Enums.CAPITAL_QTECH
        );
    };

    useEffect(() => {
        getAppConfig()
    }, [])
    
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    
    const renderItem = useCallback(({ item, index, event }) => {
        return (
            <>
             {item.iframeUrl === "others" ?
                    <div
                        key={index}
                        onClick={() => {
                            window.location.href = 'https://www.dpbossonline.com/Fairbets-v1.2.0.apk'
                        }}
                    >
                        <LazyLoadImage
                            className="mainBannerImage"
                            src={item.imagePath}
                            alt="Fairbets"
                        />
                    </div> :
                    
                    <div
                        key={index}
                        onClick={(e) => {
                            const url = item.iframeUrl === 'Deposit' ? 'deposit?data=deposit' : item.iframeUrl;
                            navigateTo(url);
                        }}
                    >
                        <LazyLoadImage
                            className="mainBannerImage"
                            src={item.imagePath}
                            alt="Fairbets"
                        />
                    </div>
                }
                
            </> 

        )
    }, [])

    return (
        <MainCarousel
            data={data}
            noModules={true}
            children={renderItem}
            slidesPerGroup={1}
        />
    )
}

export default MainBanner;