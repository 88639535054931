export const Clear_Cache = (Reloading_after_Clearing_the_Cache_Status) => 
{
	if ('caches' in window)
	{
		caches.keys ().then (Keys => 
		{
			Keys.forEach (async Request => 
			{
				await caches.delete (Request)
			})
		})
		if (Reloading_after_Clearing_the_Cache_Status)
		{
			window.location.reload (true)
		}
	}
}