import React, { useEffect, useRef } from "react";
import "./Game_iframe.css"

const Game_iframe = ({HTML, JavaScript}) =>
{
	const External_Code = useRef (null);

	useEffect (() =>
	{
		External_Code.current.innerHTML = HTML;
		const Game_JavaScript = document.createElement ('script');
		Game_JavaScript.innerHTML = JavaScript;
		document.head.appendChild (Game_JavaScript);
	}, [HTML, JavaScript]);
	
	return <div className='Game_iframe' ref={External_Code}></div>
}

export default Game_iframe;