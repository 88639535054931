import React, { useContext, useState } from 'react'
import { ExchangeContexts } from '../../../../Contexts/ExchangeContext';
import PlaceMatchBetCard from '../PlaceMatchBetCard/PlaceMatchBetCard';
import starIcon from "../../../../Assets/new-exchange/Exchange-sports-images/starIcon.png"
import "./ExchangeDataRow.css"
import { createUniqueKey } from '../../../../Utilities/ExchangeUniqueKey';
const ExchangeDataRow = ({ match, gameId }) => {
  const [betData, setBetData] = useState({ isBack: Boolean, gameId: gameId, run: "", runner: "" });
  const usePlaceBetContext = useContext(ExchangeContexts);

  const onMakeBet = (data) => {
   
    // Update the place bet data object
    setBetData({ ...data, gameId: gameId });

    const uniqueKey = createUniqueKey(match);

    // Toggle the active match place bet section using the unique key
    usePlaceBetContext.setActiveMatchPlaceBetSection(prevState => ({
      [uniqueKey]: !prevState[uniqueKey],
    }));
  };



  const uniqueKey = createUniqueKey(match);

  return <>

    <div className="exchange-data-card-matches-row">
      <div className="exchange-data-card-matches-names-wrapper">
        <div className="exchange-data-match-bets-title">{match?.runnerName}</div>
        <div className="exchange-data-back-lay-boxes">
          <div className="exchange-data-back-box"
            style={{ backgroundColor: match?.back === 0 ? "#aebade" : "#0f36ac" }}
            onClick={() => {
              if (match?.back !== 0) {
              
                onMakeBet({
                  match: match,
                  isBack: true,
                  run: match?.back,
                  gameId: gameId
                });
              } 
            }}>
            <div className="exchange-data-bet-value">
              {match?.back !== 0 ? match?.back : ""}
            </div>
            <div className="exchange-data-bet-amount" hidden></div>
          </div>
          <div className="exchange-data-lay-box"

            style={{ backgroundColor: match?.lay === 0 ? "#f1c6c0" : "#e85d4a" }}

            onClick={() => {

              if (match?.lay !== 0) {
                
                onMakeBet({
                  match: match,
                  isBack: false,
                  run: match?.lay,
                  gameId: gameId
                });
              } 
            }}
          >
            <div className="exchange-data-bet-value">
              {match?.lay !== 0 ? match?.lay : ""}

            </div>
            <div className="exchange-data-bet-amount" hidden></div>
          </div>
        </div>
      </div>
      {
        usePlaceBetContext.activeMatchPlaceBetSection[uniqueKey] && <PlaceMatchBetCard betData={betData} openedMatchPlaceBetSection={uniqueKey} />
      }
    </div>

  </>
}

export default ExchangeDataRow