import { createContext, useState } from 'react';
import Cookies from "universal-cookie";

const Cookie = new Cookies ();

const Initial_Language = 
{
	Language: Cookie.get ('language') ? Cookie.get ('language') : 'en',
	Set_Language: () => {}
}

export const Language_Context = createContext (Initial_Language);

const Language_Provider = ({ children }) =>
{
	const [Language, Set_Language] = useState (Initial_Language.Language);
	return <Language_Context.Provider value={{ Language, Set_Language }}>{children}</Language_Context.Provider>;
}

export default Language_Provider;