import Cookies from 'universal-cookie';

const Logout_User = (Set_User) => {
  const cookies = new Cookies();
  Set_User({ token: "", data: {} });
  cookies.remove("userData");
  cookies.remove ('jwt-authorization');
};


export default Logout_User;