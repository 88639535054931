import React, { useContext } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from '../../Contexts/Language';

// Components
import GeneralButton from '../../components/Buttons/GeneralButton';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';

// Assets
import { ReactComponent as LanguageIcon } from "../../Assets/Images/language.svg";
import { ReactComponent as DropperIcon } from "../../Assets/Images/dropper.svg";

// styles
import './BonusWalletTerms.css'
import { useNavigate } from 'react-router-dom';

function BonusWalletTerms1() {
    const { Language } = useContext(Language_Context);
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
    const navigate = useNavigate();

    const handleOnClick = () => navigate('/deposit');

    const showStartEarningButton = !window?.location?.href?.includes('mobile');

    return (
        <div className={isTabOrMobile ? 'mx-3' : 'mx-5 mb-5'}>
            <div className='language-dropper-container'>
                <LanguageDropdown />
            </div>
            <div className='bonus-wallet-terms-header'>
                <p className='bonus-wallet-terms-header-text'>{Translation_Table.TermsandConditions[Language]?.toUpperCase() ?? ''}</p>
            </div>

            <div className='bonus-wallet-terms-body'>
                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms1[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms2[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms3[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms4[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms5[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondTerms6[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusSecondNoteTerms[Language]}</p>
            </div>

            {
                showStartEarningButton ? 
                <div className='bonus-wallet-terms-button-container'>
                    <GeneralButton
                        onClick={handleOnClick}
                        showText={true}
                        text={'START EARNING'}
                    />
                </div>
                :
                null
            }
        </div>
    )
}

export default BonusWalletTerms1;