import React from 'react';
import { useNavigate } from 'react-router-dom';

// Assets
import LiveCasinoBanner from '../../Assets/Images/live-casino.png';
import QTechBanner from '../../Assets/Images/QtechBannerImage.png';

// Styles
import './CasinoSelect.css'
import { SessionHelper } from '../../Utilities/SessionHelper';
import { Providers_Enums } from '../../Configuration/Enums/Providers';

function CasinoSelect() {
    const navigate = useNavigate()
    
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const handleLiveCasinoBannerClick = () => navigate('/casino-category');
    const handleBannerClick = () => {
        if(activeCasinoProvider == Providers_Enums.CAPITAL_WACS){
            handleLiveCasinoBannerClick();
            return;
        }
        navigate('/casino-qtech');
    }
    return (
        <div className='casino-select-container'>
            <div className='casino-select-text-container'>
                <p className='casino-select-text' style={{ marginBottom: 0 }}>Select a Casino type</p>
            </div>


            <div className='casino-select-image-container' onClick={handleLiveCasinoBannerClick}>
                <img src={LiveCasinoBanner} className='mainBannerImage' />
            </div>

            <div className='casino-select-image-container' onClick={handleBannerClick}>
                <img src={QTechBanner} className='mainBannerImage' />
            </div>


        </div>
    )
}

export default CasinoSelect;