import React from 'react';
import { TextField } from "@mui/material";
import { onEmailInputKeyDown, onNumerInputKeyDown } from '../../Utilities/InputValidators';

function GeneralTextfield({onBlur, onChange, error, helperText, value, label, type, multiline, rows, autoComplete, disabled, inputProps }) {

    return (
        <TextField
            onChange={(e) => onChange(e)}
            error={error}
            helperText={helperText}
            value={value}
            id="outlined-basic"
            type={type}
            fullWidth
            label={label}
            autoComplete={autoComplete}
            disabled={disabled}
            variant="outlined"
            size="small"
            multiline={multiline}
            rows={rows}
            onBlur={onBlur}
            onKeyDown={(e) => type === "number" ? onNumerInputKeyDown(e) : type === "email" ? onEmailInputKeyDown(e) : null}
            InputLabelProps={{
                style: {
                    fontFamily: "Roboto",
                    fontSize: "14px",
                },
            }}
            inputProps={inputProps}
        />
    )
}

export default GeneralTextfield