import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-toastify/dist/ReactToastify.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5', // Replace with your desired primary color
        },
    },
});

ReactDOM.createRoot(document.getElementById("root")).render(<BrowserRouter>
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>

</BrowserRouter>);

serviceWorkerRegistration.register();