import React from 'react'
import { Card, CardContent } from "@mui/material";
import { ReactComponent as Pending } from "../../images/svg/clock-svgrepo-com.svg";
import { ReactComponent as Lossarrow } from "../../images/svg/arrow-down.svg";
import { ReactComponent as Profitarrow } from "../../images/svg/arrow-up.svg";

// Styles
import './BetHistory.css';

function Satta_Matka_Card({ bazar_name, bet_id, game_name, market_type, aankda, result_date, amount, Bet_Date, time, type, title, point, winningAmount, resultTime }) {
    return (
        // <div className='card'>
        //     <div className='card-body p-0'>

        //         <div className="bethis-card">
        //             <div className="card-child">
        //                 {type === "pending" && <Pending className="profile-bet" />}
        //                 {type === "loss" && <Lossarrow className="profile-bet" />}
        //                 {type === "win" && <Profitarrow className="profile-bet" />}
        //             </div>
        //             <div className="loss-bet">
        //                 <span className="text-bethis">{title}</span>
        //             </div>

        //         </div>
        //     </div>
        //     <div className='p-3'>
        //         <h6 className="font-weight-bolder">Satta Matka Bets:</h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             BAZAR NAME:
        //             <span className="betamount">
        //                 {bazar_name}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             BET ID:
        //             <span className="betamount">
        //                 {bet_id}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             GAME NAME:
        //             <span className="betamount">
        //                 {game_name}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             MARKET TYPE:
        //             <span className="betamount">
        //                 {market_type}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             RESULT DATE:
        //             <span className="betamount">
        //                 {result_date}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             Bet On:
        //             <span className="betamount">
        //                 {aankda}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             AMOUNT:
        //             <span className="betamount">
        //                 {amount}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             Bet Date:
        //             <span className="betamount">
        //                 {Bet_Date}
        //             </span>
        //         </h6>
        //         <h6 className="mb-1 pt-2 text-bold">
        //             TIME:
        //             <span className="betamount">
        //                 {time}
        //             </span>
        //         </h6>
        //     </div>
        // </div>

        <Card>
            <CardContent className="content-bet">
                <div className="bethis-card">
                    <div className="card-child">
                        {type === "pending" && <Pending className="profile-bet" />}
                        {type === "loss" && <Lossarrow className="profile-bet" />}
                        {type === "win" && <Profitarrow className="profile-bet" />}
                    </div>
                    <div className="loss-bet">
                        <span className="text-bethis">{title}</span>
                    </div>
                </div>
                <div className="amount-bethis">
                    <span> {amount}</span>
                </div>

                <div className="bet-content-container">


                    <p className='bet-market-name mb-3'>Satta Matka Bet:</p>

                    {bazar_name && <p className='bet-market-name'>{`Bazar Name: ${bazar_name}`}</p>}

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Game Name: ${game_name}`}</p>
                    </div>

                    {market_type &&
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Game Type: ${market_type}`}</p>
                        </div>
                    }

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Result Date: ${result_date}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Bet on: ${aankda}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Bet Made on: ${Bet_Date}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Ref ID: ${bet_id}`}</p>
                    </div>

                    {time && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Bet Made On: ${time}`}</p>
                        </div>
                    )}

                    {resultTime && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Bet Upated On: ${resultTime}`}</p>
                        </div>
                    )}

                    {point && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Point: ${point}`}</p>
                        </div>
                    )}

                    {winningAmount && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Winning Amount: ${winningAmount}`}</p>
                        </div>
                    )}

                </div>
            </CardContent>
        </Card >
    )
}

export default Satta_Matka_Card