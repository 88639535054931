export default
    {
        Convert_Timestamp_to_the_Indian_Standard_Tome: (times) =>
        {
            const Current_Time = new Date (times);
            const Current_Offset = Current_Time.getTimezoneOffset ();
            const IST_Offset = 5 * 60 + 30;
            const Millisecond_Multiplier = 60 * 1000;
            return new Date(Current_Time.getTime() + (IST_Offset + Current_Offset) * Millisecond_Multiplier);
        },
        dateTime: (timestamp) => new Date(timestamp).toISOString().split('T')[0],
        dateTimeComplete: (timestamp) => {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        },
        dateTimeCompleteIST: (timestamp) => {
            const date = new Date(timestamp);

            // Add 5.5 hours to the current time
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);

            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        },
        extractTimeFromISOString: (timestamp) => {
            const dateObj = new Date(timestamp);

            // Extract hour, minute, and second
            const hour = dateObj.getUTCHours();
            const minute = dateObj.getUTCMinutes();
            const second = dateObj.getUTCSeconds();

            // Format the time as HH:mm:ss
            const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;

            return formattedTime;
        },
        extractTimeFromISOStringIST: (timestamp) => {
            const dateObj = new Date(timestamp);

            // Add 5.5 hours to the current time
            dateObj.setHours(dateObj.getHours() + 5);
            dateObj.setMinutes(dateObj.getMinutes() + 30);

            // Extract hour, minute, and second
            const hour = dateObj.getUTCHours();
            const minute = dateObj.getUTCMinutes();
            const second = dateObj.getUTCSeconds();

            // Format the time as HH:mm:ss
            const formattedTime = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;

            return formattedTime;
        }


    }