import React from 'react';
import { Card, CardContent } from "@mui/material";
import { ReactComponent as Pending } from "../../images/svg/clock-svgrepo-com.svg";
import { ReactComponent as Lossarrow } from "../../images/svg/arrow-down.svg";
import { ReactComponent as Profitarrow } from "../../images/svg/arrow-up.svg";

// Styles
import './BetHistory.css';

function FalconCard({ title, type, amount, date, marketName, runnerName, reviewID, betType, marketID, transactionID, rate }) {


    return (
        <Card>
            <CardContent className="content-bet">
                <div className="bethis-card">
                    <div className="card-child">
                        {type === "pending" && <Pending className="profile-bet" />}
                        {type === "loss" && <Lossarrow className="profile-bet" />}
                        {type === "settled" && <Profitarrow className="profile-bet" />}
                    </div>
                    <div className="loss-bet">
                        <span className="text-bethis">{title}</span>
                    </div>
                </div>
                <div className="amount-bethis">
                    <span> {amount}</span>
                </div>

                <div className="bet-content-container">


                    <p className='bet-market-name mb-3'>F Exchange Bet:</p>

                    <p className='bet-market-name'>{`Market Name: ${marketName}`}</p>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Runner Name: ${runnerName}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Rate: ${rate}`}</p>
                    </div>


                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Bet Type: ${betType}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`MarketID: ${marketID}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Transaction ID: ${transactionID}`}</p>
                    </div>


                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Date: ${date}`}</p>
                    </div>


                    <div className='mt-5'>
                        <p className='text-bethis1'>{`Ref ID: ${reviewID}`}</p>
                    </div>

                </div>
            </CardContent>
        </Card >
    )
}

export default FalconCard;