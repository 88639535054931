export const Casino_Game_Categories_Enums = 
Object.freeze ({
    Top_Games: 'top-games',
    Roulette: 'roulette',
    Live_Casino: 'live-casino',
    Blackjack: 'blackjack',
    Baccarat: 'baccarat',
    Dragon_Tiger: 'dragon-tiger',
    Poker: 'poker',
    Ezugi: 'ezugi-games',
    Evolution: 'evolution-games',
    Virtual: 'virtual-games',
    Andar_Bahar: 'andar-bahar',
    Slots: 'slots',
    instantWin: 'INSTANTWIN',
    tableGame: 'tableGame',
    lottery: 'lottery',
    shooting: 'shooting',
    scratchCard: 'scratchCard',
});


export const newCasinoCategoriesMapping = {
    [Casino_Game_Categories_Enums.Virtual]: 'VIRTUAL_SPORTS',
    [Casino_Game_Categories_Enums.Poker]: 'VIDEOPOKER',
    [Casino_Game_Categories_Enums.Live_Casino]: 'LIVECASINO',
    [Casino_Game_Categories_Enums.instantWin]: 'INSTANTWIN',
    [Casino_Game_Categories_Enums.tableGame]: 'TABLEGAME',
    [Casino_Game_Categories_Enums.lottery]: 'LOTTERY',
    [Casino_Game_Categories_Enums.shooting]: 'SHOOTING',
    [Casino_Game_Categories_Enums.scratchCard]: 'SCRATCHCARD',
    [Casino_Game_Categories_Enums.Slots]: 'SLOTS' 
}