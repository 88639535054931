import React, { useContext, useEffect, useState } from 'react'
import "./ScoreCardStyle.css"
import LiveMatchScoreRow from "../../../ExchangeReusableCards/LiveMatchScoreRow/LiveMatchScoreRow"

import ExchangeDataCard from "../../../ExchangeReusableCards/ExchangeDataCard/ExchangeDataCard"
import { ExchangeMarketDataContext } from "../../../../../Contexts/ExchangeMarketContext"
import WhoWillWinCard from '../../../ExchangeReusableCards/WhoWillWinCard/WhoWillWinCard'
import { Exchange_APIs } from '../../../../../API/Exchange_APIs'
import { User_Context } from '../../../../../Contexts/User'
import LiveMatchStateComponent from '../LiveMatchStates/LiveMatchStateComponent'
import { toast } from 'react-toastify'
const ScoreCard = ({ hideMatchScore,evId }) => {

  const useMarketContext = useContext(ExchangeMarketDataContext)
  const [marketData, setMarketData] = useState(useMarketContext?.marketData)
  const { User, Set_User } = useContext(User_Context);

  const fetchLiveScore = () => {
    // call Live score API
    const API_RESPONSE = Exchange_APIs.GET_LIVE_SCORE(User.token);
    API_RESPONSE.then((response) => {
 
    }).catch((error) => {
      toast.error(error.message)
    })
  }

  useEffect(() => {
    // fetchLiveScore();
  }, [])


  return <>

    <section className="scorecard-section" style={{ paddingTop: '0px' }}>
      {
        !hideMatchScore &&
        <div className="scorecard-total-scores-list" >
          {/* live score card --------------- */}
          <LiveMatchStateComponent evId={evId}/>
          {/* live match score row --------- */}
        </div>
      }

      <div className="live-match-odds-and-bookmaker" hidden>
        <div className="live-match-odds">
          {marketData?.odds_market && marketData?.odds_market.length !== 0 &&
            <ExchangeDataCard marketType={"ODDS"} rowData={useMarketContext.marketData.odds_market} showCashout={false} showIcon={false} exchangeDataCardTitle={"Match OODS"} />
          }

        </div>
        <div className="live-match-book-maker">
          {marketData?.bookmaker && marketData?.bookmaker.length !== 0 &&
            <ExchangeDataCard rowData={useMarketContext.marketData.bookmaker} showCashout={false} showIcon={false} exchangeDataCardTitle={"Book Maker"} />
          }
        </div>


        {
          marketData["WHO WILL WIN"] && marketData["WHO WILL WIN"]?.length !== 0 &&
          <div className="suspended-match-details">
            <WhoWillWinCard whoWilWin={marketData["WHO WILL WIN"]} showIcon={false} showCashout={false} />
          </div>
        }



        <div className="who-wil-win-section">
        </div>
      </div>
    </section>


  </>
}

export default ScoreCard
