import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./SideNavigation.css";
// Components
import { Translation_Table } from "../../Configuration/Translation";
import { Casino_Game_Categories_Enums } from "../../Configuration/Enums/Casino_Game_Categories";
import { QTechUrlLobbyTypes } from "../../Configuration/Enums/QTechLobbyUrlValues";
import SideNavigationBlock from "../SideNavigationBlock/SideNavigationBlock";

// Assets
import { ReactComponent as Cross } from "../../images/svg/cross-blk.svg";
import { ReactComponent as Homesvg } from "../../Assets/sidebarIcons/new-home-icon.svg";
import { ReactComponent as Evolutionsvg } from "../../Assets/sidebarIcons/new-evolution-icon.svg";
import { ReactComponent as Supportsvg } from "../../Assets/sidebarIcons/new-support-icon.svg";
import { ReactComponent as Topgamessvg } from "../../Assets/sidebarIcons/new-topgames-icon.svg";
import { ReactComponent as Virtualssvg } from "../../images/menu-icons/new-virtual-icon.svg";
import { ReactComponent as Blackjacksvg } from "../../Assets/sidebarIcons/new-blackjack-icon.svg";
import { ReactComponent as Roulettesvg } from "../../Assets/sidebarIcons/new-roulette-icon.svg";
import { ReactComponent as Andarbaharsvg } from "../../Assets/sidebarIcons/new-andarbahar-icon.svg";
import { ReactComponent as Dragonsvg } from "../../Assets/sidebarIcons/new-dragon-icon.svg";
import { ReactComponent as Baccaratsvg } from "../../Assets/sidebarIcons/new-baccarat-icon.svg";
import { ReactComponent as Pokersvg } from "../../Assets/sidebarIcons/new-poker-icon.svg";
import { ReactComponent as Feedbacksvg } from "../../Assets/sidebarIcons/new-feedback-icon.svg";
import { ReactComponent as Whatsappsvg } from "../../Assets/sidebarIcons/new-whatsapp-icon.svg";
import { ReactComponent as Telegramsvg } from "../../Assets/sidebarIcons/new-telegram-icon.svg";
import { ReactComponent as Twittersvg } from "../../Assets/sidebarIcons/new-twitter-icon.svg";
import { ReactComponent as Instagramsvg } from "../../Assets/sidebarIcons/new-instagram-icon.svg";
import { ReactComponent as Language_Icon } from "../../Assets/sidebarIcons/new-selectlanguage-icon.svg";
import { ReactComponent as Ezuginew } from "../../Assets/sidebarIcons/new-ezugi-icon.svg";
import LogoFairbets from "../../Assets/Icons/Fairbets-Logo.png";
import { WacsUrlLobbyTypes } from "../../Configuration/Enums/QTechLobbyUrlValues";
import { Game_Context } from "../../Contexts/Games";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";
import { User_Context } from "../../Contexts/User";
import { User_APIs } from "../../API/User_APIs";
import { decryptData } from "../../Utilities/DecryptData";
import { getRandomElement } from "../../Utilities/getRandomElement";
import Logout_User from "../../Utilities/Logout";
import { getMessaging, getToken, isSupported } from "@firebase/messaging";
import { toast } from "react-toastify";
import { firebaseConfig } from "../../Configuration/Firebase";
import { initializeApp } from "@firebase/app";
import { SessionHelper } from "../../Utilities/SessionHelper";
import { Providers_Enums } from "../../Configuration/Enums/Providers";
import { Button } from "@mui/material";

function SideNavigation({
    Language,
    setShowLanguagePopup,
    whatsapp,
    telegram,
    instagram,
    twitter,
    isMobile,
    closeSideMenu,
}) {
    const navigate = useNavigate();
    const { User, Set_User } = useContext(User_Context);
    const app = initializeApp(firebaseConfig);
    const [activeMenu, setActiveMenu] = useState("HOME");
    const [messaging, setMessaging] = useState(null);
    

    useEffect(() => {
        if (app) {
            isSupported()
                .then((res) => {
                    setMessaging(getMessaging(app));
                })
                .catch((error) => {
                    console.log("error ===========>", error);
                });
        }
    }, [app]);

    useEffect(() => {
        setActiveMenu("SELECT_LANGUAGE");
    }, [setShowLanguagePopup]);

    const onNavigateToLiveChat = async () => {
        try {
            setActiveMenu("SUPPORT");
            const response = await User_APIs.Get_Support_Executive_List({}, User.token);

            const responseData = response.data ? response.data : {};
            const { success, data } = responseData;
            if (success) {
                const chatSupportData = decryptData(data);
                const dp_id = getRandomElement(chatSupportData.payload);
                // console.log("chat suport data", chatSupportData);
                // getSupportFirebaseToken(dp_id)
                requestBrowserPermissions(dp_id);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
    };

    // console.log("==========> ", messaging, isSupported());
    const requestBrowserPermissions = (dp_id) => {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                if (messaging) {
                    getToken(messaging, {
                        vapidKey:
                            "BNw5X5Cq68O_tEY_t8d5x2t1dK6dQg91sqKYPOc364yPlHh4aIV6WLPIhrF-RmhLCnbBW_qyUYtqnAYKedrsJkQ",
                    })
                        .then((currentToken) => {
                            // console.log("========>current token", currentToken, dp_id);
                            if (currentToken) {
                                registerUserForChatApplication(currentToken, dp_id);
                            } else {
                                // Show permission request UI
                                // console.log('No registration token available. Request permission to generate one.');
                                // ...
                            }
                        })
                        .catch((err) => {
                            // console.log('An error occurred while retrieving token. ', err);
                            // ...
                        });
                }
            } else if (permission === "denied") {
                toast.info("Please enable notifications in your Browser settings to Proceed");
            }
        });
    };

    const registerUserForChatApplication = async (fcmToken, dp_id) => {
        try {
            let payload = {
                _id: User.data._id,
                supportExecutiveDp_Id: dp_id,
                mobile: User.data.mobile,
                name: User.data.name,
                fireBaseToken: fcmToken,
            };

            const response = await User_APIs.Register_User_For_Chat(payload, User.token);

            const responseData = response.data ? response.data : {};
            const { success, data } = responseData;
            if (success) {
                const decryptedData = decryptData(data);
                const User_Copy = { ...User };
                User_Copy.data.fireBaseToken = decryptedData.payload.fireBaseToken;
                User_Copy.data.supportExecutiveDp_Id = decryptedData.payload.supportExecutiveDp_Id;
                Set_User(User_Copy);
                navigate("/livechat");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        // setLoading(false)
    };

    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    return (
        <div className="sidenav1">
            {isMobile && (
                <div
                    className="Display_Flex Justify_Content_Space_Between Align_Items_Center"
                    style={{ height: "27px", margin: "5px 0" }}
                >
                    <img className="new-logo" src={LogoFairbets}></img>

                    <Cross
                        onClick={closeSideMenu}
                        className="crs-sidebarIcons"
                        width="1.5rem"
                        height="1.5rem"
                    />
                </div>
            )}
            {isMobile && (
                <a
                    href="/"
                    className="abtp"
                    onClick={() => closeSideMenu() && setActiveMenu("HOME")}
                    id={activeMenu === "HOME" ? "active-side-menu" : ""}
                >
                    <Homesvg className="toggImg" />
                    {Translation_Table.home[Language]}
                </a>
            )}
            {!isMobile && (
                <Link
                    to="/"
                    className="abtp"
                    onClick={() => setActiveMenu("HOME")}
                    id={activeMenu === "HOME" ? "active-side-menu" : ""}
                >
                    <Homesvg className="toggImg" />
                    <span className="pointer">{Translation_Table.home[Language]}</span>
                </Link>
            )}
            <div className="d-flex justify-content-center mt-2">
                {/* <Button className="lobby-btn">LOBBY</Button> */}
                <SideNavigationBlock text={"LOBBY"} />
            </div>

            <a
                // reloadDocument
                // to={`/casino?data=${Casino_Game_Categories_Enums.Ezugi}`}
                className="abtp"
                onClick={
                    activeCasinoProvider === "QTECH"
                        ? () => {
                              setActiveMenu("EZUGI");
                              if (isMobile) {
                                  closeSideMenu();
                              }
                              navigate("/qtech-casino-game", {
                                  state: { gameId: QTechUrlLobbyTypes.Ezugi },
                              });
                          }
                        : () => {
                              setActiveMenu("EZUGI");
                              if (isMobile) {
                                  closeSideMenu();
                              }
                              navigate("/casino-game", {
                                  state: {
                                      Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Ezugi}&type=${WACS_Payment_Modes.Charged}`,
                                  },
                              });
                          }
                }
                id={activeMenu === "EZUGI" ? "active-side-menu" : ""}
                state={{ Category: Casino_Game_Categories_Enums.Ezugi }}
            >
                <Ezuginew className="toggImg" />
                <span className="pointer">{Translation_Table.ezugi[Language]}</span>
            </a>

            <a
                // reloadDocument
                // to={`/qtech-casino-game`}
                className="abtp"
                onClick={
                    activeCasinoProvider === "QTECH"
                        ? () => {
                              setActiveMenu("EVOLUTION");
                              if (isMobile) {
                                  closeSideMenu();
                              }
                              navigate("/qtech-casino-game", {
                                  state: { gameId: QTechUrlLobbyTypes.Evolution },
                              });
                          }
                        : () => {
                              if (isMobile) {
                                  closeSideMenu();
                              }
                              navigate("/casino-game", {
                                  state: {
                                      Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Evolution}&type=${WACS_Payment_Modes.Charged}`,
                                  },
                              });
                          }
                }
                id={activeMenu === "EVOLUTION" ? "active-side-menu" : ""}
                state={{ gameId: QTechUrlLobbyTypes.Evolution }}
            >
                <Evolutionsvg className="toggImg" />
                <span className="pointer">{Translation_Table.evolution[Language]}</span>
            </a>

            <Link
                reloadDocument
                to={`/top-games`}
                className="abtp"
                onClick={() => {
                    setActiveMenu("TOP_GAMES");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "TOP_GAMES" ? "active-side-menu" : ""}
                state={{ Category: Casino_Game_Categories_Enums.Top_Games }}
            >
                <Topgamessvg className="toggImg" />
                <span className="pointer">{Translation_Table.topGames[Language]}</span>
            </Link>

            <div className="d-flex justify-content-center mt-2">
                <SideNavigationBlock text={"LIVE CASINO"} />
            </div>

            {activeCasinoProvider === Providers_Enums.CAPITAL_QTECH ? (
                <Link
                    // reloadDocument
                    to={
                        activeCasinoProvider === Providers_Enums.CAPITAL_QTECH
                            ? `/casino?data=${Casino_Game_Categories_Enums.Virtual}`
                            : "/selected-casino"
                    }
                    className="abtp"
                    onClick={() => {
                        setActiveMenu("VIRTUAL");
                        if (isMobile) {
                            closeSideMenu();
                        }
                    }}
                    id={activeMenu === "VIRTUALS" ? "active-side-menu" : ""}
                    state={
                        activeCasinoProvider === Providers_Enums.CAPITAL_QTECH
                            ? { Category: Casino_Game_Categories_Enums.Virtual }
                            : { name: "Virtuals" }
                    }
                >
                    <Virtualssvg className="toggImg" />
                    <span className="pointer">{Translation_Table.virtuals[Language]}</span>
                </Link>
            ) : null}
            <Link
                // reloadDocument
                // to={`/casino?data=${Casino_Game_Categories_Enums.Blackjack}`}
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("BLACKJACK");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "BLACKJACK" ? "active-side-menu" : ""}
                state={{ name: "BlackJack" }}
            >
                <Blackjacksvg className="toggImg" />
                <span className="pointer">{Translation_Table.blackJack[Language]}</span>
            </Link>
            <Link
                // reloadDocument
                // to={`/casino?data=${Casino_Game_Categories_Enums.Roulette}`}
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("ROULETTE");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "ROULETTE" ? "active-side-menu" : ""}
                state={{ name: "Roulette" }}
            >
                <Roulettesvg className="toggImg" />
                <span className="pointer">{Translation_Table.roulette[Language]}</span>
            </Link>

            <Link
                // reloadDocument
                // to={`/casino?data=${Casino_Game_Categories_Enums.Andar_Bahar}`}
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("ANDARBAHAR");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "ANDARBAHAR" ? "active-side-menu" : ""}
                state={{ name: "Andar Bahar" }}
            >
                <Andarbaharsvg className="toggImg" />
                <span className="pointer">{Translation_Table.andarBahar[Language]}</span>
            </Link>

            <Link
                // reloadDocument
                // to={`/casino?data=${Casino_Game_Categories_Enums.Dragon_Tiger}`}
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("DRAGON");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "DRAGON" ? "active-side-menu" : ""}
                state={{ name: "Dragon Tiger" }}
            >
                <Dragonsvg className="toggImg" />
                <span className="pointer">{Translation_Table.dragonTiger[Language]}</span>
            </Link>

            <Link
                // reloadDocument
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("BACCARAT");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "BACCARAT" ? "active-side-menu" : ""}
                state={{ name: "Baccarat" }}
            >
                <Baccaratsvg className="toggImg" />
                <span className="pointer">{Translation_Table.baccarat[Language]}</span>
            </Link>
            <Link
                // reloadDocument
                to={"/selected-casino"}
                className="abtp"
                onClick={() => {
                    setActiveMenu("POKER");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "POKER " ? "active-side-menu" : ""}
                state={{ name: "Poker" }}
            >
                <Pokersvg className="toggImg" />
                <span className="pointer">{Translation_Table.poker[Language]}</span>
            </Link>
            <div className="d-flex justify-content-center mt-2">
                <SideNavigationBlock text={"SUPPORT"} />
                {/* <Button className="lobby-btn">SUPPORT</Button> */}
            </div>
            <a
                onClick={() => setShowLanguagePopup(true)}
                className="abtp"
                id={activeMenu === "SELECT_LANGUAGE" ? "active-side-menu" : ""}
            >
                <Language_Icon className="toggImg" />
                <span className="pointer">{Translation_Table.selectLanguage[Language]}</span>
            </a>
            {/* <a href="mailto:support@fairbets.co">
                <Supportsvg className="toggImg" />
                <span>{Translation_Table.support[Language]}</span>
            </a> */}
            {/* <Link
                to="/feedback"
                className="abtp"
                onClick={() => {
                    setActiveMenu("FEEDBACK");
                    if (isMobile) {
                        closeSideMenu();
                    }
                }}
                id={activeMenu === "FEEDBACK" ? "active-side-menu" : ""}
            >
                <Feedbacksvg className="toggImg" />
                <span className="pointer">{Translation_Table.feedback[Language]}</span>
            </Link> */}

            {/* <a
                href={whatsapp}
                onClick={() => setActiveMenu("WHATSAPP")}
                target="_blank"
                id={activeMenu === "WHATSAPP " ? "active-side-menu" : ""}
            >
                <Whatsappsvg className="toggImg" />
                <span className="pointer">{Translation_Table.whatsapp[Language]}</span>
            </a>
            <a
                href={telegram}
                target="_blank"
                id={activeMenu === "TELEGRAM" ? "active-side-menu" : ""}
            >
                <Telegramsvg className="toggImg" />
                <span>{Translation_Table.telegram[Language]}</span>
            </a>
            <a
                href={twitter}
                onClick={() => setActiveMenu("TWITTER")}
                target="_blank"
                id={activeMenu === "TWITTER" ? "active-side-menu" : ""}
            >
                <Twittersvg className="toggImg" />
                <span className="pointer">{Translation_Table.twitter[Language]}</span>
            </a>
            <a
                href={instagram}
                onClick={() => setActiveMenu("INSTAGRAM")}
                target="_blank"
                id={activeMenu === "INSTAGRAM" ? "active-side-menu" : ""}
            >
                <Instagramsvg className="toggImg" />
                <span className="pointer">{Translation_Table.instagram[Language]}</span>
            </a> */}
        </div>
    );
}

export default SideNavigation;
