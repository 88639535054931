import React, { useState, useEffect } from 'react';
import { Pagination, Stack } from "@mui/material";
import TableFilterDatePicker from '../../TableFilters/TableFilterDatePicker';
import TableFiltersSearchBox from '../../TableFilters/TableFiltersSearchBox';
import TableFiltersSelectBox from '../../TableFilters/TableFiltersSelectBox';
import "../../TableFilters/TableFilters.css"



function MasterAccountTable({ data, title, currentPage, setCurrentPage, totalPages, itemsPerPage }) {
    const [tableData, setTableData] = useState(data);
    const [searchAmount, setSearchAmount] = useState('');
    const [searchLastBalance, setSearchLastBalance] = useState('');

    const [openingBalance, setOpeningBalance] = useState('');

    const typeArray = [{ id: 'CR', value: 'Credited' }, { id: 'DR', value: 'Debited' }]
    const [selectedType, setSelectedType] = useState('');

    const paymentMedium = [{ id: "AB-UPI", value: "AB-UPI" }, { id: 'A2Z', value: 'A2Z' }]
    const [selectedMedium, setSelectedMedium] = useState('');

    const [searchAdminAllot, setSearchAdminAllot] = useState('');

    const actionTypeArray = [{ id: 'Deposit', value: 'Deposit' }, { id: 'withdrawal request', value: 'Withdrawal' }, { id: 'Settled', value: 'Settled' }, { id: 'Resettled', value: 'Resettled' }, { id: 'Place Bet', value: 'Place Bet' }, , { id: 'cancelled', value: 'cancelled' }]
    const [actionType, setActionType] = useState('')

    const formatDate = (date) => {
        const day = new Date(date).getDate().toString().padStart(2, "0");
        const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
        const year = new Date(date).getFullYear().toString();

        return `${day}-${month}-${year}`;
    };

    const formatedTime = (timestamp) => {
        const date = new Date(timestamp);
        const istDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
        const hours = istDate.getHours();
        const minutes = istDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    const onSearchAmount = (values) => {
        setSearchAmount(values);

        const filteredData = data.filter(
            (item) => item.amount.toString().includes(values)
        );
        setTableData(filteredData);
    }

    const onSearchLastBalance = (values) => {
        setSearchLastBalance(values);

        const filteredData = data.filter(
            (item) => item.balance.toString().includes(values)
        );
        setTableData(filteredData);
    }
    const onSearchOpeningBalance = (values) => {
        setOpeningBalance(values);

        const filteredData = data.filter(
            (item) => item.lastBalance.toString().includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeType = (values) => {
        setSelectedType(values);
        const filteredData = data.filter(
            (item) => item.transactionType.includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeMedium = (values) => {
        setSelectedMedium(values);
        const filteredData = data.filter(
            (item) => item.providerName.includes(values)
        );
        setTableData(filteredData);
    }

    const onChangeAction = (values) => {
        setActionType(values);
        const filteredData = data.filter(
            (item) => item.action.includes(values)
        );
        setTableData(filteredData);
    }

    const onDateChange = (values) => {
        if (values) {
            const filteredData = data.filter(
                (item) => formatDate(item.createdOn) === formatDate(values)
            );
            if (filteredData.length > 0) {
                setTableData(filteredData);
            }

        }

    }

    const onUpdatedDateChange = (values) => {
        if (values) {
            const filteredData = data.filter(
                (item) => formatDate(item.updatedOn) === formatDate(values)
            );
            if (filteredData.length > 0) {
                setTableData(filteredData);
            } else {
                setTableData(data)
            }

        } else {
            setTableData(data)
        }

    }

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const getBackgroundStyle = (action) => {
        switch (action) {
            case "Win":
                return "rgb(255 0 149 / 22%)";
            case "Deposit":
                return "rgba(255, 255, 0, 0.36)";
            case "Settled":
                return "rgb(255 0 149 / 22%)";
            case "withdrawal request":
                return "rgb(0 225 21 / 31%)";
            default:
                return "";
        }
    };
    const getFontSizeStyle = (action) => {
        switch (action) {
            case "Win":
                return "15px";
            case "Deposit":
                return "15px";
            case "Settled":
                return "15px";
            case "withdrawal request":
                return "15px";
            default:
                return "14px";
        }
    };
    const getFontWeightStyle = (action) => {
        switch (action) {
            case "Win":
                return "700";
            case "Deposit":
                return "700";
            case "Settled":
                return "700";
            case "withdrawal request":
                return "700";
            default:
                return "500";
        }
    };

    return (
        <div className='row mt-4'>
            <div className="table-responsive">
                <table className="table table-view">
                    <thead>
                        <tr>
                            <th>#</th>
                            {/* <th>Provider</th> */}
                            <th>Action</th>
                            <th>Detail</th>
                            {/* <th>Type</th> */}
                            <th>Opening Balance</th>
                            <th>Amount</th>
                            <th>Closing Balance</th>
                            <th>Created On</th>
                            {/* <th>Updated On</th> */}
                        </tr>
                    </thead>
                 
                    <tbody>
                        {tableData.map((item, index) =>
                            <tr style={{
                                background: getBackgroundStyle(item.action)
                            }} key={item._id}>
                                <td>{index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
                                {/* <td>{item.providerName}</td> */}
                                <td>{item.action}</td>
                                <td>
                                    {item.description?.eventName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>EventName: </span>
                                            <span className="wallet-color">{`${item.description.eventName} `}</span>
                                            <br />
                                        </>

                                    )}
                                    {item.description?.marketName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>MarketName: </span>
                                            <span className="wallet-color"> <b>{`${item.description.marketName} `}</b></span>
                                            <br />
                                        </>

                                    )}

                                    {item.description?.transactionId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>TrasactionId: </span>
                                            <span className="wallet-color">{`${item.description.transactionId} `}</span>
                                        </>
                                    )}

                                    {item.description?.marketId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>MarketId: </span>
                                            <span className="wallet-color">{`${item.description.marketId} `}</span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.rate && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Rate: </span>
                                            <span className="wallet-color">{`${item.description.rate} `}</span>
                                        </>
                                    )}

                                    {item.description?.betType && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>BetType: </span>
                                            <span className="wallet-color">{`${item.description.betType} `}</span>
                                        </>
                                    )}

                                    {item.description?.game && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>BetOn: </span>
                                            <span className="wallet-color"><b style={{ fontSize: "15px" }}>{`${item.description.game} `}</b></span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.paymentGatewayName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>GatewayName: </span>
                                            <span className="wallet-color">{`${item.description.paymentGatewayName} `}</span>
                                        </>
                                    )}

                                    {item.description?.paymentType && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>PaymentType: </span>
                                            <span className="wallet-color">{`${item.description.paymentType} `}</span>
                                            <br />
                                        </>
                                    )}

                                    {item.description?.reason && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Reason: </span>
                                            <span className="wallet-color">{`${item.description.reason} `}</span>
                                        </>
                                    )}

                                    {item.description?.remark && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Remark: </span>
                                            <span className="wallet-color">{`${item.description.remark} `}</span>
                                        </>
                                    )}
                                    {item.description?.stake && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Stake: </span>
                                            <span className="wallet-color">{`${item.description.stake} `}</span>
                                        </>
                                    )}
                                    {item.description?.runnerName && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>RunnerName: </span>
                                            <span className="wallet-color">{`${item.description.runnerName} `}</span>
                                        </>
                                    )}
                                    {item.description?.run && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Run: </span>
                                            <span className="wallet-color">{`${item.description.run} `}</span>
                                        </>
                                    )}
                                    {item.description?.updatedBy?.name && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>UpdatedBy: </span>
                                            <span className="wallet-color">{`${item.description.updatedBy.name} `}</span>
                                        </>
                                    )}
                                    {item.description?.gameReference && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>GameName: </span>
                                            <span className="wallet-color">{`${item.description?.gameReference} `}</span>
                                        </>
                                    )}
                                    {item.description?.settlementId && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>SettlementId: </span>
                                            <span className="wallet-color">{`${item.description?.settlementId} `}</span>
                                        </>
                                    )}
                                    {item.description?.category && (
                                        <>
                                            <span className='wallet-span' style={{ fontWeight: 'bold' }}>Category: </span>
                                            <span className="wallet-color">{`${item.description?.category} `}</span>
                                        </>
                                    )}
                                </td>
                                {/* <td>{item.providerName === "Qtech" ? item?.description?.commission : item?.description?.commissionAmount}</td> */}
                                {/* <td>{`${item.transactionType === "CR" ? "Credited" : "Debited"}`}</td> */}
                                <td>{Math.round(item.lastBalance)}</td>
                                <td style={{
                                    fontSize: getFontSizeStyle(item.action),
                                    fontWeight: getFontWeightStyle(item.action),
                                    // color: item.amount < 0 ? 'red' : 'green'
                                }}>{item.amount}</td>
                                <td>{Math.round(item.balance)}</td>
                                <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                            </tr>)}
                    </tbody>
                </table>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={totalPages}
                            color="secondary"
                            page={currentPage}
                            onChange={(Event, New_Page) =>
                                setCurrentPage(New_Page)
                            }
                        />
                    </Stack>
                </div>
            </div>
        </div>
    )
}

export default MasterAccountTable