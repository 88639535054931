import React, { useContext } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from '../../Contexts/Language';

// Styles
import "../../css/withdrawmodal.css";

function WithdrawalWarning() {
    const { Language, Set_Language } = useContext(Language_Context)
    return (
        <div>
            <div className="note mt-4">
                <strong>Note: </strong>{" "}
                {
                    Translation_Table.Tochangethewithdrawalaccountdetailspleasecontactsupport[Language]
                }{" "}
            </div>
            <div>
                <p className="point-withdraw">
                    <ul style={{ listStyleType: "disc" }}>
                        <li>
                            {

                                Translation_Table.Ifwithdrawalaccountsof2ormoreIDaresamebothIDswillbeblocked[Language]

                            }
                        </li>
                        <li>
                            {
                                Translation_Table.IftwoIDshavesameIPbothIDswillbeblocked[Language]
                            }
                        </li>
                        <li>
                            {
                                Translation_Table.Bonusshouldbewagered55xbeforeyoucanwithdrawit[Language]
                            }
                        </li>
                        <li>
                            {
                                Translation_Table.Ifbettingpatternof2IDsissamebothwillbeblocked[Language]
                            }
                        </li>
                        <li>
                            {
                                Translation_Table.DonotputwithdrawalrequestwithoutdepositingsuchrequestWillbecategorisedassuspiciousactivities[Language]
                            }
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    )
}

export default WithdrawalWarning;