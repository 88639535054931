import React, { useEffect, useRef } from 'react';
import { Casino_Game_Categories_Enums } from "../../Configuration/Enums/Casino_Game_Categories";

// Styles
import '../../Pages/Casino/Casino.css';

const CasinoIcon = ({ onClick, title, image, imageNew, Selection_Status, setIconWidth }) => {
    const iconRef = useRef(null);

    useEffect(() => {
        setIconWidth(iconRef.current.offsetWidth)
    }, []);

    return (
        <div className="col-5 casnTb" onClick={onClick} ref={iconRef}>
            {Selection_Status ? <img src={imageNew} /> : <img src={image} />}
            <span>{title}</span>
        </div>
    )

}


export default CasinoIcon;