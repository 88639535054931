import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QTechUrlLobbyTypes } from '../../Configuration/Enums/QTechLobbyUrlValues';
import { Game_Context } from '../../Contexts/Games';
import { User_Context } from '../../Contexts/User';
import { WacsUrlLobbyTypes } from '../../Configuration/Enums/QTechLobbyUrlValues';
import { WACS_Endpoint } from '../../Configuration/Settings';
import { WACS_Payment_Modes } from '../../Configuration/Enums/WACS_Payment_Modes';

// Assets
import BeterImage from '../../Assets/TopProvidersIcons/BETER.png'
import SevenMojosImage from '../../Assets/TopProvidersIcons/7-mojos.png'

import BetGamesImage from '../../Assets/TopProvidersIcons/BETGAMES.png'
import EvolutionImage from '../../Assets/TopProvidersIcons/EVOLUTION.png'

import EzugiImage from '../../Assets/TopProvidersIcons/ezugi.png'
import SAImage from '../../Assets/TopProvidersIcons/SA-Gaming.png'

import SpribeImage from '../../Assets/TopProvidersIcons/SPRIBE.png'
import VivoImage from '../../Assets/TopProvidersIcons/VIVO.png'
import { SessionHelper } from '../../Utilities/SessionHelper';

function ProvidersGameCard({ data, isCarousel }) {

    const navigate = useNavigate();

    const { User } = useContext(User_Context)
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    const { type } = data;
    const location = useLocation();

    const url = location.pathname === '/casino-category-mobile' ? '/qtech-casino-game-mobile' : '/qtech-casino-game';
    // /casino-game-mobile
    const wacUrl = location.pathname === '/casino-category-mobile' ? '/casino-game' : '/casino-game-mobile';

    const onClickImage = async (itemType) => {
        if (itemType === "evolution") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.Evolution } })
        } else if (itemType === "ezugi") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.Ezugi } })
        } else if (itemType === "sa") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.SaGaming } })
        } else if (itemType === "spribe") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.Spribe } })
        } else if (itemType === "beter") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.Beter } })
        } else if (itemType === "mojos") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.SevenMojos } })
        } else if (itemType === "vivo") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.Vivo } })
        } else if (itemType === "betGames") {
            navigate(url, { state: { gameId: QTechUrlLobbyTypes.BetGames } })
        }
    }

    const onClickWacs = async (itemType) => {
        if (itemType === "evolution") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Evolution}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (itemType === "ezugi") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Ezugi}&type=${WACS_Payment_Modes.Charged}` } })
        }
        else if (itemType === "spribe") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Spribe}&type=${WACS_Payment_Modes.Charged}` } })
        }
        else if (itemType === "mojos") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.SevenMojos}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (itemType === "vivo") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Vivo}&type=${WACS_Payment_Modes.Charged}` } })
        } else if (itemType === "betGames") {
            navigate(wacUrl, { state: { Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.BetGames}&type=${WACS_Payment_Modes.Charged}` } })
        }
    }
    return (
        activeCasinoProvider === "QTECH" ?
            <div className={'col-6 col-md-2 crv-csn'}>
                <div className='member-img' onClick={() => onClickImage(type)}>
                    <img
                        alt={type}
                        className={isCarousel ? 'carousel-image-qtech' : 'img-fluid'}
                        src={type === "evolution" ? EvolutionImage : type === "ezugi" ? EzugiImage : type === "sa" ? SAImage : type === "spribe" ? SpribeImage : type === "beter" ? BeterImage : type === "mojos" ? SevenMojosImage : type === "vivo" ? VivoImage : BetGamesImage}
                        loading='lazy'
                    ></img>
                </div>
            </div> : <div className={'col-6 col-md-2 crv-csn'}>
                <div className='member-img' onClick={() => onClickWacs(type)}>
                    <img
                        alt={type}
                        className={'carousel-image-qtech'}
                        src={type === "evolution" ? EvolutionImage : type === "ezugi" ? EzugiImage  : type === "spribe" ? SpribeImage : type === "betGames" ? BetGamesImage : type === "mojos" ? SevenMojosImage : type === "vivo" ? VivoImage : BetGamesImage}
                        loading='lazy'
                    ></img>
                </div>
            </div>
    )
}

export default ProvidersGameCard;
