import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { User_Context } from '../Contexts/User';

const Private_Route = ({ children, Redirection_Path }) => 
{
	const User_State = useContext (User_Context);
	const User = User_State.User;
	if (!User.token || User.token === '')
	{
		return <Navigate to={Redirection_Path} replace state={{ Authentication_Status: true }} />;
	}
	return children ? children : <Outlet />;
};

export default Private_Route;