import React, { useState, useEffect, useContext } from 'react';
import { Pagination, Stack } from "@mui/material";
import TableFilterDatePicker from '../../TableFilters/TableFilterDatePicker';
import TableFiltersSearchBox from '../../TableFilters/TableFiltersSearchBox';
import TableFiltersSelectBox from '../../TableFilters/TableFiltersSelectBox';
import "../../TableFilters/TableFilters.css"
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { User_Context } from '../../../Contexts/User';
import { useParams, useNavigate } from 'react-router-dom';
import { API_Endpoint } from '../../../Configuration/Settings';
import { encryptData } from '../../../Utilities/EncryptData';
import { decryptData } from '../../../Utilities/DecryptData';
import axios from 'axios';



function TransferStatementTable() {
    const [loading, setLoading] = useState(false)
    const [marketId, setMarketId] = useState("");
    const [typeError, setTypeError] = useState(false);
    const [pageNo, setPageNo] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [tableData, setTableData] = useState([]);
    const { User } = useContext(User_Context)
    const [totalPages, setTotalPages] = useState(1)
    const [searchAmount, setSearchAmount] = useState('');
    const [searchLastBalance, setSearchLastBalance] = useState('');

    const [openingBalance, setOpeningBalance] = useState('');

    const typeArray = [{ id: 'CR', value: 'Credited' }, { id: 'DR', value: 'Debited' }]
    const [selectedType, setSelectedType] = useState('');

    const paymentMedium = [{ id: "AB-UPI", value: "AB-UPI" }, { id: 'A2Z', value: 'A2Z' }]
    const [selectedMedium, setSelectedMedium] = useState('');

    const [searchAdminAllot, setSearchAdminAllot] = useState('');

    const actionTypeArray = [{ id: 'Deposit', value: 'Deposit' }, { id: 'withdrawal request', value: 'Withdrawal' }, { id: 'Settled', value: 'Settled' }, { id: 'Resettled', value: 'Resettled' }, { id: 'Place Bet', value: 'Place Bet' }, , { id: 'cancelled', value: 'cancelled' }]
    const [actionType, setActionType] = useState('')

    const formatDate = (date) => {
        const day = new Date(date).getDate().toString().padStart(2, "0");
        const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
        const year = new Date(date).getFullYear().toString();

        return `${day}-${month}-${year}`;
    };

    const formatedTime = (timestamp) => {
        const date = new Date(timestamp);
        const istDate = new Date(date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
        const hours = istDate.getHours();
        const minutes = istDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    // const onSearchAmount = (values) => {
    //     setSearchAmount(values);

    //     const filteredData = data.filter(
    //         (item) => item.amount.toString().includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    // const onSearchLastBalance = (values) => {
    //     setSearchLastBalance(values);

    //     const filteredData = data.filter(
    //         (item) => item.balance.toString().includes(values)
    //     );
    //     setTableData(filteredData);
    // }
    // const onSearchOpeningBalance = (values) => {
    //     setOpeningBalance(values);

    //     const filteredData = data.filter(
    //         (item) => item.lastBalance.toString().includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    // const onChangeType = (values) => {
    //     setSelectedType(values);
    //     const filteredData = data.filter(
    //         (item) => item.transactionType.includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    // const onChangeMedium = (values) => {
    //     setSelectedMedium(values);
    //     const filteredData = data.filter(
    //         (item) => item.providerName.includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    // const onChangeAction = (values) => {
    //     setActionType(values);
    //     const filteredData = data.filter(
    //         (item) => item.action.includes(values)
    //     );
    //     setTableData(filteredData);
    // }

    // const onDateChange = (values) => {
    //     if (values) {
    //         const filteredData = data.filter(
    //             (item) => formatDate(item.createdOn) === formatDate(values)
    //         );
    //         if (filteredData.length > 0) {
    //             setTableData(filteredData);
    //         }

    //     }

    // }

    // const onUpdatedDateChange = (values) => {
    //     if (values) {
    //         const filteredData = data.filter(
    //             (item) => formatDate(item.updatedOn) === formatDate(values)
    //         );
    //         if (filteredData.length > 0) {
    //             setTableData(filteredData);
    //         } else {
    //             setTableData(data)
    //         }

    //     } else {
    //         setTableData(data)
    //     }

    // }
    const getWalletHistory = async (page) => {
        setLoading(true);
        let payload = {
            itemsPerPage: Number(itemsPerPage),
            pageNo: page,
            filter: {
                userId: User.data._id,
            }
        }

        payload.filter['action'] = 'transfer';
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_Endpoint}/wallet-History`,
            headers: { Authorization: `Bearer ${User.token}` },
            data: { token: encryptData(payload) },
        };

        axios.request(config).then((response) => {
            response.data.data = decryptData(response.data.data);
            const dataArray = response.data.data.payload
            setTableData(dataArray.walletHistory)
            setTotalPages(dataArray.totalPages);
            setLoading(false);
        }).catch((error) => { console.log(error); });
    }
    const handleSubmitClick = () => getWalletHistory(pageNo);

    useEffect(() => {
        getWalletHistory(pageNo);
    }, [pageNo, itemsPerPage]);

    const getBackgroundStyle = (action) => {
        switch (action) {
            case "Win":
                return "rgb(255 0 149 / 22%)";
            case "Deposit":
                return "rgba(255, 255, 0, 0.36)";
            case "Settled":
                return "rgb(255 0 149 / 22%)";
            case "withdrawal request":
                return "rgb(0 225 21 / 31%)";
            default:
                return "";
        }
    };
    const getFontSizeStyle = (action) => {
        switch (action) {
            case "Win":
                return "15px";
            case "Deposit":
                return "15px";
            case "Settled":
                return "15px";
            case "withdrawal request":
                return "15px";
            default:
                return "14px";
        }
    };
    const getFontWeightStyle = (action) => {
        switch (action) {
            case "Win":
                return "700";
            case "Deposit":
                return "700";
            case "Settled":
                return "700";
            case "withdrawal request":
                return "700";
            default:
                return "500";
        }
    };


    const handleDropdownChange = (event) => {
        setItemsPerPage(event.target.value);
        setTypeError(false);
    };

    return (
        // <   div className='row mt-4'>
        //     <div>
        //         <div className='col-12 col-xl-2 col-sm-4 master-table'>
        //             <label className='label-reason'>Items Per Page</label>
        //             <FormControl
        //                 className="form-banner mt-2"
        //                 sx={{ m: 1, width: 200 }}
        //                 size="small"
        //             >
        //                 <InputLabel
        //                     className="label-banner"
        //                     id="demo-select-small-label"
        //                 >
        //                     Select
        //                 </InputLabel>
        //                 <Select
        //                     labelId="demo-select-small-label"
        //                     id="demo-select-small"
        //                     value={itemsPerPage}
        //                     label="Select"
        //                     fullWidth
        //                     onChange={handleDropdownChange}
        //                     error={typeError}
        //                 >
        //                     <MenuItem value="10">10</MenuItem>
        //                     <MenuItem value="25">25</MenuItem>
        //                     <MenuItem value="50">50</MenuItem>
        //                     <MenuItem value="75">75</MenuItem>
        //                     <MenuItem value="100">100</MenuItem>
        //                 </Select>
        //             </FormControl>
        //         </div>
        //     </div>
        //     <div className="table-responsive">
        //         <table className="table table-view">
        //             <thead>
        //                 <tr>
        //                     <th>#</th>
        //                     <th>Amount</th>
        //                     <th>Description</th>
        //                     <th>Date</th>
        //                 </tr>
        //             </thead>

        //             <tbody>
        //                 {tableData.map((item, index) =>
        //                     <tr style={{
        //                         background: getBackgroundStyle(item.action)
        //                     }} key={item._id}>
        //                         <td>{index + 1 + (pageNo && itemsPerPage ? (pageNo - 1) * itemsPerPage : 0)}</td>
        //                         {/* <td>{item.providerName}</td> */}
        //                         <td>{item.amount}</td>
        //                         <td>{item.lastBalance > item.balance ? "withdraw" : "Deposit"}</td>
        //                         <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
        //                     </tr>)}
        //             </tbody>
        //         </table>
        //     </div>
        //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
        //             <Stack spacing={2}>
        //                 <Pagination
        //                     count={totalPages}
        //                     color="secondary"
        //                     page={pageNo}
        //                     onChange={(Event, New_Page) =>
        //                         (value) => setPageNo(New_Page)
        //                     }
        //                 />
        //             </Stack>
        //     </div>
        // </div>
        <>
        <div className='container-fluid'>
            <div>
            <div className="row mt-2">
                        <div className='col-12 col-xl-2 col-sm-4 master-table'>
                            <label className='label-reason'>Items Per Page</label>
                            <FormControl
                                className="form-banner mt-2"
                                sx={{ m: 1, width: 200 }}
                                size="small"
                            >
                                <InputLabel
                                    className="label-banner"
                                    id="demo-select-small-label"
                                >
                                    Select
                                </InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={itemsPerPage}
                                    label="Select"
                                    fullWidth
                                    onChange={handleDropdownChange}
                                    error={typeError}
                                >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="25">25</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="75">75</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
            </div>
            <div className='row mt-4'>
            <div className="table-responsive">
                <table className="table table-view">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {tableData.map((item, index) =>
                            <tr style={{
                                background: getBackgroundStyle(item.action)
                            }} key={item._id}>
                                <td>{index + 1 + (pageNo && itemsPerPage ? (pageNo - 1) * itemsPerPage : 0)}</td>
                                {/* <td>{item.providerName}</td> */}
                                <td>{item.amount}</td>
                                <td>{item.lastBalance > item.balance ? "withdraw" : "Deposit"}</td>
                                <td>{`${formatDate(item.createdOn)} ${formatedTime(item.createdOn)}`}</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 20 }}>
                    <Stack spacing={2}>
                        <Pagination
                            count={totalPages}
                            color="secondary"
                            page={pageNo}
                            onChange={(Event, New_Page) => setPageNo(New_Page)}
                        />
                    </Stack>
            </div>
            </div>
            </div>

        </div>
        </>
    )
}

export default TransferStatementTable