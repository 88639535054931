import React, { useEffect, useState, useContext, useCallback } from "react";
import "../Bet-History/bet-history.css";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Pagination, Stack, Tab, Tabs } from "@mui/material";
import { User_Context } from "../../../Contexts/User";
import config from "../../../Utilities/Data_Formatting";
import Logout_User from "../../../Utilities/Logout";
import { User_APIs } from "../../../API/User_APIs";
import { Language_Context } from "../../../Contexts/Language";
import { Translation_Table } from "../../../Configuration/Translation";
import { Bet_Types } from "../../../Configuration/Enums/Bet_Types";
import { decryptData } from "../../../Utilities/DecryptData";

// Components
import BetHistoryNoData from "../../BetHistory/BetHistoryNoData";
import BetHistoryCards from "../../BetHistory/BetHistoryCards";
import BetHistoryFilterModal from "../../Modals/BetHistoryFilterModal";
import JetfairCard from "../../BetHistory/JetfairCard";
import Satta_Matka_Card from "../../BetHistory/Satta_Matka_Card";
import FalconCard from "../../BetHistory/FalconCard";
import QTechGameCard from "../../BetHistory/QTechGameCard";

function BetHistory({ showBetFilter, setShowBetFilter }) {
	const [Bet_History, Set_Bet_History] = useState({});
	const { User, Set_User } = useContext(User_Context);
	const { Language } = useContext(Language_Context)
	const navigate = useNavigate();
	const navigateToProfile = () => {
		navigate("/profile");
	};
	const [Selected_Tab, Set_Selected_Tab] = useState(Bet_Types.Pending);
	const [filterPopup, setFilterPopup] = useState(false);
	const [Current_Pending_Bets_Page, Set_Current_Pending_Bets_Page] = useState(0);
	const [Current_Completed_Bets_Page, Set_Current_Completed_Bets_Page] = useState(0);

	// const [totalPending, setTotalPending] = useState(1);
	// const [totalCompleted, setTotalCompleted] = useState(1);

	const [totalCount, setTotalCount] = useState(0)

	const Items_per_Page = 1000;

	useEffect(() => {
		setFilterPopup(showBetFilter)
	}, [showBetFilter]);

	const TabPanel = ({ children, value, index }) =>
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>

	const showFilterPopup = () => {
		setFilterPopup(true);
	};

	const closeFilterPopup = () => {
		Get_Bet_History(1);
		setFilterPopup(false);
		setShowBetFilter(false)
	};

	const handleTabChange = (event, newValue) => {
		Set_Selected_Tab(newValue);
	};

	const Change_Page = (Bet_Type, New_Page) => {
		if (Bet_Type === Bet_Types.Completed) {
			Set_Current_Completed_Bets_Page(New_Page);
		}
		if (Bet_Type === Bet_Types.Pending) {
			Set_Current_Pending_Bets_Page(New_Page);
		}
		Get_Bet_History(New_Page);
	}

	const Get_Bet_History = (Page) => {
		User_APIs.Get_Bet_History({ _id: User.data._id, pageNo: Page, itemPerPage: Items_per_Page }, User.token).then((API_Response) => {
			if (API_Response && API_Response.data && API_Response.data.success) {
				API_Response.data.data = decryptData(API_Response.data.data);
				const totalLength = API_Response.data.data.payload.totalCount;

				setTotalCount(totalLength)

				Set_Bet_History(API_Response.data.data.payload.finalArray);

			}
		}).catch((error) => {
			if (error && error.response && error.response.status === 401) {
				Logout_User(Set_User)
				navigate("/")
			}
		});
	}

	const filterBetHistory = useCallback(async (filterObj) => {
		setFilterPopup(false);
		setShowBetFilter(false)
		let payload = {
			_id: User.data._id,
			pageNo: 1,
			itemPerPage: Items_per_Page,
			Filters: {
				Start_Date: filterObj.startDate,
				End_Date: filterObj.endDate,
				Game: filterObj.selectedGame,
			}
		}

		if (filterObj.betStatus) {
			payload.Filters.Bet_Status = filterObj.betStatus
		}

		// below if condition added to make sure game will not be undefined 
		if (!payload.Filters.Game) {
			delete payload.Filters.Game
		}
		// above if condition added to make sure game will not be undefined 

		try {
			const response = await User_APIs.Get_Bet_History(payload, User.token)
			const { data } = response ? response : {};
			const decryptedData = decryptData(data.data);

			const totalLength = decryptedData.payload.totalCount;

			setTotalCount(totalLength)
			Set_Bet_History(decryptedData.payload.finalArray);
		} catch (error) {
			if (error && error.response && error.response.status === 401) {
				Logout_User(Set_User)
				navigate("/")
			}
		}
	}, [])

	useEffect(() => {
		Get_Bet_History(1);
	}, [])

	return (
		<div>
			{/* <Header
				isBetHistory={true}
				onBetHistoryClick={showFilterPopup}
				showBetHistoryFilter={Selected_Tab === Bet_Types.Completed ? true : false}
			/> */}
			<Box sx={{ width: "100%" }}>
				<Tabs
					className="bg-tab-bet"
					value={Selected_Tab}
					onChange={handleTabChange}
					centered
				>
					<Tab className="tab-bet" label={Translation_Table.pending[Language]} />
					<Tab className="tab-bet" label={Translation_Table.completed[Language]} />
				</Tabs>
				<TabPanel value={Selected_Tab} index={Bet_Types.Pending}>
					<div className="profile-detail dw" id="profilinfo">
						<div className="profilepg">
							{Bet_History.length > 0 &&
								<Grid container spacing={2}>
									{Bet_History.length > 0 &&
										Bet_History.map((item, index) => {
											if (
												(item.gameName && item.gameName === "sattaMatka" && item.status === "P") ||
												(item.gameName && item.gameName === "jetfair" && item.transactionCode === "PlaceMatchedBet") ||
												(item.gameName && item.gameName === "falcon" && item.betStatus === "P")
											) {
												return (
													<Grid key={index} item xs={12} sm={4}>
														{item.gameName && item.gameName === "sattaMatka" && item.status === "P" && (
															<Satta_Matka_Card
																title={'Pending'}
																type={"pending"}
																bazar_name={item.bazar_name}
																bet_id={item._id}
																game_name={item?.market_code === "701" ? "INSTANT WORLI" : (item.game_name ? item.game_name : "")}
																market_type={item.game_type ? item.game_type : ""}
																result_date={item.result_date}
																aankda={item.game}
																amount={item.point}
																Bet_Date={config.dateTime(item.updatedOn)}
																// time={config.extractTimeFromISOStringIST(item.updatedOn)}
																time={config.dateTimeCompleteIST(item.createdOn)}
																point={item.point}
																winningAmount={item.winning_point}
															/>
														)}
														{item.gameName && item.gameName === "jetfair" && item.transactionCode === "PlaceMatchedBet" && (
															<JetfairCard
																title={'Pending'}
																type={"pending"}
																amount={item.amount}
																// date={config.dateTimeCompleteIST(item.updatedOn)}
																createdOn={config.dateTimeCompleteIST(item.createdOn)}
																// updatedOn={config.dateTimeCompleteIST(item.updatedOn)}
																marketName={item.marketName}
																runnerName={item.runnerName}
																rate={item.rate}
																transactionID={item.transactionId}
																marketID={item.marketId}
																betType={item.betType}
																reviewID={item._id}
															/>
														)}
														{item.gameName && item.gameName === "falcon" && item.betStatus === "P" && (
															<FalconCard
																title={'Pending'}
																type={"pending"}
																amount={item.Amount}
																date={config.dateTimeComplete(config.Convert_Timestamp_to_the_Indian_Standard_Tome(item.updatedOn))}
																marketName={item.Marketname}
																runnerName={item.Runnername}
																rate={item.Rate}
																transactionID={item.TransactionID}
																marketID={item.MarketID}
																betType={item.BetType === 1 ? "Back" : "Lay"}
																reviewID={item._id}
															/>
														)}
													</Grid>
												);
											} else {
												return null;
											}
										})}
								</Grid>
							}
							{Bet_History.length === 0 &&
								<BetHistoryNoData />
							}

						</div>
					</div>
					<Stack spacing={2}>
						<Pagination
							className="d-flex justify-content-center mt-2"
							count={Math.ceil(totalCount / Items_per_Page)}
							color="secondary"
							page={Current_Pending_Bets_Page}
							onChange={(Event, New_Page) => Change_Page(Bet_Types.Pending, New_Page)}
						/>
					</Stack>
				</TabPanel>
				<TabPanel value={Selected_Tab} index={Bet_Types.Completed}>
					<div className="profile-detail dw" id="profilinfo">
						<div className="profilepg">
							{Bet_History.length > 0 &&
								<Grid container spacing={2}>
									{Bet_History.length > 0 &&
										Bet_History.map((item, index) => {
											if (
												(item.gameName && item.gameName === "sattaMatka" && item.status !== "P") ||
												(item.gameName && item.gameName === "jetfair" && item.transactionCode === "SettledMarket") ||
												(item.gameName && item.gameName === "falcon" && (item.betStatus === "W" || item.betStatus === "L")) ||
												(item.gameName && item.gameName === "Qtech") ||
												(!item.gameName)
											) {
												return (
													<Grid key={index} item xs={12} sm={4}>
														{item.gameName && item.gameName === "sattaMatka" && item.status !== "P" && (
															<Satta_Matka_Card
																title={item.status === "W" ? "Win" : "Loss"}
																type={item.status === "W" ? "win" : "loss"}
																bazar_name={item.bazar_name}
																bet_id={item._id}
																game_name={item?.market_code === "701" ? "INSTANT WORLI" : (item.game_name ? item.game_name : "")}
																market_type={item.game_type ? item.game_type : ""}
																result_date={item.result_date}
																aankda={item.game}
																amount={item.point}
																Bet_Date={config.dateTime(item.createdOn)}
																time={config.dateTimeCompleteIST(item.createdOn)}
																actualTime={item.updatedOn}
																point={item.point}
																winningAmount={item.winning_point}
																resultTime={config.dateTimeCompleteIST(item.updatedOn)}
															/>
														)}
														{item.gameName && item.gameName === "jetfair" && item.transactionCode === "SettledMarket" && (
															<JetfairCard
																title={'Settled'}
																type={"settled"}
																amount={item.amount}
																// date={config.dateTimeCompleteIST(item.updatedOn)}
																createdOn={config.dateTimeCompleteIST(item.createdOn)}
																updatedOn={config.dateTimeCompleteIST(item.updatedOn)}
																marketName={item.marketName}
																runnerName={item.runnerName}
																rate={item.rate}
																transactionID={item.transactionId}
																marketID={item.marketId}
																betType={item.betType}
																reviewID={item._id}
															/>
														)}
														{item.gameName && item.gameName === "falcon" && (item.betStatus === "W" || item.betStatus === "L") && (
															<FalconCard
																title={'Settled'}
																type={item.betStatus === "W" ? "settled" : "loss"}
																amount={item.Amount}
																date={config.dateTimeComplete(config.Convert_Timestamp_to_the_Indian_Standard_Tome(item.updatedOn))}
																marketName={item.Marketname}
																runnerName={item.Runnername}
																rate={item.Rate}
																transactionID={item.TransactionID}
																marketID={item.MarketID}
																betType={item.BetType === 1 ? "Back" : "Lay"}
																reviewID={item._id}
															/>
														)}

														{item.gameName && item.gameName === "Qtech" && (
															<QTechGameCard
																title={item.status === "W" ? "Win" : "Loss"}
																type={item.status === "W" ? "win" : "loss"}
																amount={item.amount}
																date={config.dateTimeComplete(config.Convert_Timestamp_to_the_Indian_Standard_Tome(item.createdOn))}
																// resultDate={config.dateTimeCompleteIST(item.updatedOn)}
																marketName={item.gameReference}
																category={item.category}
																reviewID={item._id}
																roundId={item.roundId}
																// winningAmount={item.amountAfterCommison}
																winning={item.status === "W" ? item.wining - item.commissionAmount : ""}
																transactionId={item.transactionId}
																item={item.status}
															/>
														)}

														{!item.gameName && (
															<BetHistoryCards
																title={item.status === "W" ? "Win" : "Loss"}
																type={item.status === "W" ? "win" : "loss"}
																amount={item.amount}
																date={config.dateTimeCompleteIST(item.createdOn)}
																marketName={item.gameReference}
																reviewID={item._id}
																roundId={item.roundId}
																transactionId={item.transactionId}
															/>
														)}
													</Grid>
												);
											} else {
												return null;
											}
										})
									}
								</Grid>
							}
							{Bet_History.length === 0 &&
								<BetHistoryNoData />
							}
						</div>
					</div>
					<Stack spacing={2}>
						<Pagination
							className="d-flex justify-content-center mt-2"
							count={Math.ceil(totalCount / Items_per_Page)}
							color="secondary"
							page={Current_Completed_Bets_Page}
							onChange={(Event, New_Page) => Change_Page(Bet_Types.Completed, New_Page)}
						/>
					</Stack>
				</TabPanel>
			</Box>
			{
				filterPopup && (
					<BetHistoryFilterModal
						closeFilterPopup={closeFilterPopup}
						selectedTab={Selected_Tab}
						onFilterPress={filterBetHistory}

					/>
				)
			}
		</div >
	);
}

export default BetHistory;