import { createContext, useState, useEffect, useContext } from "react";
import { API_Endpoint } from "../Configuration/Settings";
import { User_Context } from "./User";
import { useNavigate } from "react-router-dom";
import Logout_User from "../Utilities/Logout";
import { Various_APIs } from "../API/Various_APIs";
import { decryptData } from "../Utilities/DecryptData";
// import assets
import banner1 from "../Assets/banners/homeBanners/banner1.png";

const Game = {
    state: {
        topGames: [],
        whatsapp: "",
        telegram: "",
        instagram: "",
        twitter: "",
        stateData: [],
        cityData: [],
        subAdminId: "",
        appDetail: [],
        bonusBanner: [],
    },
    Set_State: () => {},
};

export const Game_Context = createContext(Game);

const Game_Provider = ({ children }) => {
    const [state, Set_State] = useState(Game.state);
    const [banner, setBanner] = useState([]);
    const [bannerArray, setBannerArray] = useState([]);
    const [gameArray, setGameArray] = useState([]);
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();

    // useEffect(()=>{
    //   setBannerArray([...bannerArray,banner1,banner1,banner1])
    // },[])

    useEffect(() => {
        var topGames = [];
        var whatsapp = "";
        var instagram = "";
        var twitter = "";
        var telegram = "";
        var stateData = [];
        var cityData = [];
        var subAdminId = "";
        var appDetail = [];
        var bonusBanner = [];
        var mostPlayed = [];
        const state = async () => {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: API_Endpoint,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${User.token}`,
                },
            };
            Various_APIs.Get_Landing_Page_Data()
                .then((response) => {
                    const data = decryptData(response.data.data);
                    stateData = data.payload.countries[0].states;
                    cityData = data.payload.countries[0].cities;
                    subAdminId = data.payload.subAdminId;
                    appDetail = data.payload.appDetail;
                    bonusBanner = data?.payload?.bonusBanner;
                    const bannerArrs = data.payload.bannerGames.map(async (item) => {
                        if (item.type === "banner" && item.status) {
                            return {
                                ...item,
                                imagePath: `${item.imagePath}`,
                            };
                        }
                    });
                    const gameArrs = data.payload.bannerGames.map(async (item) => {
                        if (item.type === "game" && item.status) {
                            return {
                                ...item,
                                imagePath: `${item.imagePath}`,
                            };
                        }
                    });
                    Promise.all(bannerArrs).then((item) => {
                        var array = [];
                        item.forEach((element) => {
                            if (element) {
                                array.push(element);
                            }
                        });
                        setBannerArray(array);
                    });
                    Promise.all(gameArrs).then((item) => {
                        var array1 = [];
                        item.forEach((element) => {
                            if (element) {
                                array1.push(element);
                            }
                        });
                        setGameArray(array1);
                    });
                    topGames = data.payload.topGames;
                    data.payload.socialMedia.forEach((element) => {
                        if (element.name === "WhatsApp Riya") {
                            whatsapp = element.link;
                        } else if (element.name === "Instagram") {
                            instagram = element.link;
                        } else if (element.name === "Twitter") {
                            twitter = element.link;
                        } else if (element.name) {
                            telegram = element.link;
                        }
                    });
                    mostPlayed = data.payload.mostPlayed;
                    Set_State({
                        topGames,
                        mostPlayed,
                        bannerArray,
                        gameArray,
                        whatsapp,
                        telegram,
                        instagram,
                        twitter,
                        stateData,
                        cityData,
                        subAdminId,
                        appDetail,
                        bonusBanner,
                    });
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                        Logout_User(Set_User);
                        navigate("/");
                    }
                });
        };

        state();
    }, [banner]);
    useEffect(() => {
        Set_State({
            ...state,
            bannerArray,
            gameArray,
        });
    }, [gameArray, bannerArray]);
    return <Game_Context.Provider value={{ state, Set_State }}>{children}</Game_Context.Provider>;
};

export default Game_Provider;
