import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./TopProviders.css";
// import assets
import {
    QTechUrlLobbyTypes,
    WacsUrlLobbyTypes,
} from "../../Configuration/Enums/QTechLobbyUrlValues";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";
import { User_Context } from "../../Contexts/User";
import { useNavigate } from "react-router-dom";
import ezugiImage from "../../Assets/topProviders/ezugi.png";
import evolutionImage from "../../Assets/topProviders/evolution.png";
import saGamingImage from "../../Assets/topProviders/saGaming.png";
import spribeImage from "../../Assets/Images/home-page-lobby-banners/spribe.png";
import beterImage from "../../Assets/Images/home-page-lobby-banners/beter.png";
import betGamesImage from "../../Assets/Images/home-page-lobby-banners/bet-games.png";
import mojosImage from "../../Assets/Images/home-page-lobby-banners/mojos.png";
import vivoImage from "../../Assets/Images/home-page-lobby-banners/vivo.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";
import { SessionHelper } from "../../Utilities/SessionHelper";

const TopProviders = () => {
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const navigate = useNavigate();
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const { User } = useContext(User_Context);

    let bannerArray = [
        { image: evolutionImage, type: "evolution" },
        { image: ezugiImage, type: "ezugi" },
        { image: saGamingImage, type: "sa" },
        { image: spribeImage, type: "spribe" },
        { image: beterImage, type: "beter" },
        { image: mojosImage, type: "mojos" },
        { image: vivoImage, type: "vivo" },
        { image: betGamesImage, type: "betGames" },
    ];

    const onClickImage = async (type) => {
        if (type === "evolution") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.Evolution } });
        } else if (type === "ezugi") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.Ezugi } });
        } else if (type === "sa") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.SaGaming } });
        } else if (type === "spribe") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.Spribe } });
        } else if (type === "beter") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.Beter } });
        } else if (type === "mojos") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.SevenMojos } });
        } else if (type === "vivo") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.Vivo } });
        } else if (type === "betGames") {
            navigate("/qtech-casino-game", { state: { gameId: QTechUrlLobbyTypes.BetGames } });
        }
    };

    const onClickWacs = async (type) => {
        if (type === "evolution") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Evolution}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        } else if (type === "ezugi") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Ezugi}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        } else if (type === "spribe") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Spribe}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        } else if (type === "mojos") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.SevenMojos}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        } else if (type === "vivo") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.Vivo}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        } else if (type === "betGames") {
            navigate("/casino-game", {
                state: {
                    Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${WacsUrlLobbyTypes.BetGames}&type=${WACS_Payment_Modes.Charged}`,
                },
            });
        }
    };

    return (
        <>
            <div className="top-providers-section">
                <GamesHeaderMarker showBtn={false} markerText={"Top Providers"} />
                <div className="swiper-container" style={{ margin: "5px" }}>
                    <Swiper
                        id="provider-swiper"
                        slidesPerView={isTabOrMobile ? 2 : 3}
                        slidesPerColumn={1}
                        className="myGameSwiper"
                        freeMode={true}
                        loop={false}
                        autoplay={{ delay: 2500 }}
                        slidesPerGroup={isTabOrMobile ? 2 : 3}
                        navigation={false}
                        pagination={{ clickable: true }}
                        modules={[FreeMode, Navigation, Pagination, Autoplay]}
                    >
                        {bannerArray.map((item, index) => {
                            if ((index + 1) % 2 === 0) return null;
                            return (
                                <>
                                    <SwiperSlide
                                        key={index}
                                        style={{ width: "220px", backgroundColor: "transparent" }}
                                    >
                                        <div className="provider-img">
                                            <LazyLoadImage
                                                key={index}
                                                onClick={
                                                    activeCasinoProvider === "QTECH"
                                                        ? () => onClickImage(item.type)
                                                        : () => onClickWacs(item.type)
                                                }
                                                className="top-provider-image"
                                                src={item.image}
                                                alt="Fairbets"
                                            />
                                        </div>
                                        {bannerArray[index + 1] ? (
                                            <div className="provider-img">
                                                <LazyLoadImage
                                                    key={index + 1}
                                                    onClick={
                                                        activeCasinoProvider === "QTECH"
                                                            ? () =>
                                                                  onClickImage(
                                                                      bannerArray[index + 1].type
                                                                  )
                                                            : () =>
                                                                  onClickWacs(
                                                                      bannerArray[index + 1].type
                                                                  )
                                                    }
                                                    className="top-provider-image"
                                                    src={bannerArray[index + 1].image}
                                                    alt="Fairbets"
                                                />
                                            </div>
                                        ) : null}
                                    </SwiperSlide>
                                </>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default TopProviders;
