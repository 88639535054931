import React, { useState, useContext, useRef } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Game_Context } from "../../Contexts/Games";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from '../../API/User_APIs';
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { decryptData } from "../../Utilities/DecryptData";
import { getRandomElement } from "../../Utilities/getRandomElement";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { firebaseConfig } from "../../Configuration/Firebase";
import useOutsideAlerter from "../../Hooks/OutsideClickHandler";

// Components
import ChangePasswordModal from "../../components/Modals/ChangePasswordModal";

// Assets
import { ReactComponent as Whatsappicon } from "../../Assets/Images/whatsapp.svg";
import { ReactComponent as Chat } from "../../images/profile-images/messenger.svg";
import { ReactComponent as Mail } from "../../images/profile-images/mail.svg";


// Styles
import "../../css/profile.css";
import ProfileTabs from "./ProfileTab";
import MobileFooter from "../../components/MobileFooter/MobileFooter";

function Profile() {
	const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
	const [modalChangePassword, setModalChangePassword] = useState(false);
	const helpBoxRef = useRef(null);
	const { User, Set_User } = useContext(User_Context);
	const { Language, Set_Language } = useContext(Language_Context)
	const navigate = useNavigate();


	const app = initializeApp(firebaseConfig);
	const messaging = getMessaging(app);


	const onNavigateToLiveChat = async () => {
		try {
			const response = await User_APIs.Get_Support_Executive_List({}, User.token)
			const responseData = response.data ? response.data : {}
			const { success, data } = responseData;
			if (success) {
				const chatSupportData = decryptData(data);
				const dp_id = getRandomElement(chatSupportData.payload);
				// getSupportFirebaseToken(dp_id)
				requestBrowserPermissions(dp_id)
			}
		} catch (error) {
			if (error.response && error.response.status === 401) {
				Logout_User(Set_User)
				navigate('/')
			}
		}
	}

	const requestBrowserPermissions = (dp_id) => {
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				getToken(messaging, { vapidKey: 'BNw5X5Cq68O_tEY_t8d5x2t1dK6dQg91sqKYPOc364yPlHh4aIV6WLPIhrF-RmhLCnbBW_qyUYtqnAYKedrsJkQ' }).then((currentToken) => {
					if (currentToken) {
						registerUserForChatApplication(currentToken, dp_id)
					} else {
						// Show permission request UI
						// console.log('No registration token available. Request permission to generate one.');
						// ...
					}
				}).catch((err) => {
					// console.log('An error occurred while retrieving token. ', err);
					// ...
				});
			} else if (permission === "denied") {
				toast.info('Please enable notifications in your Browser settings to Proceed')
			}
		}
		)
	}

	const registerUserForChatApplication = async (fcmToken, dp_id) => {
		try {
			let payload = {
				_id: User.data._id,
				supportExecutiveDp_Id: dp_id,
				mobile: User.data.mobile,
				name: User.data.name,
				fireBaseToken: fcmToken
			}

			const response = await User_APIs.Register_User_For_Chat(payload, User.token);

			const responseData = response.data ? response.data : {}
			const { success, data } = responseData;
			if (success) {
				const decryptedData = decryptData(data)
				const User_Copy = { ...User };
				User_Copy.data.fireBaseToken = decryptedData.payload.fireBaseToken;
				User_Copy.data.supportExecutiveDp_Id = decryptedData.payload.supportExecutiveDp_Id;
				Set_User(User_Copy);
				navigate('/live-chat')
			}
		} catch (error) {
			if (error.response && error.response.status === 401) {
				Logout_User(Set_User)
				navigate('/')
			}
		}
		// setLoading(false)
	}

	const navigateToDeposit = () => {
		navigate("/deposit?data=deposit");
	};

	const copyShare = () => {
		if (isTabOrMobile) {
			window.navigator
				.share({
					text: User.data?.referralCodeUser,
					url: 'https://fairbets.co/'
				})
				.then(() => toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`))
				.catch(err => toast.error(`Unable to copy`));
		} else {
			window.navigator.clipboard.writeText(`https://fairbets.co/ - ${User.data?.referralCodeUser}`);
			toast.success(`Referral Code: ${User.data?.referralCodeUser} copied`);
		}

	}

	const { state: gameState, Set_State: setGameState } =
		useContext(Game_Context);
	const {
		whatsapp,
		telegram,
	} = gameState;

	const [showHelpOption, setShowHelpOption] = useState(false)

	const closeHelpBox = () => {
		setShowHelpOption(false)
	}

	const logOut = () => {
		Logout_User(Set_User)
		navigate('/')
	}

	useOutsideAlerter(helpBoxRef, closeHelpBox);

	const handleLiveChatClick = () => {
		const userAgent = window?.navigator?.userAgent ?? '';
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
			window.open(whatsapp, '_blank', 'noopener,noreferrer');
			return;
		}
		onNavigateToLiveChat();
	}

	return (
		<div className={isTabOrMobile ? "common-style-profile-mobile" : "common-style-profile"} >

			<div style={{ width: '100%', paddingBottom: isTabOrMobile ? '100px' : '30px' }}>
				<div className="profile-text-content">
					{/* <span>Exposure ( Pending Bet Amount ): {Math.round(User.data.exposer)}</span> */}
					<span>{`Mobile: ${User.data.mobile}`}</span>
				</div>

				<hr className="hr-content" />

				<div className="user-main-container">

					<div className="user-container">
						<span>{`Welcome ${User.data.name}`}</span>
						<div className="referalCodeClick" onClick={copyShare}>
							{/* <span style={{ fontWeight: '700', fontSize: 12, textDecoration: 'underline' }}>{`Referral Code: ${User.data.referralCodeUser}`}</span> */}
							{/* <div onClick={copyShare}>
								<CopyIcon className="copy-icon" />
							</div> */}

						</div>

					</div>

					{/* <div className="deposit-container">
						<button
							className="deposit-button"
							onClick={navigateToDeposit}
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{ width: "15px" }}>
								<path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
							<span className="deposit-text"> DEPOSIT</span>
						</button>
					</div> */}

				</div>

				<div className="user-main-container">
					<div className="bonus-text">
						<span>Balance :</span>
						<span style={{ fontWeight: '700' }}>{`Rs. ${Math.floor(User.data.balance)}`}</span>
					</div>
					{/* <div className="bonus-text">
						<span>Bonus Balance : </span>
						<span style={{ fontWeight: '700' }}>{`Rs. ${User.data.bonusWalletBalance}`}</span>
					</div> */}
				</div>

				<div className="bonus-text">
					<span>Credit Limit :</span>
					<span style={{ fontWeight: '700' }}>{`Rs. ${Math.floor(User.data.initialBalance)}`}</span>
				</div>

				{/* <hr className="hr-content" /> */}

				{/* <div className="whatsAppContainer">
					<div className="whatsapp-img-container" onClick={() => window.open(whatsapp, '_blank', 'noopener,noreferrer')}>
						<span style={{ fontWeight: '700', }} className="img-content">Message us on WhatsApp !</span>
						<Whatsappicon className="whatsapp-img" onClick={() => window.open(whatsapp, '_blank', 'noopener,noreferrer')} />
					</div>
				</div> */}

				<hr className="hr-content" />

				<ProfileTabs />
				<hr className="hr-content mb-2" />
				{/* <p className="text-content" onClick={() => navigate('/feedback')}>{Translation_Table.feedback[Language]}</p> */}
				{/* <div className="new-help-box">
					<p className="text-content" onClick={() => setShowHelpOption(true)}>{Translation_Table.help[Language]}</p>
					{showHelpOption &&
						<div className="help-box-horizontal" ref={helpBoxRef}>
							<div className='help-box-icon-container'>
								<Mail width={18} style={{ marginInlineEnd: '5px' }} />
								<span className="MobileFooter_label1-alt" style={{ cursor: 'pointer' }} onClick={() => window.location.replace("mailto:support@fairbets.co")}>
									Email Us
								</span>
							</div>

							<div className='help-box-icon-container'>
								<Chat width={18} style={{ marginInlineEnd: '5px' }} />
								<span className="MobileFooter_label1-alt" style={{ cursor: 'pointer' }} onClick={handleLiveChatClick}>
									Live Chat
								</span>
							</div>

						</div>

					}
				</div> */}
                <div className="d-flex justify-content-center mt-3">
                    <p className="text-content" onClick={() => logOut()}>{Translation_Table.logout[Language]}</p>
                </div>
			</div>

			<ChangePasswordModal
				show={modalChangePassword}
				onHide={() => {
					setModalChangePassword(false);
				}}
			/>
			<MobileFooter />
		</div>

	);
}

export default Profile;