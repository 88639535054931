import React, { useState, useEffect, useRef, useContext } from "react";
import { User_Context } from "../../Contexts/User";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Login from "./Login";
import Eyeslash from "../../images/eye-slash.svg";
import Eyefill from "../../images/eye-fill.svg";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";
import Loader from "../Loader/Loader";
import { Game_Context } from "../../Contexts/Games";
import { User_APIs } from "../../API/User_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { encryptData } from "../../Utilities/EncryptData";
import { decryptData } from "../../Utilities/DecryptData";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../../Configuration/Firebase';
import { getRandomElement } from "../../Utilities/getRandomElement";
import axios from "axios";
import { API_Endpoint } from "../../Configuration/Settings";
import { Bonus_Wallet_APIs } from "../../API/Bonus_Wallet_API";

// Components
import SignUpBanner from "../Banner/SignUpBanner";
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import OTPModal from "./OTPModal";

// Styles
import '../../css/signup.css'
import '../../css/otpform.css'

function SignUp(props) {
	const [modalShow4, setModalShow4] = useState(false);
	const [confirmPasword, setConfirmPassword] = useState("");
	const [click, setClick] = useState(false);
	const [firstname, setFirstName] = useState("");
	const [lastname, setLastName] = useState("");
	const [mobile, setMobile] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [city, setCity] = useState("");
	const [verifyFirstName, setVerifyFirstName] = useState(true);
	const [verifyLastName, setVerifyLastName] = useState(true);
	const [verifyEmail, setVerifyEmail] = useState(true);
	const [verifyMobile, setVerifyMobile] = useState(true);
	const [verifyPassword, setVerifyPassword] = useState(true);
	const [showOtpForm, setShowotpForm] = useState(false);
	const [verifyConfirmPassword, setVerifyConfirmPassword] = useState(true);
	const [otp, setOtp] = useState("");
	const [error, setError] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [velidOtp, setVelidOtp] = useState(true);
	const [remainingMinutes, setRemainingMinutes] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [remainingSeconds, setRemainingSeconds] = useState(0);
	const [stateName, setStateName] = useState("");
	const [cityArray, setCityArray] = useState([]);
	const [refCode, setRefCode] = useState('');
	const signupModal = useRef(null);
	const [verifyRefCode, setVerifyRefCode] = useState(true)
	const [supportDPID, setSupportDPID] = useState('');
	const [fcmToken, setFcmToken] = useState('');
	const vapidKey = process.env.VAPID_KEY;
	const { User, Set_User } = useContext(User_Context);
	const cookies = new Cookies();
	const { state: gameState, Set_State: setGameState } =
		useContext(Game_Context);
	const { stateData, cityData, subAdminId, whatsapp } = gameState;
	const { Language, Set_Language } = useContext(Language_Context);
	const [refErr, setRefErr] = useState(false);
	const [refErrText, setRefErrText] = useState("")
	const [labelRef, setLabelRef] = useState("");
	const [validRef, setValidRef] = useState(false);
	const [errorEmail, setErrorEmail] = useState(false);
	const [helperText, setHelperText] = useState('');


	useEffect(() => {
		var cityArray = [];
		cityData.forEach((element) => {
			if (element[stateName]) {
				cityArray = element[stateName];
			}
		});
		setCityArray(cityArray);
	}, [stateName]);

	const handleOtpVerification = () => {
		countdown(5);
		setShowotpForm(true);
	};

	// const app = initializeApp(firebaseConfig);
	// const messaging = getMessaging(app);

	// const requestBrowserPermissions = () => {
	// 	Notification.requestPermission().then((permission) => {
	// 		if (permission === 'granted') {
	// 			getToken(messaging, { vapidKey: 'BD1BsGW2yFGnn0YkGmIPXInT6hG_JpUvAHmg4qFotdMG0gr7bFNxvTviE5iMd4_NgDoAW5FEX11-A6gx64bbg9s' }).then((currentToken) => {
	// 				if (currentToken) {
	// 					// Send the token to your server and update the UI if necessary
	// 					// ...
	// 					setFcmToken(currentToken)
	// 				} else {
	// 					// Show permission request UI
	// 					console.log('No registration token available. Request permission to generate one.');
	// 					// ...
	// 				}
	// 			}).catch((err) => {
	// 				console.log('An error occurred while retrieving token. ', err);
	// 				// ...
	// 			});
	// 		}
	// 	}
	// 	)
	// }

	// const getChatSupport = async () => {
	// 	setIsLoading(true)
	// 	try {
	// 		const response = await User_APIs.Get_Chat_Support({})
	// 		const responseData = response.data ? response.data : {}
	// 		const { success, data } = responseData;
	// 		if (success) {
	// 			const chatSupportData = decryptData(data);
	// 			const dp_id = getRandomElement(chatSupportData.payload);
	// 			setSupportDPID(dp_id)
	// 		}
	// 	} catch (error) {
	// 		// console.log(error, 'error')
	// 	}
	// 	setIsLoading(false)
	// }

	// useEffect(() => {
	// 	requestBrowserPermissions();
	// 	getChatSupport();
	// }, []);


	const validateEmail = (email) => {
		const emailRegex =
			/^(([^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+(\.[^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email);
	};
	const validatMobile = (mobile) => {
		const emailRegex = /[6-9]{1}[0-9]{9}/;
		return emailRegex.test(mobile);
	};
	const validatePassword = (password) => {
		const emailRegex = /(?=.{8,})/;
		return emailRegex.test(password);
	};

	function countdown(minutes) {
		let seconds = minutes * 60;

		const timer = setInterval(() => {
			const Minutes = Math.floor(seconds / 60);
			setRemainingMinutes(Minutes);
			const Seconds = seconds % 60;
			setRemainingSeconds(Seconds);
			seconds--;

			if (seconds < 0) {
				clearInterval(timer);
				setShowotpForm(false);
			}
		}, 1000);
	}

	const navigateToLogin = () => {
		props.onHide();
		setModalShow4(true)
	};

	const closeLoginModal = () => {
		setModalShow4(false)
	}
	const handleClose = () => {
		setShowotpForm(false)
		setVerifyConfirmPassword(true);
		setVerifyEmail(true);
		setVerifyMobile(true);
		setVerifyPassword(true);
		setOtp("");
		props.onHide();
	};

	const handleCloseModal = () => {
		setVerifyConfirmPassword(true);
		setFirstName("")
		setLastName("")
		setEmail("")
		setMobile("")
		setStateName("")
		setCity("")
		setPassword("")
		setVerifyPassword(true);
		setConfirmPassword("")
		setClick(false)
		props.onHide()
	}

	const register = async () => {
		if (firstname.length === 0 && firstname === "") {
			setVerifyFirstName(false);
			return;
		} else {
			setVerifyFirstName(true)
		}
		if (lastname.length === 0 && lastname === "") {
			setVerifyLastName(false);
			return;
		} else {
			setVerifyLastName(true);
		}
		if (email.length === 0 && email === "") {
			setVerifyEmail(false);
			return;
		}
		if (mobile.length === 0 && mobile === "") {
			setVerifyMobile(false);
			return;
		}
		if (refCode.length > 0 && !validRef) {
			setVerifyRefCode(false)
			setRefErr(true)
			setLabelRef("Enter valid referral code first")
			return
		}
		if (stateName === "") {
			toast.error('State cannot be blank');
			return;
		}
		if (city === "") {
			toast.error('City cannot be blank');
			return;
		}
		// setVerifyEmail(validateEmail(email));
		setVerifyMobile(validatMobile(mobile));
		setVerifyPassword(validatePassword(password));
		if (password !== confirmPasword) {
			setVerifyConfirmPassword(false);
		}
		if (!click) {
			notify();
			return;
		}

		if (
			validateEmail(email) &&
			validatMobile(mobile) &&
			validatePassword(password) &&
			password === confirmPasword &&
			click
		) {
			setIsLoading(true);
			let data = {
				city: city,
				subAdminId: subAdminId,
				deviceType: "app",
				email: email,
				mobile: mobile,
				name: `${firstname + " " + lastname}`,
				password: password,
				state: stateName,
			};

			if (refCode.length > 0) {
				data.referredCode = refCode.toUpperCase()
			}

			// if (fcmToken) {
			// 	data.fireBaseToken = fcmToken;
			// }
			// if (supportDPID !== "") {
			// 	data.supportExecutiveDp_Id = supportDPID;
			// }
			User_APIs.Check_User_Existence(data)
				.then((response) => {
					if (response && response.data && response.data.success) {
						setIsLoading(false);
						handleOtpVerification();
						setFirstName("");
						setLastName("");
						setEmail("");
						setStateName("");
						setCity("");
						setPassword("");
						setConfirmPassword("");
						setClick(false)
					}
				})
				.catch((error) => {
					if (
						error &&
						error.response &&
						error.response.data &&
						error.response.data.message
					) {
						setIsLoading(false);
						setError(error.response.data.message);
						if (error.response.data.message === "email already exist") {
							toast.error('Email already exists')
							setEmail("");
							setVerifyEmail(false);
						} else if (error.response.data.message === '\"email\" must be a valid email') {
							toast.error('Invalid email address')
						}
						else if (error.response.data.message === "mobile already exist") {
							setMobile("");
							toast.error('Mobile already exists')
							setVerifyMobile(false);
						}
					}
					return error;
				});
		}
	};
	const call = (e) => {
		setIsLoading(true);
		e.preventDefault();
		let data = {
			otp: parseInt(otp),
			mobile: mobile,
		};
		User_APIs.Register_User(data)
			.then(async (response) => {
				if (response && response.data && response.data.success) {
					response.data.data = decryptData(response.data.data);
					response.data.data = response.data.data.payload;
					Set_User(response.data);
					setIsLoading(false);
					setVelidOtp(true);
					cookies.set("userData", encryptData(response.data.data));
					cookies.set("jwt-authorization", encryptData(response.data.token));
					handleClose();
				}
			})
			.catch((error) => {
				if (error.response.data.message === "invalid otp") {
					toast.error('invalid otp');
					setIsLoading(false);
					setVelidOtp(false);
				}
				return error;
			});
	};
	const notify = () => {
		toast.info("Please accept the Terms & Conditions");
	};
	const navigate = useNavigate();

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(!passwordVisible);
	};

	const [passwordVisible1, setPasswordVisible1] = useState(false);

	const handleToggle1 = () => {
		setPasswordVisible1(!passwordVisible1);
	};

	const handleFirstNameChange = (e) => {
		const newName = e.target.value;
		const regex = /^[a-zA-Z0-9\s]*$/;
		if (regex.test(newName) && newName.length <= 25) {
			setFirstName(newName);
			setVerifyFirstName(true);
		}
	};


	const handleLastNameChange = (e) => {
		const newName = e.target.value;
		const regex = /^[a-zA-Z0-9\s]*$/;
		if (regex.test(newName) && newName.length <= 20) {
			setLastName(newName);
			setVerifyLastName(true);
		}
	};
	const handleEmailChange = (e) => {
		const newEmail = e.target.value;
		const regex = /^(([^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+(\.[^-`\\\]!@#$%^&*()+=_,?><';:"{}/[|.\s]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (newEmail === '') {
			setEmail('');
			setVerifyEmail(false);
			setErrorEmail(false);
			setHelperText('');
		}
		else if (regex.test(newEmail) && newEmail.length <= 50) {
			setEmail(newEmail);
			setVerifyEmail(true);
			setErrorEmail(false);
			setHelperText('');
		} else {
			setEmail(newEmail);
			setVerifyEmail(false);
			setErrorEmail(true);
			setHelperText('Invalid email address');
		}
	};

	const handleMobileChange = (e) => {
		const newName = e.target.value;
		if (newName.length <= 10) {
			setMobile(newName);
			setVerifyMobile(true);
		}
	};

	const handleRefCode = (e) => {
		const newName = e.target.value.toUpperCase();
		if (newName.length <= 6) {
			setRefCode(newName);
			setVerifyRefCode(true);
		}
	};

	const refCodeCheck = () => {
		let data = {
			referral: refCode
		}
		Bonus_Wallet_APIs.referralCodeCheck(data)
			.then((response) => {
				if (response.data.success) {
					toast.success("Referral Code Matched")
					setValidRef(true)
				} else {
					setVerifyRefCode(false)
					setRefErr(true)
					setLabelRef("You have entered an invalid referral code")
					setValidRef(false)
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				onHide={handleClose}
				id="signup-modal"
				ref={signupModal}
			>
				<Modal.Header className="hdr">
					{Translation_Table.register[Language]}
					<Crosssmall onClick={handleCloseModal} className="cross-kyc" />
				</Modal.Header>
				<Modal.Body className="signupmod">
					<div>
						{isLoading ? <Loader /> : <></>}
						<SignUpBanner whatsapp={whatsapp} />

						<div className="login-second">
							<section className="secLogin">
								<div className="login-form">
									<div
										className="login-main-bx registerForm"
										name="registerForm"
										noValidate
									>
										<div className="col-12">
											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">

														{verifyFirstName ? (
															<GeneralTextfield
																onChange={handleFirstNameChange}
																label={Translation_Table.firstname[Language] + " * "}
																value={firstname}
															/>
														) : (
															<GeneralTextfield
																error={true}
																onChange={handleFirstNameChange}
																label={'First name cannot be blank'}
																value={firstname}
															/>
														)}

													</div>

												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">
														{verifyLastName ? (
															<GeneralTextfield
																onChange={handleLastNameChange}
																label={Translation_Table.lastname[Language] + " * "}
																value={lastname}
															/>
														) : (
															<GeneralTextfield
																error={true}
																onChange={handleLastNameChange}
																label={'Last name cannot be blank'}
																value={lastname}
															/>
														)}

													</div>

												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">
														{verifyEmail ? (
															<GeneralTextfield
																onChange={handleEmailChange}
																label={Translation_Table.email[Language] + " * "}
																type="email"
																value={email}
																error={errorEmail}
																// helperText={helperText}
															/>
														) : (
															<GeneralTextfield
																error={errorEmail}
																onChange={handleEmailChange}
																label={
																	error === "email already exist"
																		? `${Translation_Table.emailalreadyexist[Language]
																		}`
																		: `${Translation_Table.entervalidemail[Language]
																		}`
																}
																type="email"
																value={email}
																// helperText={helperText}
															/>
														)}
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">
														{verifyMobile ? (
															<GeneralTextfield
																onChange={handleMobileChange}
																label={
																	Translation_Table.mobileNumber[Language] + " * "
																}
																type="number"
																value={mobile}
															/>
														) : (

															<GeneralTextfield
																onChange={handleMobileChange}
																label={
																	error === "mobile already exist"
																		? `${Translation_Table.mobileAlreadyExist[Language]
																		}`
																		: `${Translation_Table.entervalidmobile[Language]
																		}`
																}
																error={true}
																value={mobile}
															/>
														)}
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">
														{verifyRefCode ? (
															<GeneralTextfield
																onChange={handleRefCode}
																label={
																	'Referral Code'
																}
																type="text"
																value={refCode}
																onBlur={refCodeCheck}
															/>
														) : (

															<GeneralTextfield
																onChange={handleRefCode}
																label={labelRef}
																error={refErr}
																value={refCode}
															/>
														)}
													</div>
												</div>
											</div>

											<div className="row mt-2">
												<div className="col-6">
													<div className="username-input">
														<select
															onChange={(e) => {
																setStateName(e.target.value);
															}}
															className="form-select"
															style={{
																fontFamily: "Roboto",
															}}
														>
															<option selected value="null">
																{" "}
																{
																	Translation_Table.selectState[Language]
																}{" "}
															</option>
															{stateData?.map((review, index) => {
																return <option key={index} value={review}>{review}</option>;
															})}
														</select>
													</div>
													<div className="clrBoth" />
												</div>
												<div className="col-6">
													<div className="username-input">
														<select
															style={{
																fontFamily: "Roboto",
															}}
															className="form-select"
															formcontrolname="city"
															onChange={(e) => {
																setCity(e.target.value);
															}}
														>
															<option selected value="null">
																{Translation_Table.selectCity[Language]}
															</option>
															{cityArray?.map((review, index) => {
																return <option key={index} value={review}>{review}</option>;
															})}
														</select>
													</div>

												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<div className="username-input mt-3">
														{verifyPassword ? (
															<GeneralTextfield
																onChange={(e) => {
																	setPassword(e.target.value);
																}}
																label={Translation_Table.password[Language] + " * "}
																autoComplete="current-password"
																type={passwordVisible ? "text" : "password"}
															/>
														) : (
															<GeneralTextfield
																error={true}
																label={
																	Translation_Table.enterValid8DigitPassword[Language]
																}
																onChange={(e) => {
																	setPassword(e.target.value)
																	setVerifyPassword(true);
																}}
															/>
														)}
														<a className="eye1" onClick={handleToggle}>
															{passwordVisible ? (
																<img src={Eyefill} />
															) : (
																<img src={Eyeslash} />
															)}
														</a>
													</div>

												</div>
											</div>
											<div className="row" style={{ marginTop: "-23px" }}>
												<div className="col-12">
													<div className="username-input">
														{verifyConfirmPassword ? (
															<GeneralTextfield
																label={
																	Translation_Table.confirmPassword[Language] + " * "
																}
																type={passwordVisible1 ? "text" : "password"}
																onChange={(e) => {
																	setConfirmPassword(e.target.value);
																}}
																autoComplete="current-password"
															/>
														) : (
															<GeneralTextfield
																error={true}
																label={
																	Translation_Table.confirmpasswordmismatch[Language]
																}
																onChange={(e) => {
																	setVerifyConfirmPassword(true)
																	setConfirmPassword(e.target.value)
																}}
															/>
														)}
														<a className="eye1" onClick={handleToggle1}>
															{passwordVisible1 ? (
																<img src={Eyefill} />
															) : (
																<img src={Eyeslash} />
															)}
														</a>
													</div>

												</div>
											</div>

											<div className="row">
												<div className="col-12">
													<p className="signupp">
														<input
															style={{ marginTop: "20px", width: "auto" }}
															type="checkbox"
															className
															checked={click}
															onClick={() => {
																if (click) {
																	setClick(false);
																} else {
																	setClick(true);
																}
															}}
														/>
														<span className="check-box">
															{" "}
															{
																Translation_Table.Iamoflegalage18togambleandIacceptthe[Language]
															}{" "}
															<a
																className="text-decoration-none"
																href="/terms-and-conditions"
																style={{ color: "#de641f" }}
															>
																{" "}
																{
																	Translation_Table.TermsandConditions[Language]
																}{" "}
															</a>{" "}
															and{" "}
															<a
																className="text-decoration-none"
																href="/aml-policy"
																style={{ color: "#de641f" }}
															>
																{" "}
																{
																	Translation_Table.privacypolicy[Language]
																}{" "}
															</a>
															.
														</span>
													</p>
												</div>
											</div>
											<div className="clrBoth" />
											<div className="newsignup mt-2">
												<GeneralButton
													onClick={() => {
														register();
													}}
													showText={true}
													text={Translation_Table.register[Language]}
												/>
											</div>
											<div className="newsignup mt-1" onClick={navigateToLogin}>
												<a className="under">
													{Translation_Table.alreadyhaveaccount[Language]}
												</a>
											</div>

											{showOtpForm && (
												<OTPModal
													onChange={setOtp}
													value={otp}
													validOtp={velidOtp}
													onClick={(e) => {
														call(e);
													}}
													remainingMinutes={remainingMinutes}
													remainingSeconds={remainingSeconds}
												/>
											)}

										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</Modal.Body>

			</Modal>
			<Login show={modalShow4} onHide={closeLoginModal} />
		</div>

	);
}
export default SignUp;