import React, { useState } from 'react'
import "./FancyBetsSection.css"
import SessionRunCard from '../../../../ExchangeReusableCards/SessionRunCard/SessionRunCard'
import AllFancyBetsSession from './AllFancyBetsSession'
const FancyBetsSection = ({ fancy,gameId }) => {
  const [activeFancyTab, setActiveFancyTab] = useState("ALL")
  return <>

    <div className="fancy-section">
      <div className="fancy-section-marker">Fancy</div>
      <div className="fancy-section-header">
        <div className="fancy-section-navigation-tabs">
          <div className="sessino-tab"
            id={activeFancyTab === "ALL" ? 'fancy-active-tab' : ''}
            onClick={() => setActiveFancyTab("ALL")} >ALL</div>
          <div className="sessino-tab"
            id={activeFancyTab === "SESSIONS" ? 'fancy-active-tab' : ''}
            onClick={() => setActiveFancyTab("SESSIONS")}>SESSIONS</div>
          <div className="sessino-tab"
            id={activeFancyTab === "W/P MARKET" ? 'fancy-active-tab' : ''}
            onClick={() => setActiveFancyTab("W/P MARKET")}>W/P MARKET</div>
        </div>
      </div>

      <div className="session-runs-section">
        {
          activeFancyTab === "ALL" && (fancy?.length === 0 ? <h6
            style={{ fontSize: "0.9rem", textAlign: 'center', marginTop: '100px', marginLeft: '10px' }}>
            No Sessions</h6> : <AllFancyBetsSession gameId={gameId} fancy={fancy} />)
        }

        {
          activeFancyTab === "SESSIONS" && <h6
            style={{ fontSize: "0.9rem", textAlign: 'center', marginTop: '100px', marginLeft: '10px' }}>
            No Sessions</h6>
        }

        {activeFancyTab === "W/P MARKET" && <h6
          style={{ fontSize: "0.9rem", textAlign: 'center', marginTop: '100px', marginLeft: '10px' }}>
          No Market</h6>}

      </div>
    </div>

  </>
}

export default FancyBetsSection
