export const Languages = 
[
	{
		Code: 'hi',
		Name: 'Hindi'
	},
	{
		Code: 'en',
		Name: 'English'
	},
	{
		Code: 'ta',
		Name: 'Tamil'
	},
	{
		Code: 'te',
		Name: 'Telugu'
	}
]

export const Currency = 'INR';