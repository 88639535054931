import React, { useCallback, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SessionHelper } from "../../Utilities/SessionHelper";
import { Various_APIs } from "../../API/Various_APIs";
import { Providers_Enums } from "../../Configuration/Enums/Providers";

// Components
import SecondaryCarousel from "../Carousel/SecondaryCarousel";
import ComponentHeading from "../ComponentHeading/ComponentHeading";

// Style
import "./GameSlider.css";
import { useNavigate } from "react-router-dom";

function GameSlider({ data, navigateFun, heading }) {
    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    const navigate = useNavigate();

    // KEEP THE BELOW RENDER ITEM CODE COMMENTED INCASE WE NEED TO GO BACK TO THE CAROUSEL

    // const renderItem = useCallback(({ item, index }) => {
    //     return (
    //         <div
    //             key={index}
    //             onClick={(e) => {
    //                 navigateFun(item.iframeUrl);
    //             }}
    //         >
    //             <LazyLoadImage
    //                 className="game-item-image"
    //                 src={item.imagePath}
    //                 alt="Fairbets"
    //             />
    //         </div>

    //     )
    // }, [])

    const getAppConfig = async () => {
        const appConfig = await Various_APIs.Get_Landing_Page_Data();
        SessionHelper.setActiveExchangeProvider(
            appConfig?.data?.data?.payload?.activeCasinoProvider ?? Providers_Enums.CAPITAL_QTECH
        );
    };

    useEffect(() => {
        getAppConfig();
    }, []);

    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
    const handleNavigateFunc = (item) => {
        if (item?.category === "sattamatka") {
            navigate(`/${item?.iframeUrl}`);
            return;
        }
        if (item?.category === "exchange") {
            navigate("/exchange-select");
            return;
        }
        if (activeCasinoProvider === "QTECH" && item.iframeUrl === "casino-qtech") {
            navigateFun(item.iframeUrl);
        } else if (activeCasinoProvider === "WACS" && item.iframeUrl === "casino-qtech") {
            navigateFun("casino-category");
        } else if (
            activeCasinoProvider === "WACS" ||
            (activeCasinoProvider === "QTECH" && item.iframeUrl === "casino?data=slots")
        ) {
            navigateFun("casino-category");
        } else {
            navigateFun(item.iframeUrl);
        }
    };

    return (
        <div className={isTabOrMobile ? "game-item-container-mob" : "game-item-container"}>
            <div className={"game-slider-header"}>
                <ComponentHeading title={heading} />
            </div>

            <div className="game-slider-wrapper">
                {Array.isArray(data) &&
                    data.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <LazyLoadImage
                                key={index}
                                className="game-item-image"
                                src={item.imagePath}
                                alt="Fairbets"
                                onClick={(e) => handleNavigateFunc(item)}
                            />
                        );
                    })}
            </div>

            {/*
            <SecondaryCarousel
                data={data}
                noModules={false}
                children={renderItem}
                slidesPerGroup={1}
            /> */}
        </div>
    );
}

export default GameSlider;
