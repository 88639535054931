import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from '../../Contexts/Language';
import { User_Context } from '../../Contexts/User';
import { User_APIs } from '../../API/User_APIs';
import Logout_User from '../../Utilities/Logout';
import useOutsideAlerter from '../../Hooks/OutsideClickHandler';
import { getMessaging, deleteToken, isSupported } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from '../../Configuration/Firebase';

// Assets
import { ReactComponent as Chat } from "../../images/profile-images/messenger.svg";
import { ReactComponent as Mail } from "../../images/profile-images/mail.svg";

// Components
import GeneralButton from '../Buttons/GeneralButton';

function ProfileFooter({ navigateToChat }) {
    const cookies = new Cookies();
    const navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    let messaging =  null;

    const helpBoxRef = useRef(null);

    const { User, Set_User } = useContext(User_Context);
    const { Language, Set_Language } = useContext(Language_Context);

    const [showHelpOption, setShowHelpOption] = useState(false)

    const closeHelpBox = () => {
        setShowHelpOption(false)
    }

    useEffect(() => {
        if(app){
            isSupported().then(res => {
                messaging = getMessaging(app)
            });
        }
    }, [app])

    const logout = () => {
        let data = {
            id: User.data._id,
        };
        User_APIs.Log_Out(data, User.token)
            .then((response) => {
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    Logout_User(Set_User)
                    navigate("/")
                }
            });
    }

    const deleteFirebaseToken = () => {
        if(messaging){
            deleteToken(messaging, { vapidKey: 'BNw5X5Cq68O_tEY_t8d5x2t1dK6dQg91sqKYPOc364yPlHh4aIV6WLPIhrF-RmhLCnbBW_qyUYtqnAYKedrsJkQ' }).then((resposne) => {
                if (resposne) {
                    // console.log(resposne)
                } else {
                    // ...
                }
            }).catch((err) => {
                // console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        }
    }

    useOutsideAlerter(helpBoxRef, closeHelpBox);

    return (
        <div className="MobileFooter_mobileFooter1 pro-footer">
            <div className="MobileFooter_footerItem1 ">
                <div className="footera">
                    <span
                        onClick={() => navigate('/feedback')}
                        className="MobileFooter_label1"
                        style={{ cursor: 'pointer' }}
                    >
                        {Translation_Table.feedback[Language]}
                    </span>
                </div>
                <div
                    className="profile-help-button"
                    // onClick={() => window.location.href("mailto:support@fairbets.co")}
                    onClick={() => setShowHelpOption(true)}
                >
                    <span className="MobileFooter_label1" style={{ cursor: 'pointer' }}>
                        {Translation_Table.help[Language]}
                    </span>
                </div>
            </div>

            {showHelpOption &&
                <div className='help-hover-box' ref={helpBoxRef}>
                    <div className='help-box-icon-container'>
                        <Mail width={18} style={{ marginInlineEnd: '5px' }} />
                        <span className="MobileFooter_label1-alt" style={{ cursor: 'pointer' }} onClick={() => window.location.replace("mailto:support@fairbets.co")}>
                            Email Us
                        </span>
                    </div>

                    <div className='mobile-footer-divider' />

                    <div className='help-box-icon-container'>
                        <Chat width={18} style={{ marginInlineEnd: '5px' }} />
                        <span className="MobileFooter_label1-alt" style={{ cursor: 'pointer' }} onClick={() => navigateToChat()}>
                            Live Chat
                        </span>
                    </div>

                </div>
            }

            <GeneralButton
                showText={true}
                onClick={(e) => {
                    cookies.remove("jwt-authorization");
                    cookies.remove("userData");
                    Set_User({ token: '', data: {} })
                    logout()
                    deleteFirebaseToken();
                    navigate('/')
                }}
                showSmall={true}
                text={Translation_Table.logout[Language]}
            />
        </div>
    )
}

export default ProfileFooter;