import React from 'react'
import NoDataImg from "../../Assets/Images/nodata.png"
import { useNavigate } from "react-router-dom";
import GeneralButton from '../Buttons/GeneralButton';
import "./PageNotFound.css"


function PageNotFound() {
    const navigate = useNavigate();
    return (
        <>
            <div className="container d-flex justify-content-center align-items-center min-height">
                <div className="text-center">
                    <img className='mb-2' src={NoDataImg} alt="No Data" />
                    <p className="p-color">There seems to be a problem accessing this game, we apologize for the inconvenience</p>
                    <div className="d-flex justify-content-center mt-4">
                        <GeneralButton
                            showIcon={false}
                            showText={true}
                            text="Go Back"
                            onClick={() => navigate(-1)}
                        >
                            Go Back
                        </GeneralButton>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PageNotFound