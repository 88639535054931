import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { decryptData } from '../Utilities/DecryptData';
import { encryptData } from '../Utilities/EncryptData';

export const ExchangeMarketDataContext = createContext(null);

const ExchangeMarketContext = ({ children }) => { // Correct destructuring here

    const [marketData, setMarketData] = useState(() => {
        const savedMarket = localStorage.getItem('exchange-market');
        return savedMarket ? JSON.parse(savedMarket) : { /* default state values */ };
      });

      useEffect(() => {
        localStorage.setItem('exchange-market', JSON.stringify(marketData));
      }, [marketData]);

    return (
        <ExchangeMarketDataContext.Provider
            value={{ marketData, setMarketData }}>
            {children}
        </ExchangeMarketDataContext.Provider>
    );
};

export default ExchangeMarketContext 
