import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./TopGames.css";
import GamesHeaderMarker from "../GamesHeaderMarker/GamesHeaderMarker";
// Assets
import Thumbnail_Fallback_Image from "../../Assets/Images/Thumbnail_Fallback_Image.webp";

// import assets
import game1 from "../../Assets/topGames/topGame1.png";
import game2 from "../../Assets/topGames/topGame2.png";
import game3 from "../../Assets/topGames/topGame3.png";
import game4 from "../../Assets/topGames/topGame4.png";
import game5 from "../../Assets/topGames/topGame5.png";
import game6 from "../../Assets/topGames/topGame6.png";
import playIcon from "../../Assets/topGames/playIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
const TopGames = ({ data, showfullScreen = false }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
    useEffect(() => {
        console.log(data);
    }, []);

    const handleOnRedirect = (data) => {
        if (location.pathname === "/casino-category-mobile") {
            navigate("/qtech-casino-game-mobile", { state: { gameId: data.gameId } });
            return;
        }
        navigate("/qtech-casino-game", { state: { gameId: data.gameId } });
    };

    return (
        <>
            <div className="top-games-section">
                <GamesHeaderMarker showBtn={false} markerText={"Top Games"} />

                <div
                    className="top-games-container"
                    style={showfullScreen ? { height: "55vh" } : {}}
                >
                    {data?.map((data, index) => {
                        return (
                            <>
                                {/* casino game card ---------------- */}
                                <div
                                    className="top-game-card"
                                    onClick={() => handleOnRedirect(data)}
                                >
                                    <div className="game-image">
                                        <LazyLoadImage
                                            src={
                                                data?.images?.[2]
                                                    ? data?.images?.[2]?.url
                                                    : Thumbnail_Fallback_Image
                                            }
                                            loading="lazy"
                                            className="top-game-img"
                                        />
                                    </div>
                                    <div className="game-details">
                                        <div className="gameLeftDetail">
                                            <div className="gameName">{data?.Name}</div>
                                            <div className="game-sub-title">
                                                Fairbets Exclusive{" "}
                                            </div>
                                        </div>
                                        <div className="playIcon">
                                            <LazyLoadImage src={playIcon} className="playIcon" />
                                        </div>
                                    </div>
                                </div>
                                {/* end of casino game card ------------ */}
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default TopGames;
