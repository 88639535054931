export const Translation_Table = {
  bonus: {
    hi: "बक्शीश",
    ta: "போனஸ்",
    en: "Bonus",
    te: "అదనపు",
  },
  status: {
    hi: "स्थिति",
    ta: "நிலை",
    en: "Status",
    te: "హోదా",
  },
  wacs: {
    hi: "wacs",
    ta: "wacs",
    en: "wacs",
    te: "wacs",
  },
  Ifwithdrawalaccountsof2ormoreIDaresamebothIDswillbeblocked: {
    hi: "यदि 2 या अधिक आईडी वाले निकासी खाते एक ही हैं तो दोनों आईडी ब्लॉक कर दी जाएंगी।",
    ta: "2 அல்லது அதற்கு மேற்பட்ட ஐடியின் திரும்பப் பெறும் கணக்குகள் ஒரே மாதிரியாக இருந்தால், இரண்டு ஐடிகளும் தடுக்கப்படும்.",
    en: "If there are 2 or more withdrawal accounts with the same ID, then all of these accounts will be blocked.",
    te: "2 లేదా అంతకంటే ఎక్కువ ID యొక్క ఉపసంహరణ ఖాతాలు ఒకేలా ఉంటే రెండు IDలు బ్లాక్ చేయబడతాయి.",
  },
  aadharnumber: {
    hi: "आधार नंबर *",
    ta: "ஆதார் எண் *",
    en: "Aadhar Number *",
    te: "ఆధార్ సంఖ్య *",
  },
  bankName: {
    hi: "Bank Name *",
    ta: "Bank Name *",
    en: "Bank Name *",
    te: "Bank Name *",
  },
  english: {
    hi: "अंग्रेज़ी",
    ta: "ஆங்கிலம்",
    en: "English",
    te: "ఆంగ్ల",
  },
  hindi: {
    hi: "हिंदी",
    ta: "இந்தி",
    en: "Hindi",
    te: "హిందీ",
  },
  telugu: {
    hi: "तेलुगु",
    ta: "தெலுங்கு",
    en: "Telugu",
    te: "తెలుగు",
  },
  tamil: {
    hi: "तमिल",
    ta: "தமிழ்",
    en: "Tamil",
    te: "తమిళం",
  },
  Satta_Matka: {
    hi: "सट्टा मटका",
    ta: "சத்தா மட்கா",
    en: "Satta Matka",
    te: "సత్తా మట్కా",
  },
  firstname: {
    hi: "पहला नाम",
    ta: "முதல் பெயர்",
    en: "First Name",
    te: "మొదటి పేరు",
  },
  lastname: {
    hi: "उपनाम",
    ta: "கடைசி பெயர்",
    en: "Last Name",
    te: "చివరి పేరు",
  },
  pleaseentermessage: {
    hi: "कृपया संदेश दर्ज करें",
    ta: "தயவுசெய்து செய்தியை உள்ளிடவும்",
    en: "Please Enter your Feedback Message",
    te: "దయచేసి సందేశాన్ని నమోదు చేయండి",
  },
  pleaseentername: {
    hi: "कृपया नाम दर्ज करें",
    ta: "தயவுசெய்து பெயரை உள்ளிடவும்",
    en: "Please Enter your Name",
    te: "దయచేసి పేరు నమోదు చేయండి",
  },
  pleaseentermobile: {
    hi: "कृपया मोबाइल दर्ज करें",
    ta: "மொபைலை உள்ளிடவும்",
    en: "Please Enter your Mobile",
    te: "దయచేసి మొబైల్‌ని నమోదు చేయండి",
  },
  enteramount: {
    hi: "राशि डालें",
    ta: "தொகையை உள்ளிடவும்",
    en: "Enter an Amount",
    te: "మొత్తాన్ని నమోదు చేయండి",
  },
  passwordLength: {
    hi: "पासवर्ड 8 अंक या अधिक का होना चाहिए",
    ta: "கடவுச்சொல் 8 இலக்கங்கள் அல்லது அதற்கு மேற்பட்டதாக இருக்க வேண்டும்",
    en: "Password must have 9 characters or more",
    te: "పాస్‌వర్డ్ తప్పనిసరిగా 8 అంకెలు లేదా అంతకంటే ఎక్కువ ఉండాలి",
  },
  amount: {
    hi: "मात्रा",
    ta: "தொகை",
    en: "Amount",
    te: "మొత్తం",
  },
  confirmpasswordnotmatched: {
    hi: "पुष्टि करें कि पासवर्ड मेल नहीं खाता",
    ta: "கடவுச்சொல் பொருந்தவில்லை என்பதை உறுதிப்படுத்தவும்",
    en: "Passwords do not match",
    te: "పాస్‌వర్డ్ సరిపోలలేదని నిర్ధారించండి",
  },
  oldpasswordnotmatched: {
    hi: "पुराना पासवर्ड मेल नहीं खा रहा है",
    ta: "பழைய கடவுச்சொல் பொருந்தவில்லை",
    en: "Passwords do not match",
    te: "పాత పాస్‌వర్డ్ సరిపోలలేదు",
  },
  entermin8characterpassword: {
    hi: "न्यूनतम 8 अक्षर का पासवर्ड दर्ज करें",
    ta: "குறைந்தபட்சம் 8 எழுத்து கடவுச்சொல்லை உள்ளிடவும்",
    en: "Enter a password with a minimum of 8 characters",
    te: "కనీసం 8 అక్షరాల పాస్‌వర్డ్‌ను నమోదు చేయండి",
  },
  newpassword: {
    hi: "नया पासवर्ड",
    ta: "புதிய கடவுச்சொல்",
    en: "New Password",
    te: "కొత్త పాస్వర్డ్",
  },
  oldpassword: {
    hi: "पुराना पासवर्ड",
    ta: "பழைய கடவுச்சொல்",
    en: "Old Password",
    te: "పాత పాస్వర్డ్",
  },
  close: {
    hi: "बंद करना",
    ta: "நெருக்கமான",
    en: "Close",
    te: "దగ్గరగా",
  },
  apply: {
    hi: "आवेदन करना",
    ta: "விண்ணப்பிக்க",
    en: "Apply",
    te: "దరఖాస్తు",
  },
  game: {
    hi: "खेल",
    ta: "விளையாட்டு",
    en: "Game",
    te: "ఆట",
  },
  betstatus: {
    hi: "शर्त की स्थिति",
    ta: "பந்தயம் நிலை",
    en: "Bet Status",
    te: "పందెం స్థితి",
  },
  end: {
    hi: "अंत",
    ta: "முடிவு",
    en: "End",
    te: "ముగింపు",
  },
  start: {
    hi: "शुरू",
    ta: "தொடங்கு",
    en: "Start",
    te: "ప్రారంభించండి",
  },
  nodata: {
    hi: "कोई डेटा नहीं",
    ta: "தகவல் இல்லை",
    en: "No Data Available",
    te: "సమాచారం లేదు",
  },
  pending: {
    hi: "लंबित",
    ta: "நிலுவையில் உள்ளது",
    en: "Pending",
    te: "పెండింగ్‌లో ఉంది",
  },
  completed: {
    hi: "पुरा होना",
    ta: "நிறைவு",
    en: "Completed",
    te: "పూర్తయింది",
  },
  mybets: {
    hi: "मेरे दांव",
    ta: "என் பந்தயம்",
    en: "My Bets",
    te: "నా పందెం",
  },
  wrongpassword: {
    hi: "गलत पासवर्ड",
    ta: "தவறான கடவுச்சொல்",
    en: "Wrong Password",
    te: "సరియినది కాని రహస్య పదము",
  },
  mobiledonotexists: {
    hi: "मोबाइल मौजूद नहीं है",
    ta: "மொபைல் இல்லை",
    en: "This mobile number does not exist",
    te: "మొబైల్ ఉనికిలో లేదు",
  },
  entervalidemailormobile: {
    hi: "वैध ईमेल या मोबाइल दर्ज करें",
    ta: "செல்லுபடியாகும் மின்னஞ்சல் அல்லது மொபைலை உள்ளிடவும்",
    en: "Enter a valid email or mobile number",
    te: "చెల్లుబాటు అయ్యే ఇమెయిల్ లేదా మొబైల్ నమోదు చేయండి",
  },
  DonotputwithdrawalrequestwithoutdepositingsuchrequestWillbecategorisedassuspiciousactivities:
  {
    hi: "बिना जमा किए निकासी का अनुरोध न करें, ऐसे अनुरोध को संदिग्ध गतिविधियों की श्रेणी में रखा जाएगा।",
    ta: "அத்தகைய கோரிக்கையை டெபாசிட் செய்யாமல் திரும்பப் பெறும் கோரிக்கையை வைக்க வேண்டாம், சந்தேகத்திற்குரிய செயல்களாக வகைப்படுத்தப்படும்.",
    en: "Do not make a withdrawal request without making a deposit first. Such request will be flagged as a suspicious activity.",
    te: "అటువంటి అభ్యర్థనను డిపాజిట్ చేయకుండా ఉపసంహరణ అభ్యర్థనను ఉంచవద్దు అనుమానాస్పద కార్యకలాపాలుగా వర్గీకరించబడుతుంది.",
  },
  entervalidaadharnumber: {
    hi: "वैध आधार नंबर दर्ज करें",
    ta: "சரியான ஆதார் எண்ணை உள்ளிடவும்",
    en: "Enter a valid Aadhar number",
    te: "చెల్లుబాటు అయ్యే ఆధార్ నంబర్‌ను నమోదు చేయండి",
  },
  Ifbettingpatternof2IDsissamebothwillbeblocked: {
    hi: "यदि दो आईडी का सट्टेबाजी पैटर्न समान है तो दोनों को ब्लॉक कर दिया जाएगा।",
    ta: "2 ஐடிகளின் பந்தயம் ஒரே மாதிரியாக இருந்தால் இரண்டும் தடுக்கப்படும்.",
    en: "If 2 or more users have similar betting patterns, their accounts will be blocked.",
    te: "2 IDల బెట్టింగ్ నమూనా ఒకేలా ఉంటే రెండూ బ్లాక్ చేయబడతాయి.",
  },
  privacypolicy: {
    hi: "गोपनीयता नीति",
    ta: "தனியுரிமைக் கொள்கை",
    en: "Privacy Policy",
    te: "గోప్యతా విధానం",
  },
  enterotp: {
    hi: "अपना ओटीपी दर्ज करें",
    ta: "உங்கள் OTP ஐ உள்ளிடவும்",
    en: "Enter your OTP",
    te: "మీ OTP ని నమోదు చేయండి",
  },
  IftwoIDshavesameIPbothIDswillbeblocked: {
    hi: "यदि दो आईडी का आईपी समान है तो दोनों आईडी ब्लॉक कर दी जाएंगी",
    ta: "இரண்டு ஐடிகளுக்கு ஒரே ஐபி இருந்தால் இரண்டு ஐடிகளும் தடுக்கப்படும்",
    en: "If 2 ore more IDs have the same IP address, then these IDs will be blocked",
    te: "రెండు IDలు ఒకే IP కలిగి ఉంటే రెండు IDలు బ్లాక్ చేయబడతాయి",
  },
  AccountHolderName: {
    hi: "खाता धारक का नाम",
    ta: "கணக்கு வைத்திருப்பவரின் பெயர்",
    en: "Account Holder Name",
    te: "ఖాతాదారుని పేరు",
  },
  Bonusshouldbewagered55xbeforeyoucanwithdrawit: {
    hi: "इससे पहले कि आप इसे वापस ले सकें, बोनस पर 5x का दांव लगाया जाना चाहिए।",
    ta: "போனஸ் திரும்பப் பெறுவதற்கு முன் @5x பந்தயம் கட்டப்பட வேண்டும்.",
    en: "Bonus should be wagered @5x before you can withdraw",
    te: "మీరు ఉపసంహరించుకునే ముందు బోనస్ @5x పందెం వేయాలి.",
  },
  ifscCode: {
    hi: "आईएफएससी कोड",
    ta: "IFSC குறியீடு",
    en: "IFSC",
    te: "IFSC కోడ్",
  },
  Tochangethewithdrawalaccountdetailspleasecontactsupport: {
    hi: "निकासी खाते का विवरण बदलने के लिए कृपया सहायता से संपर्क करें।",
    ta: "திரும்பப் பெறும் கணக்கு விவரங்களை மாற்ற, ஆதரவைத் தொடர்பு கொள்ளவும்.",
    en: "To change your withdrawal account details, please contact customer support",
    te: "ఉపసంహరణ ఖాతా వివరాలను మార్చడానికి దయచేసి మద్దతును సంప్రదించండి.",
  },
  EnterYourOTP: {
    hi: "अपना ओटीपी दर्ज करें",
    ta: "உங்கள் OTP ஐ உள்ளிடவும்",
    en: "Enter your OTP",
    te: "మీ OTPని నమోదు చేయండి",
  },
  entervalidifsc: {
    hi: "वैध ifsc दर्ज करें",
    ta: "செல்லுபடியாகும் ifsc ஐ உள்ளிடவும்",
    en: "Enter valid IFSC",
  },
  Iamoflegalage18togambleandIacceptthe: {
    hi: "जुआ खेलने के लिए मेरी कानूनी उम्र 18+ है और मैं इसे स्वीकार करता हूं",
    ta: "நான் சூதாட்ட சட்டப்பூர்வ வயது 18+ மற்றும் நான் ஏற்கிறேன்",
    en: "I am of legal age (18+) to gamble and I accept the",
    te: "నేను జూదం ఆడటానికి చట్టపరమైన వయస్సు 18+ మరియు నేను అంగీకరిస్తున్నాను",
  },
  alreadyhaveaccount: {
    hi: "पहले से ही खाता है ? लॉग इन करें",
    ta: "ஏற்கனவே கணக்கு உள்ளதா? உள்நுழைய",
    en: "Already have an account? Log In",
    te: "ఇప్పటికే ఖాతా ఉందా? ప్రవేశించండి",
  },
  emailmobile: {
    hi: "ईमेल / मोबाइल",
    ta: "மின்னஞ்சல் / மொபைல்",
    en: "Email/Mobile",
    te: "ఇమెయిల్ / మొబైల్",
  },
  confirmPassword: {
    hi: "पासवर्ड की पुष्टि कीजिये",
    ta: "கடவுச்சொல்லை உறுதிப்படுத்தவும்",
    en: "Confirm Password",
    te: "పాస్వర్డ్ను నిర్ధారించండి",
  },
  AccountNo: {
    hi: "खाता नंबर",
    ta: "கணக்கு எண்",
    en: "Bank Account Number",
    te: "ఖాతా సంఖ్య",
  },
  TermsandConditions: {
    hi: "नियम और शर्तें",
    ta: "விதிமுறைகள் மற்றும் நிபந்தனைகள்",
    en: "Terms and Conditions",
    te: "నిబంధనలు మరియు షరతులు",
  },
  entervalidmobile: {
    hi: "वैध मोबाइल दर्ज करें",
    ta: "செல்லுபடியாகும் மொபைலை உள்ளிடவும்",
    en: "Enter valid mobile",
    te: "చెల్లుబాటు అయ్యే మొబైల్‌ని నమోదు చేయండి",
  },
  confirmpasswordmismatch: {
    hi: "पासवर्ड बेमेल की पुष्टि करें",
    ta: "கடவுச்சொல் பொருத்தமின்மையை உறுதிப்படுத்தவும்",
    en: "Passwords do not match",
    te: "పాస్‌వర్డ్ సరిపోలని నిర్ధారించండి",
  },
  entervalidemail: {
    hi: "मान्य ईमेल दर्ज करें",
    ta: "சரியான மின்னஞ்சலை உள்ளிடவும்",
    en: "Enter valid email",
    te: "చెల్లుబాటు అయ్యే ఇమెయిల్‌ని నమోదు చేయండి",
  },
  enterValid8DigitPassword: {
    hi: "न्यूनतम 8 अक्षर का पासवर्ड दर्ज करें",
    ta: "குறைந்தபட்சம் 8 எழுத்து கடவுச்சொல்லை உள்ளிடவும்",
    en: "Enter a password with a minimum of 8 characters",
    te: "కనీసం 8 అక్షరాల పాస్‌వర్డ్‌ను నమోదు చేయండి",
  },
  emailalreadyexist: {
    hi: "ईमेल पहले से मौजूद है",
    ta: "மின்னஞ்சல் ஏற்கனவே உள்ளது",
    en: "This email already exists",
    te: "ఇమెయిల్ ఇప్పటికే ఉంది",
  },
  login: {
    hi: "प्रवेश",
    ta: "உள்நுழைய",
    en: "Login",
    te: "ప్రవేశించండి",
  },
  logout: {
    hi: "बहार निकले",
    ta: "வெளியே வா",
    en: "Log Out",
    te: "బయటికి రా",
  },
  register: {
    hi: "पंजीकरण",
    ta: "பதிவு",
    en: "Register",
    te: "నమోదు",
  },
  IFSC: {
    hi: "आईएफएससी",
    ta: "आईएफएससी",
    en: "IFSC",
    te: "IFSC",
  },
  enterFullName: {
    hi: "पूरा नाम दर्ज करें",
    ta: "முழு பெயரை உள்ளிடவும்",
    en: "Enter Full Name",
    te: "పూర్తి పేరును నమోదు చేయండి",
  },
  email: {
    hi: "ईमेल",
    ta: "மின்னஞ்சல்",
    en: "Email",
    te: "ఇమెయిల్",
  },
  selectCity: {
    hi: "शहर चुनें",
    ta: "நகரத்தைத் தேர்ந்தெடுக்கவும்",
    en: "Select City",
  },
  selectState: {
    hi: "राज्य चुनें",
    ta: "மாநிலத்தை தேர்ந்தெடு",
    en: "Select State",
    te: "రాష్ట్రం ఎంచుకోండి",
  },
  password: {
    hi: "पासवर्ड",
    ta: "கடவுச்சொல்",
    en: "Password",
    te: "పాస్వర్డ్",
  },
  mobileAlreadyExist: {
    hi: "मोबाइल पहले से मौजूद है",
    ta: "மொபைல் ஏற்கனவே உள்ளது",
    en: "This mobile number already exists",
    te: "మొబైల్ ఇప్పటికే ఉంది",
  },
  forgetPassword: {
    hi: "पासवर्ड भूल गए",
    ta: "கடவுச்சொல்லை மறந்து",
    en: "Forgot Password?",
    te: "పాస్‌వర్డ్‌ను మరచిపోండి",
  },
  home: {
    hi: "घर",
    ta: "வீடு",
    en: "Home",
    te: "ఇల్లు",
  },
  ezugi: {
    hi: "एजुगी",
    ta: "எசுகி",
    en: "Ezugi",
    te: "ఎజుగి",
  },
  evolution: {
    hi: "एवोल्यूशन",
    ta: "பரிணாமம்",
    en: "Evolution",
    te: "ఎవల్యూషన్",
  },
  support: {
    hi: "सहायता",
    ta: "ஆதரவு",
    en: "Support",
    te: "మద్దతు",
  },
  topGames: {
    hi: "शीर्ष खेल",
    ta: "மேல்  விளையாட்டுகள்",
    en: "Top Games",
    te: "అగ్ర ఆటలు",
  },
  virtuals: {
    hi: "वर्चुअल",
    ta: "மெய்நிகர்கள்",
    en: "Virtuals",
    te: "వర్చువల్స్",
  },
  blackJack: {
    hi: "ब्लैकजैक",
    ta: "கருப்பு ஜாக்",
    en: "Black Jack",
    te: "బ్లాక్ జాక్",
  },
  roulette: {
    hi: "रॉलेट",
    ta: "சில்லி",
    en: "Roulette",
    te: "రాలెట్",
  },
  andarBahar: {
    hi: "अंदर बहार",
    ta: "அந்தர் பஹார்",
    en: "Andar Bahar",
    te: "అందర్ బహార్",
  },
  dragonTiger: {
    hi: "ड्रैगन टाइगर",
    ta: "டிராகன் டைகர்",
    en: "Dragon Tiger",
    te: "డ్రాగన్ టైగర్",
  },
  baccaratPoker: {
    hi: "बैकारेट पोकर",
    ta: "பேக்கரட் போக்கர்",
    en: "Baccarat Poker",
    te: "బక్కరట్ పోకర్",
  },
  selectLanguage: {
    hi: "भाषा चुने",
    ta: "மொழியை தேர்ந்தெடுங்கள்",
    en: "Select Language",
    te: "భాషను ఎంచుకోండి",
  },
  feedback: {
    hi: "प्रतिक्रिया",
    ta: "பின்னூட்டம்",
    en: "Feedback",
    te: "ప్రతిస్పందన",
  },
  whatsapp: {
    hi: "व्हाट्सएप",
    ta: "வாட்ஸப்",
    en: "WhatsApp",
    te: "వాట్సాప్",
  },
  telegram: {
    hi: "टेलीग्राम",
    ta: "தந்தி",
    en: "Telegram",
    te: "టెలిగ్రామ్",
  },
  selectstate: {
    hi: "राज्य चुनें",
    ta: "மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
    en: "Select state",
    te: "రాష్ట్రాన్ని ఎంచుకోండి",
  },
  twitter: {
    hi: "ट्विटर",
    ta: "ட்விட்டர்",
    en: "Twitter",
    te: "ట్విట్టర్",
  },
  instagram: {
    hi: "इंस्टाग्राम",
    ta: "படவரி",
    en: "Instagram",
    te: "ఇన్స్టాగ్రామ్",
  },
  playGame: {
    hi: "खेल खेले",
    ta: "விளையாட்டு விளையாடு",
    en: "Play Game",
    te: "ప్లే గేమ్",
  },
  WithdrawalAmount: {
    hi: "निकाली गयी राशि",
    ta: "திரும்பப் பெறும் தொகை",
    en: "Withdrawal Amount",
    te: "ఉపసంహరణ మొత్",
  },
  betslip: {
    hi: "शर्त पर्ची",
    ta: "பந்தயம் சீட்டு",
    en: "Betslip",
    te: "పందెం స్లిప్",
  },
  openBets: {
    hi: "दांव खोलें",
    ta: "திறந்த சவால்",
    en: "Open Bets",
    te: "ఓపెన్ పందెం",
  },
  editStakes: {
    hi: "दांव संपादित करें",
    ta: "பங்குகளை திருத்தவும்",
    en: "Edit Stakes",
    te: "వాటాలను సవరించండి",
  },
  myAccount: {
    hi: "मेरा खाता",
    ta: "என் கணக்கு",
    en: "My Account",
    te: "నా ఖాతా",
  },
  deposit: {
    hi: "जमा करे",
    ta: "வைப்பு",
    en: "Deposit",
    te: "డిపాజిట్",
  },
  withdrawal: {
    hi: "निकाले",
    ta: "திரும்பப் பெறுதல்",
    en: "Withdrawal",
    te: "ఉపసంహరణ",
  },
  withdrawalRequest: {
    hi: "निकलने का अनुरोध",
    ta: "திரும்பப் பெறுதல் கோரிக்கைகள்",
    en: "Withdrawal Request",
    te: "ఉపసంహరణ అభ్యర్థన",
  },
  kyc: {
    hi: "केवाईसी",
    ta: "உங்கள் வாடிக்கையாளரை அறிந்து கொள்ளுங்கள்",
    en: "KYC",
    te: "కేవైసీ",
  },
  betHistory: {
    hi: "शर्त इतिहास",
    ta: "பந்தயம் வரலாறு",
    en: "Bet History",
    te: "పందెం చరిత్ర",
  },
  smBetHistory: {
    hi: "एसएम बेट इतिहास",
    ta: "எஸ்.எம் பந்தயம் வரலாறு",
    en: "Satta Matka Bet History",
    te: "ఎస్ఎం బెట్ హిస్టరీ",
  },
  accountStatement: {
    hi: "खाता विवरण",
    ta: "கணக்கு அறிக்கை",
    en: "Account Statement",
    te: "ఖాతా ప్రకటన",
  },
  rewards: {
    hi: "पुरस्कार",
    ta: "வெகுமதிகள்",
    en: "Rewards",
    te: "పురస్కారాలు",
  },
  changePassword: {
    hi: "पासवर्ड बदलें",
    ta: "கடவுச்சொல்லை மாற்று",
    en: "Change Password",
    te: "పాస్‌వర్డ్ మార్చండి",
  },
  referEarn: {
    hi: "भेजे और कमाएँ",
    ta: "அனுப்பி சம்பாதிக்க",
    en: "Refer & Earn",
  },
  help: {
    hi: "मदद",
    ta: "உதவி",
    en: "Help",
    te: "సహాయం",
  },
  BankAccountNumber: {
    hi: "बैंक खाता संख्या",
    ta: "வங்கி கணக்கு எண்",
    en: "Bank Account Number",
    te: "బ్యాంకు ఖాతా సంఖ్య",
  },
  exchange: {
    hi: "अदला-बदली",
    ta: "பரிமாற்றம்",
    en: "Exchange",
    te: "మార్పిడి",
  },
  casino: {
    hi: "कैसीनो",
    ta: "சூதாட்ட விடுதி",
    en: "Casino",
    te: "క్యాసినో",
  },
  lottery: {
    hi: "लॉटरी",
    ta: "லாட்டரி",
    en: "Lottery",
    te: "లాటరీ",
  },
  satta: {
    hi: "सट्टा",
    ta: "சத்தா",
    en: "Satta",
    te: "సత్తా",
  },
  slots: {
    hi: "स्लॉट",
    ta: "ஸ்லாட்",
    en: "Slots",
    te: "స్లాట్",
  },
  baccarat: {
    hi: "बैकारेट",
    ta: "பேக்கரட்",
    en: "Baccarat",
    te: "బక్కరాట్",
  },
  poker: {
    hi: "पोकर",
    ta: "போக்கர்",
    en: "Poker",
    te: "పేకాట",
  },
  liveCasino: {
    hi: "लाइव कैसीनो",
    ta: "நேரடி கேசினோ",
    en: "Live Casino",
    te: "ప్రత్యక్ష క్యాసినో",
  },
  submit: {
    hi: "जमा करो",
    ta: "சமர்ப்பிக்கவும்",
    en: "Submit",
    te: "సమర్పించండి",
  },
  entervalidaccountnumber: {
    hi: "वैध खाता संख्या दर्ज करें",
    ta: "சரியான கணக்கு எண்ணை உள்ளிடவும்",
    en: "Enter a valid account number",
    te: "చెల్లుబాటు అయ్యే ఖాతా సంఖ్యను నమోదు చేయండి",
  },
  name: {
    hi: "नाम",
    ta: "பெயர்",
    en: "Name",
    te: "పేరు",
  },
  username: {
    hi: "उपयोगकर्ता नाम",
    ta: "பயனர் பெயர்",
    en: "User Name",
    te: "వినియోగదారు పేరు",
  },
  mobileNumber: {
    hi: "मोबाइल नंबर",
    ta: "கைபேசி எண்",
    en: "Mobile Number",
    te: "మొబైల్ నంబర్",
  },
  message: {
    hi: "संदेश",
    ta: "செய்தி",
    en: "Message",
    te: "సందేశం",
  },
  regularBazar: {
    hi: "नियमित बाज़ार",
    ta: "வழக்கமான சந்தை",
    en: "Regular Bazar",
    te: "రెగ్యులర్ బజార్",
  },
  kingBazar: {
    hi: "किंगबाज़ार",
    ta: "கிங் பஜார்",
    en: "King Bazar",
    te: "కింగ్ బజార్",
  },
  starlineBazar: {
    hi: "स्टारलाइनबाजार",
    ta: "ஸ்டார்லைன் பஜார்",
    en: "Starline Bazar",
    te: "స్టార్లైన్ బజార్",
  },
  instantWorli: {
    hi: "इंस्टेंटवर्ली",
    ta: "உடனடி வொர்லி",
    en: "Instant Worli",
    te: "ఇన్స్టంట్ వొర్లి",
  },
  Download_App:
  {
    hi: "ऐप डाउनलोड करें",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "Download App",
    te: "అనువర్తనాన్ని డౌన్లోడ్ చేయండి",
  },
  bonusFirstTerms1:
  {
    hi: "1) 300-5000 जमा बोनस कुल बोनस 300",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "1) 300-5000 Deposit Bonus Total Bonus 300",
    te: "1) 300-5000 డిపాజిట్ బోనస్ మొత్తం బోనస్ 300",
  },
  bonusFirstTerms2:
  {
    hi: "ऐप डाउनलोड करें",
    ta: "2) 5000 जमा पर 300 + 5000 पर 3% बोनस",
    en: "2) Bonus on 5000 deposit 300 + 3% on 5000 ",
    te: "2) 5000 డిపాజిట్ పైన బోనస్ 300 + 5000 పైన 3% ",
  },
  bonusFirstTerms3:
  {
    hi: "3) 5000 से ऊपर रिफिल पर 3% बोनस",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "3) 3% bonus on refill above 5000  ",
    te: "3) 5000 కంటే ఎక్కువ రీఫిల్ చేస్తే 3% బోనస్",
  },
  bonusFirstTerms4:
  {
    hi: "4) रेफरल उपयोगकर्ताओं को 300-5000 जमा पर 150 मिलते हैं।",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "4) Referral users get 150 on 300-5000 deposit.  ",
    te: "4) రెఫరల్ యూజర్లు 300-5000 డిపాజిట్పై 150 పొందుతారు",
  },
  bonusFirstTerms5:
  {
    hi: "5) रेफरल उपयोगकर्ताओं को प्रति उपयोगकर्ता 5000 से अधिक जमा पर 1% मिलता है।",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "5) Referral users get 1% on deposit above 5000 per user. ",
    te: "5) రెఫరల్ వినియోగదారులు ఒక్కో వినియోగదారుకు 5000 కంటే ఎక్కువ డిపాజిట్పై 1% పొందుతారు",
  },
  bonusFirstTerms6:
  {
    hi: "6) रेफ़रल उपयोगकर्ता को उपयोगकर्ता स्थिति से 5000 रीफिल पर 1% मिलता है",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "6) Referral user get 1% on 5000 refill from user status ",
    te: "6) రిఫరల్ యూజర్ యూజర్ స్టేటస్ నుండి 5000 రీఫిల్పై 1% పొందండి",
  },
  rules:
  {
    hi: "नियम",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "Rules: ",
    te: "నియమాలు:",
  },
  rules_1:
  {
    hi: "1) उपयोगकर्ता बोनस तभी ट्रांसफर कर सकता है जब बैलेंस 100 या उससे कम हो और कोई बैलेंस न बचा हो।",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "1) User can transfer bonus only when balance is 100 or less and no balance left. ",
    te: "1) బ్యాలెన్స్ 100 లేదా అంతకంటే తక్కువ ఉన్నప్పుడు మరియు బ్యాలెన్స్ మిగిలి లేనప్పుడు మాత్రమే వినియోగదారు బోనస్ని బదిలీ చేయగలరు.",
  },
  rules_2:
  {
    hi: "2) यदि निकासी अनुरोध सबमिट करते समय बोनस बोनस वॉलेट में है तो बोनस रद्द कर दिया जाएगा।",
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "2) The bonus will be canceled if the bonus is in the bonus wallet when they submit the withdrawal request",
    te: "2) వారు ఉపసంహరణ అభ్యర్థనను సమర్పించినప్పుడు బోనస్ బోనస్ వాలెట్లో ఉన్నట్లయితే బోనస్ రద్దు చేయబడుతుంది",
  },
  bonusSecondTerms1:
  {
    hi: `1) प्रत्येक रेफरल खाता प्रत्येक रेफरल:प्रत्येक खिलाड़ी को केवल एक खाता बनाने की अनुमति है। एकाधिक खाते बनाने से बोनस रद्द हो जाएगा।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "1) One account per referral : Each player can create only one account. Creating multiple accounts will result in cancellation of bonuses.",
    te: "1) 300-5000 డిపాజిట్ బోనస్ మొత్తం బోనస్ 300",
  },
  bonusSecondTerms2:
  {
    hi: `2) वैध केवाईसी दस्तावेज़:बोनस के लिए पात्र होने के लिए प्रत्येक खिलाड़ी को वैध अपने ग्राहक को जानिए (केवाईसी) दस्तावेज जमा करने होंगे। अधूरे या नकली दस्तावेज़ों के उपयोग से बोनस रद्द हो जाएगा।`,
    ta: "2) 5000 जमा पर 300 + 5000 पर 3% बोनस",
    en: "2) Valid KYC documents : Every player must submit valid Know Your Customer (KYC) documents to receive bonuses. Use of incomplete or falsified documents will result in cancellation of bonus.",
    te: "2) 5000 డిపాజిట్ పైన బోనస్ 300 + 5000 పైన 3% ",
  },
  bonusSecondTerms3:
  {
    hi: `3) न्यूनतम जमा आवश्यकता:रेफरल या जमा बोनस के लिए अर्हता प्राप्त करने के लिए न्यूनतम जमा राशि पूरी होनी चाहिए। इस राशि को पूरा करने में विफलता के परिणामस्वरूप बोनस अस्वीकार कर दिया जाएगा।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "3) Minimum deposit : Minimum deposit amount must be met to receive referral or deposit bonuses. If this amount is not exceeded, the bonus will be rejected.",
    te: "3) 5000 కంటే ఎక్కువ రీఫిల్ చేస్తే 3% బోనస్",
  },
  bonusSecondTerms4:
  {
    hi: `4) निष्पक्ष खेल नीति:धोखाधड़ी, या किसी भी अनुचित व्यवहार के खिलाफ बोनस रद्दीकरण और खाता निलंबन सहित सख्त कार्रवाई की जाएगी।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "4) Fairbets Policy : Fraud, fraud or unfair practices will be strictly dealt with, including bonus cancellation and account suspension.",
    te: "4) రెఫరల్ యూజర్లు 300-5000 డిపాజిట్పై 150 పొందుతారు",
  },
  bonusSecondTerms5:
  {
    hi: `5) बोनस दुरुपयोग खंड:यदि कोई खिलाड़ी बोनस का दुरुपयोग करते हुए पकड़ा जाता है, तो उनका खाता निलंबित कर दिया जाएगा, और वे भविष्य के बोनस के लिए पात्र नहीं होंगे।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "5 ) Bonus Abuse Clause : if a player is caught abusing bonuses, his account will be suspended and he will not be able to receive bonuses in the future.",
    te: "5) రెఫరల్ వినియోగదారులు ఒక్కో వినియోగదారుకు 5000 కంటే ఎక్కువ డిపాజిట్పై 1% పొందుతారు",
  },
  bonusSecondTerms6:
  {
    hi: `6) नियमित ऑडिट:हम नियमित रूप से आपके खाते की जाँच करेंगे। यदि कोई खिलाड़ी संदिग्ध गतिविधियों में शामिल होता है तो हम उसका खाता स्थायी रूप से समाप्त कर देंगे।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "6) Regular checks : We will check your account regularly. If any player engages in suspicious activity, we will permanently close their account.",
    te: "6) రిఫరల్ యూజర్ యూజర్ స్టేటస్ నుండి 5000 రీఫిల్పై 1% పొందండి",
  },
  bonusSecondNoteTerms:
  {
    hi: `नोट: प्रत्येक खिलाड़ी को उपरोक्त सभी नियमों और शर्तों का सख्ती से पालन करना होगा।`,
    ta: "பயன்பாட்டைப் பதிவிறக்கவும்",
    en: "Note. All players must strictly follow all the above rules and conditions..",
    te: "6) రిఫరల్ యూజర్ యూజర్ స్టేటస్ నుండి 5000 రీఫిల్పై 1% పొందండి",
  },
};
