import React, { useContext, useMemo } from "react";
import "./Game_Card.css";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { User_Context } from "../../Contexts/User";
import Thumbnail_Fallback_Image from "../../Assets/Images/Thumbnail_Fallback_Image.webp";
import { WACS_Endpoint } from "../../Configuration/Settings";
import { WACS_Payment_Modes } from "../../Configuration/Enums/WACS_Payment_Modes";
import {SessionHelper} from '../../Utilities/SessionHelper';
import { Providers_Enums } from "../../Configuration/Enums/Providers";


const Game_Card = memo(({ Game_Item, Page, casino }) => {
  // let image = Game_Item.images.filter((item)=> item.type === "logo-square")
  const Navigate = useNavigate();
  const { User } = useContext(User_Context);
  const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();
  const isExcludedGameCode = [
    "EZUGI-UltimateAndarBahar_228100",
    "KM-Bai_Buu",
    "KM-Bai_Cao",
    "KM-Belangkai_2",
    "KM-Dragon_Tiger_2",
    "KM-European_Roulette",
    "KM-Fan_Tan_2",
    "KM-Fan_Tan_Classic",
    "KM-Fish_Prawn_Crab_2",
    "KM-Fruit_Roulette",
    "KM-Max_Keno",
    "KM-Poker_Roulette",
    "KM-Sicbo",
    "KM-Tai_Xiu",
    "KM-Thai_Fish_Prawn_Crab",
    "KM-Thai_Hi_Lo_2",
    "KM-Triple_Chance",
    "KM-Viet_Fish_Prawn_Crab",
    "KM-Xoc_Dia",
    "KM-Xoc_Dia_Lightning",
  ];

  const navigationUrl = useMemo(() => activeCasinoProvider === Providers_Enums.CAPITAL_QTECH ? '/casino-game' : '/casino-game-mobile', [activeCasinoProvider]);

  const isExcluded = isExcludedGameCode.includes(Game_Item.Game_Code);
  if (isExcluded) {
    return null;
  }
  return casino ? (
    <div
      className={
        Page === "Casino" ? "col-4 col-md-2 crv-csn" : "col-md-3 col-6 crvimg"
      }
      onClick={() =>
        Navigate(navigationUrl, {
          state: {
            Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${Game_Item.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
          },
        })
      }
    >
      <div className="member-img">
        <img
          alt={Game_Item.Name}
          className={casino === true ? "carousel-image-qtech" : "img-fluid indian-card-image"}
          src={
            Game_Item.Thumbnail ? Game_Item.Thumbnail : Thumbnail_Fallback_Image
          }
          loading="lazy"
        ></img>
      </div>
    </div>
  ) : (
    <div className={"col-6 col-md-2 crv-csn"}>
      <div
        className="member-img"
        onClick={() =>
          Navigate(navigationUrl, {
            state: {
              Game_URI: `${WACS_Endpoint}?token=${User.data._id}&pn=${process.env.REACT_APP_PN}&lang=en&game=${Game_Item.Game_Code}&type=${WACS_Payment_Modes.Charged}`,
            },
          })
        }
      >
        <img
          alt={Game_Item.Name}
          className="carousel-image-qtech indian-card-image"
          src={
            Game_Item.Thumbnail ? Game_Item.Thumbnail : Thumbnail_Fallback_Image
          }
          loading="lazy"
        ></img>
      </div>
    </div>
  );
});

export default Game_Card;
