import React, { useContext, useState, useEffect } from 'react';
import { Language_Context } from '../../Contexts/Language';
import { User_Context } from '../../Contexts/User';
import { Bonus_Wallet_APIs } from '../../API/Bonus_Wallet_API';
import { decryptData } from '../../Utilities/DecryptData';
import { Box, Grid, Pagination, Stack, Tab, Tabs } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import Logout_User from '../../Utilities/Logout';
import { BonusWalletTypes } from '../../Configuration/Enums/BonusWallterTypes';

// Components
import BonusWalletCard from '../../components/BonusWallet/BonusWalletCard';
import BetHistoryNoData from '../../components/BetHistory/BetHistoryNoData';

// Styles
import '../../components/Profile/Bet-History/bet-history.css'

const itemsPerPage = 100;

function BonusWalletHistory() {
    const { User, Set_User } = useContext(User_Context);
    const { Language } = useContext(Language_Context);
    const navigate = useNavigate();
    const [bonusWalletData, setBonusWalletData] = useState([])
    const [pageNo, setPageNo] = useState(1);

    const [Selected_Tab, Set_Selected_Tab] = useState(BonusWalletTypes.bonusEarnings);

    const handleTabChange = (event, newValue) => {
        Set_Selected_Tab(newValue);
    };

    const getBonusWalletData = async () => {
        try {
            let payload = {
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                filter: {
                    // userId: "65c48323fc52c81899e0f7fc",
                    userId: User.data?._id,
                },

            }
            const response = await Bonus_Wallet_APIs.getBonusWalletHistory(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const history = decryptData(data);
                setBonusWalletData(history.payload.items);
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate("/")
            }
        }
    }

    const getBonusWalletReferralData = async () => {
        try {
            let payload = {
                itemsPerPage: itemsPerPage,
                pageNo: pageNo,
                filter: {
                    userId: User.data?._id,
                }
            }
            const response = await Bonus_Wallet_APIs.getBonusWalletReferralHistory(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const history = decryptData(data);
                setBonusWalletData(history.payload.items);
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate("/")
            }
        }
    }

    useEffect(() => {
        if (Selected_Tab === BonusWalletTypes.bonusEarnings) {
            getBonusWalletData();
        } else {
            getBonusWalletReferralData();
        }

    }, [Selected_Tab]);

    const TabPanel = ({ children, value, index }) =>
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>

    return (
        <div>
            <Box sx={{ width: "100%" }}>
                <Tabs
                    className="bg-tab-bet"
                    value={Selected_Tab}
                    onChange={handleTabChange}
                    centered
                >
                    <Tab className="tab-bet" label={'Bonus Earnings'} />
                    <Tab className="tab-bet" label={'Referral Bonus Earnings'} />
                </Tabs>
                <TabPanel value={'Pending'} index={'Pending'}>
                    <div className="profile-detail dw" id="profilinfo">
                        <div className="profilepg">
                            <Grid container spacing={2}>
                                {bonusWalletData.length > 0 &&
                                    bonusWalletData.map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={12} sm={4}>
                                                <BonusWalletCard
                                                    key={index}
                                                    item={item}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                                {bonusWalletData.length === 0 &&
                                    <BetHistoryNoData />
                                }
                            </Grid>
                        </div>
                    </div>
                </TabPanel>
            </Box>

        </div>
    )
}

export default BonusWalletHistory;