import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { User_Context } from "../../../Contexts/User";
import config from "../../../Utilities/Data_Formatting";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Logout_User from "../../../Utilities/Logout";
import { Payment_APIs } from '../../../API/Payment_APIs';
import { Language_Context } from "../../../Contexts/Language";
import { Translation_Table } from "../../../Configuration/Translation";
import { decryptData } from "../../../Utilities/DecryptData";
import { Bonus_Wallet_APIs } from "../../../API/Bonus_Wallet_API";

// Components
import AccountStatementFilterModal from "../../Modals/AccountStatementFilterModal";
import TransactionCard from "../../Transaction/TransactionCard";
import BonusWalletCard from "../../BonusWallet/BonusWalletCard";

// Styles
import "../Account-Statement/account-statement.css";

function AccountStatement({ showAccountStatementFiler, setShowAccountStatementFilter }) {
	const [selectedTab, setSelectedTab] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const [showData, setShowData] = useState([]);
	const [filterPopup, setFilterPopup] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [transactionStatus, setTransactionStatus] = useState("");
	const { Language, Set_Language } = useContext(Language_Context)
	const [childState, setChildState] = useState(false);

	const [bonusWalletData, setBonusWalletData] = useState([])


	const { User, Set_User } = useContext(User_Context);
	const TabPanel = ({ children, value, index }) => {
		return (
			<div role="tabpanel" hidden={value !== index}>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</div>
		);
	};

	useEffect(() => {
		setFilterPopup(showAccountStatementFiler)
	}, [showAccountStatementFiler]);

	const showFilterPopup = () => {
		setFilterPopup(true);
	};

	const closeFilterPopup = () => {
		setFilterPopup(false);
		setStartDate(new Date(0))
		setEndDate(new Date())
		setTransactionStatus("")
	};

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	const navigate = useNavigate();
	const navigateToProfile = () => {
		navigate("/profile");
	};
	useEffect(() => {
		let data = { userId: User.data._id };

		Payment_APIs.Get_Transactions(data, User.token)
			.then((response) => {
				const data = decryptData(response.data.data);
				setTransactions(data.payload);
				setShowData(data.payload.withdrawals);
			})
			.catch((error) => {
				if (error.response.status === 401) {
					Logout_User(Set_User)
					navigate("/")
				}
			});
	}, [childState]);

	useEffect(() => {
		if (selectedTab === 0) {
			setShowData(transactions.withdrawals)
		} else {
			setShowData(transactions.deposits)
		}
	}, [selectedTab])

	const setData = (arr) => {
		const a = arr.filter((transaction) => {
			if (config.dateTime(transaction.createdOn) >=
				config.dateTime(startDate) &&
				config.dateTime(transaction.updatedOn) <= config.dateTime(endDate) &&
				transaction.status.toLowerCase() === transactionStatus.toLowerCase()

			)
				return true;
		});
		if (a.length === 0) {
			toast.error("No data is available for the selected dates");
			setStartDate(new Date(0))
			setEndDate(new Date())
			setTransactionStatus("")
		} else {
			setShowData(a);
		}
		setFilterPopup(false);
	}

	const filterTransaction = () => {
		if (selectedTab === 0) {
			setData(transactions.withdrawals)
		} else {
			setData(transactions.deposits)
		}
	};

	const handleChildStateChange = (newState) => {
		setChildState(newState);
	};

	const getTransferRequests = async () => {
		try {
			let payload = {
				itemsPerPage: 1000,
				pageNo: 1,
				filter: {
					userId: User.data?._id,
				}

			}

			const response = await Bonus_Wallet_APIs.getTransferRequests(payload, User.token)
			const { data, success } = response.data ? response.data : {}
			if (success) {
				const bonusWallet = decryptData(data);
				setBonusWalletData(bonusWallet.payload.items)
			}
		} catch (error) {
			if (error.response.status === 401) {
				Logout_User(Set_User)
				navigate("/")
			}
		}
	}

	useEffect(() => {
		if (selectedTab === 2) {
			getTransferRequests();
		}
	}, [selectedTab]);

	return (
		<div>
			<Box sx={{ width: "100%" }}>
				<Tabs
					className="bg-tab-bet"
					value={selectedTab}
					onChange={handleTabChange}
					centered
				>
					<Tab
						className="tab-bet-ac"
						label={Translation_Table.withdrawal[Language]}
					/>
					<Tab
						className="tab-bet-ac"
						label={Translation_Table.deposit[Language]}
					/>
					<Tab
						className="tab-bet-ac"
						label={'Bonus Request'}
					/>
				</Tabs>
				<TabPanel value={selectedTab} index={0}>
					<div className="profile-detail dw" id="profilinfo">
						<div className="profilepg">
							<Grid container spacing={2}>
								{transactions.withdrawals?.map((transaction) => (
									<Grid item xs={12} sm={4}>
										<TransactionCard
											key={Math.random()}
											transactions={transaction}
											onChange={handleChildStateChange}
										/>
									</Grid>
								))}
							</Grid>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={selectedTab} index={1}>
					<div className="profile-detail dw" id="profilinfo">
						<div className="profilepg">
							<Grid container spacing={2}>
								{transactions.deposits?.map((transaction) => (
									<Grid item xs={12} sm={4}>
										<TransactionCard
											key={Math.random()}
											transactions={transaction}
										/>
									</Grid>
								))}
							</Grid>
						</div>
					</div>
				</TabPanel>
				<TabPanel value={selectedTab} index={2}>
					<div className="profile-detail dw" id="profilinfo">
						<div className="profilepg">
							<Grid container spacing={2}>
								{bonusWalletData.map((item, index) => (
									<Grid item xs={12} sm={4}>
										<BonusWalletCard
											key={index}
											item={item}
											showButton={true}
										/>
									</Grid>
								))}
							</Grid>
						</div>
					</div>
				</TabPanel>
			</Box>
			{filterPopup && (
				<AccountStatementFilterModal
					startDate={startDate}
					endDate={endDate}
					transactionStatus={transactionStatus}
					onChangeStartDate={(e) => setStartDate(e.target.value)}
					onChangeEndDate={(e) => setEndDate(e.target.value)}
					onChangeSelect={(e) => setTransactionStatus(e.target.value)}
					onClickApply={filterTransaction}
					onClickClose={() => setShowAccountStatementFilter(false)}
				/>
				// <div className="popup3 d-flex align-items-center justify-content-center">
				// 	<div className="popup-content3" style={{ height: "12.5rem" }}>
				// 		<div className="row bet-r">
				// 			<div class="col-sm-3 col-md-5 col-5">
				// 				<label className="label-bet">
				// 					{Translation_Table.start[Language]}
				// 				</label>
				// 				<input
				// 					className="form-control inpt-bet"
				// 					id="fromDate"
				// 					name="fromDate"
				// 					placeholder=""
				// 					type="date"
				// 					value={startDate}
				// 					onChange={(e) => setStartDate(e.target.value)}
				// 				/>
				// 			</div>
				// 			<div class="col-sm-3 col-md-5 col-5">
				// 				<label className="label-bet">
				// 					{Translation_Table.end[Language]}
				// 				</label>
				// 				<input
				// 					className="form-control inpt-bet"
				// 					id="fromDate"
				// 					name="fromDate"
				// 					placeholder=""
				// 					type="date"
				// 					value={endDate}
				// 					onChange={(e) => setEndDate(e.target.value)}
				// 				/>
				// 			</div>

				// 			<div class="col-sm-3 col-md-5 col-5">
				// 				<label className="label-bet">
				// 					{Translation_Table.status[Language]}
				// 				</label>
				// 				<select
				// 					className="form-select inpt-bet"
				// 					value={transactionStatus}
				// 					onChange={(e) => setTransactionStatus(e.target.value)}
				// 				>
				// 					<option value={"Select Status"}>
				// 						Select Status
				// 					</option>
				// 					<option value={"Pending"}>
				// 						Pending
				// 					</option>
				// 					<option value={"Approved"}>
				// 						Approved
				// 					</option>
				// 					<option value={"Rejected"}>
				// 						Rejected
				// 					</option>
				// 					<option value={"Failed"}>
				// 						Failed
				// 					</option>
				// 				</select>
				// 			</div>
				// 			<div class="col-sm-3 col-md-5 col-5"></div>

				// 			<div style={{ marginRight: "57px", marginTop: "15px" }}>
				// 				<Button
				// 					variant="contained"
				// 					className="signbt4"
				// 					onClick={filterTransaction}
				// 				>
				// 					{Translation_Table.apply[Language]}
				// 				</Button>
				// 				<Button
				// 					onClick={closeFilterPopup}
				// 					variant="contained"
				// 					className="signbt4"
				// 				>
				// 					{Translation_Table.close[Language]}
				// 				</Button>
				// 			</div>
				// 		</div>
				// 	</div>
				// </div>
			)}
		</div>
	);
}

export default AccountStatement;