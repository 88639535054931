import React from 'react';
import { Card, CardContent } from "@mui/material";
import { ReactComponent as Pending } from "../../images/svg/clock-svgrepo-com.svg";
import { ReactComponent as Lossarrow } from "../../images/svg/arrow-down.svg";
import { ReactComponent as Profitarrow } from "../../images/svg/arrow-up.svg";

// Styles
import './BetHistory.css';

function QTechGameCard({ item,title, type, amount, date, marketName, reviewID, roundId, transactionId, resultDate, winning, winningAmount, category }) {

    return (
        <Card>
            <CardContent className="content-bet">
                <div className="bethis-card">
                    <div className="card-child">
                        {type === "pending" && <Pending className="profile-bet" />}
                        {type === "loss" && <Lossarrow className="profile-bet" />}
                        {type === "win" && <Profitarrow className="profile-bet" />}
                    </div>
                    <div className="loss-bet">
                        <span className="text-bethis">{title}</span>
                    </div>
                </div>
                <div className="amount-bethis">
                    {/* <span> {amount}</span> */}
                </div>

                <div className="bet-content-container">


                    <p className='bet-market-name mb-3'>QTech Bet:</p>

                    {/* <p className='bet-market-name'>{`Game Name: ${marketName}`}</p> */}

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`${item === "W" ? "Winning" : "Loss"} Amount: ${item === "W" ? winning : amount}`}</p>
                    </div>
                    
                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Qtech Bet Amount: ${amount}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Category: ${category}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Settlement ID: ${transactionId}`}</p>
                    </div>

                    {winningAmount && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Winning Amount: ${winningAmount}`}</p>
                        </div>
                    )}

                    {winning && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Winning: ${winning}`}</p>
                        </div>
                    )}


                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Date: ${date}`}</p>
                    </div>

                    {resultDate && (
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Result Date: ${resultDate}`}</p>
                        </div>
                    )}


                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Ref ID: ${reviewID}`}</p>
                    </div>

                </div>
            </CardContent>
        </Card>
    )
}

export default QTechGameCard;