import React, { useContext, useState } from 'react'
import { ExchangeContexts } from '../../../../Contexts/ExchangeContext';
import PlaceMatchBetCard from '../PlaceMatchBetCard/PlaceMatchBetCard';
import starIcon from "../../../../Assets/new-exchange/Exchange-sports-images/starIcon.png"
import { createUniqueKey } from '../../../../Utilities/ExchangeUniqueKey';
const MatchOddsDataRow = ({ match, gameId }) => {
    const [betData, setBetData] = useState({ isBack: Boolean, gameId: gameId, run: "", runner: "" });
    const usePlaceBetContext = useContext(ExchangeContexts);


    // open place bet section on user click on the bet back box and lay box
    const onBetPlace = (data) => {
        // Update the place bet data object
        setBetData({ ...data, gameId: gameId });

        const uniqueKey = createUniqueKey(match);

        // Toggle the active match place bet section using the unique key
        usePlaceBetContext.setActiveMatchPlaceBetSection(prevState => ({
            [uniqueKey]: !prevState[uniqueKey],
        }));
    };


    const uniqueKey = createUniqueKey(match);



    return <>

        <div className="exchange-data-card-matches-row">
            <div className="exchange-data-card-matches-names-wrapper">
                <div className="exchange-data-match-bets-title">{match?.runnerName}</div>
                <div className="exchange-data-back-lay-boxes">
                    <div className="exchange-data-back-box"
                        style={{ backgroundColor: match?.back1 === 0 ? "#aebade" : "#0f36ac" }}
                        onClick={() => {
                            if (match?.back1 !== 0) {
                                 onBetPlace({
                                    match: match,
                                    isBack: true,
                                    runnerName: match?.runnerName,
                                    run: match?.back1,
                                });
                            } 
                        }}>
                        <div className="exchange-data-bet-value">
                            {match?.back1 !== 0 ? match?.back1 : ""}
                        </div>
                        <div className="exchange-data-bet-amount" hidden></div>
                    </div>
                    <div className="exchange-data-lay-box"

                        style={{ backgroundColor: match?.lay1 === 0 ? "#f1c6c0" : "#e85d4a" }}

                        onClick={() => {
                       
                            if (match?.lay1 !== 0) {
                               
                                onBetPlace({
                                    match: match,
                                    runnerName: match?.runnerName,
                                    isBack: false,
                                    run: match?.lay1
                                });
                            } 
                        }}
                    >
                        <div className="exchange-data-bet-value">
                            {match?.lay1 !== 0 ? match?.lay1 : ""}

                        </div>
                        <div className="exchange-data-bet-amount" hidden></div>
                    </div>
                </div>
            </div>
            {
                usePlaceBetContext.activeMatchPlaceBetSection[uniqueKey] && <PlaceMatchBetCard betData={betData} openedMatchPlaceBetSection={uniqueKey} />
            }
        </div>

    </>
}

export default MatchOddsDataRow