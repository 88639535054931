import React from 'react'
import "./WhoWillWinCard.css"

// import icons and images
import starIcon from "../../../../Assets/new-exchange/Exchange-sports-images/starIcon.png"
const WhoWillWinCard = ({ showIcon, winBoxColor, rowData }) => {
  
    return <>
        <div className="scorcard-match-win-card">
            {/* Header of the scorcard matchcard ----------------- */}
            <div className="scorcard-match-win-card-header">
                <div className="scorcard-match-win-card-header-wrapper">
                    <div className="scorcard-match-win-header-heading">
                        {showIcon && <img src={starIcon} alt="loading" />}

                        Who will win the match?</div>
                </div>

            </div>
            {/* end of the scorcard match card --------------------- */}


            <div className="match-winers-boxes">
                {
                    rowData?.map((winer) => {
                        return <>
                            <div className="winner1" >
                                <div className="winner-name">{winer?.runnerName} </div>
                                <div style={{ backgroundColor: winBoxColor }} className="winner-score-box">1.91</div>
                            </div>
                        </>
                    })
                }


            </div>

            {/* Match row ---------------------------- */}
        </div>

    </>
}

export default WhoWillWinCard
