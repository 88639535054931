import React from 'react';

// Components
import Loader from '../../components/Loader/Loader';

function LoadingPage() {
    return (
        <Loader />
    )
}

export default LoadingPage