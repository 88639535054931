import React from 'react'
import "./FancyBetsSection.css"
import SessionRunCard from "../../../../ExchangeReusableCards/SessionRunCard/SessionRunCard"
const AllFancyBetsSession = ({fancy,gameId}) => {
    return <>
        <div className="fancy-all-bets-sessions">
            {/* start the session run header ------------ */}
            <div className="session-runs-header">
                <div className="session-heading">
                    SESSION
                </div>
                <div className="fancy-back-lay-labels">
                    <div className="fancy-lay-label">Lay</div>
                    <div className="fancy-back-label">Back</div>
                </div>
            </div>
            {/* end of labels haeder ---------------------- */}
            <div className="session-runs-list">

                {
                    fancy?.map((match)=>{
                        return <> 
                          <SessionRunCard gameId={gameId} match={match} />
                        </>
                    })
                }
              
               
            </div>
        </div>

    </>
}

export default AllFancyBetsSession
