import React, { useContext, useEffect, useState } from 'react';
import "./OpenBetsDataTable.css";
import { toast } from 'react-toastify';
import { ExchangeContexts } from '../../../../../Contexts/ExchangeContext';
import { ReactComponent as PaginationIcon } from "../../../../../Assets/openBetsIcons/paginationIcon.svg"
import Loader from '../../../../Loader/Loader';
import { useLocation } from 'react-router-dom';

const OpenBetsDataTable = ({ isAllBets, filteredBets }) => {
    const [pageNo, setPageNo] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const { openBets, getOpenBets } = useContext(ExchangeContexts);
    const [selectedMarket, setSelectedMarket] = useState("MARKET");
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState({ marketBets: [], fancyBets: [] });
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
    const [displayBets, setDisplayBets] = useState([]);
    
    useEffect(() => {
      
        getOpenBets();
       
    }, []);

    // check if all bets or not
    const data = isAllBets ? openBets : filteredBets;

    const filterBetsByDate = (bets, start, end) => {
        return bets.filter(bet => {
            const betDate = new Date(bet.dateTime);
            return betDate >= start && betDate <= end;
        });
    };

    const handleBetsSearch = () => {
        if (startDate && endDate) {
            setLoading(true);
            const start = new Date(startDate);
            const end = new Date(endDate);
            end.setHours(23, 59, 59, 999); // Set the end date to the end of the day
            const filteredMarketBets = filterBetsByDate(data.marketBets, start, end);
            const filteredFancyBets = filterBetsByDate(data.fancyBets, start, end);
            setFilteredData({ marketBets: filteredMarketBets, fancyBets: filteredFancyBets });
            setLoading(false);
            setIsSearchPerformed(true);
        } else {
            toast.error("Please select dates");
        }
    };

    useEffect(() => {
        if (isSearchPerformed) {
            if (selectedMarket === "MARKET") {
                setDisplayBets(filteredData.marketBets);
            } else {
                setDisplayBets(filteredData.fancyBets);
            }
        } else {
            if (selectedMarket === "MARKET") {
                setDisplayBets(data.marketBets);
            } else {
                setDisplayBets(data.fancyBets);
            }
        }
    }, [startDate, endDate, selectedMarket, isSearchPerformed, isAllBets, filteredBets, data, filteredData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className={`open-bets-data-table-section ${isAllBets ? 'all-open-bets' : ''}`}>
                <div className="open-bets-data-table-actions-header">
                    <div className="table-actions">
                        <div className="action-label">Betting Profit and Loss</div>
                        <div className="action-buttons">
                            <select name="market-type" className='select-market' id="" onChange={(e) => setSelectedMarket(e.target.value)}>
                                <option value="MARKET">Market</option>
                                <option value="FANCY">Fancy</option>
                            </select>
                            <input value={startDate} type="date" onChange={(e) => setStartDate(e.target.value)} className='date-input' />
                            <input value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className='date-input' />
                            <button onClick={handleBetsSearch} className='search-button'>Search</button>
                        </div>
                    </div>
                </div>
                <div className="open-bets-data-table">
                    <table className='table'>
                        <thead className='thead'>
                            <tr>
                                <th>No</th>
                                <th>Date</th>
                                <th>Event Type</th>
                                <th>Event</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayBets?.map((bet, index) => (
                                <tr key={index}>
                                    <td className='td'>{index + 1}</td>
                                    <td className='td date'>{new Date(bet.dateTime).toLocaleString()}</td>
                                    <td className='td event-type'>{bet.eventType}</td>
                                    <td className='td event-name'>{bet.runner}</td>
                                    <td className='td amount'>{bet.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="open-bets-pagination">
                    <div className="pagination-actions">
                        <div className="prev-btn">
                            <button disabled={pageNo <= 1} onClick={() => setPageNo(pageNo - 1)} className='btn'>
                                <PaginationIcon className={"prev-icon"} /> Previous
                            </button>
                        </div>
                        <div className="page-No">{pageNo}</div>
                        <div className="next-btn">
                            <button onClick={() => setPageNo(pageNo + 1)} className="btn">
                                Next  <PaginationIcon className={"next-icon"} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OpenBetsDataTable;
