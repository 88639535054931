import React from 'react'
import { useNavigate } from "react-router-dom";
import { ReactComponent as Inplay } from "../../Assets/new-exchange/inplay.svg";
import { ReactComponent as Cricket } from "../../Assets/new-exchange/cricket.svg";
import { ReactComponent as Football } from "../../Assets/new-exchange/football.svg";
import { ReactComponent as Tennis } from "../../Assets/new-exchange/tennis.svg";
import { ReactComponent as Polictics } from "../../Assets/new-exchange/polictics.svg";
import { ReactComponent as Play } from "../../Assets/new-exchange/play.svg";
import { ReactComponent as Cric } from "../../Assets/new-exchange/cric-white.svg";
import { ReactComponent as Playbtn } from "../../Assets/new-exchange/playbtn.svg";
import { ReactComponent as FootBall } from "../../Assets/new-exchange/foot-ball.svg";
import "../../Pages/Home/Home.css"
import ExchangeInplayComponent from './ExchangeSections/ExchangeInplayComponent/ExchangeInplayComponent';
function NewExchange() {
  const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;
  const navigate = useNavigate();
  const handleGame = () => {
      navigate("/exchange-data")
  }

  return (
    <>
      {/* <div className="new-exchange">
        <div className="game-name">
          <Inplay />
          <span className="spn-clr">IN PLAY</span>
        </div>
        <div className="game-name">
          <Cricket />
          <span className="spn-clr">CRICKET</span>
        </div>
        <div className="game-name">
          <Football />
          <span className="spn-clr">FOOTBALL</span>
        </div>
        <div className="game-name">
          <Tennis />
          <span className="spn-clr">TENNIS</span>
        </div>
        <div className="game-name">
          <Polictics />
          <span className="spn-clr">POLITICS</span>
        </div>
      </div> */}

      <ExchangeInplayComponent/>
      

      
    </>
  )
}

export default NewExchange






// <div>
//         <div className="inplay">
//           <div >
//             <Play />
//           </div>
//           <div className="inner-inplay">
//             <span className="spn-inplay">In Play</span>
//           </div>
//           <div className="open-bet">
//             <span className="spn-openbet">Open Bets</span>
//             <div className="circle">
//               <span>1</span>
//             </div>
//           </div>
//         </div>
//         <div>
//           <div className="col-12 mt-2">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><Cric /></div>
//                         <div>Cricket</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           44 Ovr <br />
//                           Tgt 170 <br />
//                           139/6
//                         </span>
//                         <span onClick={handleGame} className="match pdd">
//                           Australia <br />
//                           India
//                         </span>
//                         <span>
//                           <Playbtn className="playbtn" />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">-</td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           25 Ovr <br />
//                           139/6
//                         </span>
//                         <span className="match pdd">
//                           Australia Women <br />
//                           India Women
//                         </span>
//                         <span>
//                           <Playbtn className="playbtn" />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">-</td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <div className="line-ht">
//                         <span className="over pdd">
//                           19/11/2023 <br />
//                           14:00 <br />
//                         </span>
//                         <span className="match pdd">
//                           ICC Cricket World Cup <br />
//                         </span>
//                       </div>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,31,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         4.9 <br />
//                         31,111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         68,111
//                       </span>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//         <div>
//           <div className="col-12">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><FootBall /></div>
//                         <div>Football</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         44'
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='ftball'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         44'
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='ftball'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//         <div>
//           <div className="col-12">
//             <div className="table-responsive">
//               <table className="table">
//                 <thead>
//                   <tr className="text-center pdd-btm">
//                     <th>
//                       <div className="cricket-bg">
//                         <div><FootBall /></div>
//                         <div>Tennis</div>
//                       </div>
//                     </th>
//                     <th>1</th>
//                     <th>X</th>
//                     <th>2</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         <div style={{marginLeft:"5px"}}>
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='tennis'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
//                   <tr className="text-center brdr">
//                     <td>
//                       <span className="over-ft">
//                         <div style={{marginLeft:"5px"}}>
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className="mg-left">
//                           <span>0</span><br />
//                           <span>1</span>
//                         </div>
//                         <div className='tennis'>
//                           <span className="match ">
//                             Australia <br />
//                             India
//                           </span>
//                           <span>
//                             <Playbtn className="playbtn" />
//                           </span>
//                         </div>
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                     <td className="td-dash">
//                       <span>
//                         1.17 <br />
//                         111
//                       </span>
//                     </td>
//                     <td className="td-point">
//                       <span>
//                         1.17 <br />
//                         1,111
//                       </span>
//                     </td>
//                   </tr>
                  
//                 </tbody>
//               </table>
//             </div>

//           </div>
//         </div>
//       </div>