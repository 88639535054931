import React from 'react'
import "./MatchTeamsInfoCard.css"
import { ReactComponent as Playbtn } from "../../../../Assets/new-exchange/play-button.svg";
import { ReactComponent as Info } from "../../../../Assets/new-exchange/info.svg";
const MatchTeamsInfoCard = ({ match, isInplay, Set_Selected_Tab }) => {
    return <>

        <div className='outer-bg'>
            <div className='outer-cont'>
                <div className="match-teams-details">
                    {
                        !isInplay && <Playbtn onClick={() => Set_Selected_Tab("LIVE")} className='svg-btn' />
                    }
                    <div className='play-spn'>
                        <span>
                            {match?.team1} vs {match?.team2}
                        </span>
                        <br />

                        <span className='spn-play'>
                            {match?.statsTitle}
                        </span>
                    </div>
                </div>
                <div className='svg-info'>
                    <Info />
                </div>
            </div>

        </div>

    </>
}

export default MatchTeamsInfoCard
