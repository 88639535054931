import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';

import "./Scanner.css"
import { Button } from '@mui/material';

const ScannerQr = ({ state }) => {
    const location = useLocation();
    const urlQr = new URLSearchParams(location.search).get('qr');
    const qrData = urlQr ?  decodeURIComponent(urlQr) : null
    const decryptedDataUrl =  qrData || (location.state && location.state.url);
    const qrCodeContainerRef = useRef(null);


    const downloadQRCode = () => {
        const container = qrCodeContainerRef.current;
        if(container){
            html2canvas(document.querySelector("#qrCode")).then(canvas => {
                const dataUrl = canvas.toDataURL('image/jpeg', 1);
                const a = document.createElement('a');
                a.href = dataUrl;
                a.download = 'qrcode.jpeg';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        }
    };


    return (
        <div className='qr-code' id='qrCode' style={
            {
                width: "100%",
                height: '100%',
            }
        } ref={qrCodeContainerRef}>
            <h5 className='text-center'>QR Code For Payment</h5>
            <div className='pt-4 pb-4'>
                {/* <div className='mt-2' ref={qrCodeContainerRef} >
                    <QRCode className='qrcode' value={decryptedDataUrl} />
                </div> */}
                <div className='d-flex justify-content-center mt-2'>
                    <QRCode className='qrcode' value={decryptedDataUrl} />
                </div>
            </div>
            <div className='d-flex justify-content-center mt-4 mb-4'>
                <Button onClick={downloadQRCode}>Download QR Code</Button>
            </div>
        </div>
    );
};

export default ScannerQr;
