import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Components
import Login from "../Modals/Login";

// Assets
import { ReactComponent as Homeicon } from "../../Assets/footerIcons/HomeIcon.svg";
import { ReactComponent as Satta } from "../../Assets/footer-icons-new/Satta.svg";
import { ReactComponent as Exchangenor } from "../../Assets/footer-icons-new/Exchange.svg";
import { ReactComponent as Casino } from "../../Assets/footer-icons-new/Casino.svg";
import { ReactComponent as Slots } from "../../Assets/footer-icons-new/Slots.svg";

import "./MobileFooter.css";
// Styles
import "../../css/deposite.css";
import "../../css/footer.css";
import "../../css/profile.css";
import "../../Pages/Home/Home.css";

function MobileFooter() {
    const navigate = useNavigate();
    const location = useLocation();

    const { pathname } = location ? location : {};

    const [modalShow1, setModalShow1] = useState(false);
    const [isHomeActive, setIsHomeActive] = useState(true);
    const [isSattaActive, setIsSattaActive] = useState(false);
    const [isExchangeActive, setIsExchangeActive] = useState(false);
    const [isCasinoActive, setIsCasinoActive] = useState(false);
    const [isSlotsActive, setIsSlotsActive] = useState(false);

    useEffect(() => {
        if (
            pathname === "/" ||
            pathname === "/deposit" ||
            pathname === "/profile" ||
            pathname === "/bet-history" ||
            pathname === "/account-statement"
        ) {
            setIsHomeActive(true);
        } else if (pathname === "/satta") {
            setIsSattaActive(true);
        } else if (pathname === "/exchange") {
            setIsExchangeActive(true);
        } else if (pathname === "/casino") {
            setIsCasinoActive(true);
        } else if (pathname === "/slots") {
            setIsSlotsActive(true);
        } else if (pathname === "/exchange-select") {
            setIsExchangeActive(true);
        }
    }, [pathname]);

    return (
        <div className="MobileFooter-outer">
            <div className="MobileFooter_mobileFooter depo-foot">
                <div
                    onClick={() => navigate("/satta")}
                    role="button"
                    className={`MobileFooter_footerItem ${isSattaActive ? "active" : ""}`}
                >
                    <a className="MobileFooter_itemContent" role="button">
                        {isSattaActive ? (
                            <>
                                <Satta className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Satta Matka</span>
                            </>
                        ) : (
                            <>
                                <Satta className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Satta Matka</span>
                            </>
                        )}
                    </a>
                </div>
                <div
                    onClick={() => navigate("/exchange-select")}
                    role="button"
                    className={`MobileFooter_footerItem ${isExchangeActive ? "active" : ""}`}
                >
                    <a className="MobileFooter_itemContent" role="button">
                        {isExchangeActive ? (
                            <>
                                <Exchangenor className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Exchange</span>
                            </>
                        ) : (
                            <>
                                <Exchangenor className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Exchange</span>
                            </>
                        )}
                    </a>
                </div>
                <div
                    onClick={() => navigate("/")}
                    role="button"
                    className={`MobileFooter_footerItem ${isHomeActive ? "active" : ""}`}
                >
                    <a className="MobileFooter_itemContent" role="button">
                        {isHomeActive ? (
                            <>
                                <Homeicon className="MobileFooter_icon newfix" />
                                <span className="MobileFooter_label">Home</span>
                            </>
                        ) : (
                            <>
                                <Homeicon className="MobileFooter_icon newfix" />
                                <span className="MobileFooter_label">Home</span>
                            </>
                        )}
                    </a>
                </div>

                <div
                    onClick={() => navigate("/casino-select")}
                    role="button"
                    className={`MobileFooter_footerItem ${isCasinoActive ? "active" : ""}`}
                >
                    <a className="MobileFooter_itemContent" role="button">
                        {isCasinoActive ? (
                            <>
                                <Casino className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Casino</span>
                            </>
                        ) : (
                            <>
                                <Casino className="MobileFooter_icon" />
                                <span className="MobileFooter_label">Casino</span>
                            </>
                        )}
                    </a>
                </div>
                <div
                    onClick={() => navigate("/casino-category", { state: { Category: "SLOT" } })}
                    role="button"
                    className={`MobileFooter_footerItem ${isSlotsActive ? "active" : ""}`}
                >
                    <a className="MobileFooter_itemContent" role="button">
                        {isSlotsActive ? (
                            <>
                                <Slots className="MobileFooter_icon newfix" />
                                <span className="MobileFooter_label">Slots</span>
                            </>
                        ) : (
                            <>
                                <Slots className="MobileFooter_icon newfix" />
                                <span className="MobileFooter_label">Slots</span>
                            </>
                        )}
                    </a>
                </div>
            </div>
            <Login show={modalShow1} onHide={() => setModalShow1(false)} />
        </div>
    );
}

export default MobileFooter;
