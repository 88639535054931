import React from 'react';

// Assets
import F1 from "../../images/fi1.png";
import F2 from "../../images/fi2.png";
import F6 from "../../images/fi6.png";

// Styles
import '../../css/signup.css'
import '../../css/otpform.css'

function SignUpBanner({ whatsapp }) {
    return (
        <div className="login-first">
            <p className="id-redy">Get your ready-made ID from whatsapp</p>
            <div className="row row-mr sign2">
                <div className="col">
                    <div className="limg">
                        <img src={F1} />
                    </div>
                    <div className="rdsc">
                        <span>1 to 1</span>
                        <span>Customer</span>
                        <span>Support</span>
                    </div>
                    <div className="clrBoth" />
                </div>
                <div className="col">
                    <div className="limg">
                        <img src={F2} />
                    </div>
                    <div className="rdsc">
                        <span>24/7</span>
                        <span>Instant</span>
                        <span>Withdrawl</span>
                    </div>
                    <div className="clrBoth" />
                </div>
                <div className="col">
                    <div className="limg">
                        <img src={F2} />
                    </div>
                    <div className="rdsc">
                        <span>Safest</span>
                        <span>Gaming</span>
                        <span>ID Provider</span>
                    </div>
                    <div className="clrBoth" />
                </div>
                <div className="clrBoth" />
                <div className="whtsmain">
                    <a
                        className="whtaBtn"
                        target="_blank"
                        href={whatsapp}
                    >
                        <img src={F6} />
                    </a>
                </div>
                <div className="clrBoth" />
            </div>
            <div className="clrBoth" />
        </div>
    )
}

export default SignUpBanner;