import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { User_Context } from "../../Contexts/User";
import { toast } from "react-toastify";
import { Payment_APIs } from "../../API/Payment_APIs";
import { Language_Context } from "../../Contexts/Language";
import { decryptData } from "../../Utilities/DecryptData";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Card, Grid, CardContent, Typography, IconButton, Tooltip, Button } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import TextField from "@mui/material/TextField";
import { Translation_Table } from "../../Configuration/Translation";
import GeneralButton from "../../components/Buttons/GeneralButton";
import { encryptData } from "../../Utilities/EncryptData";
import { API_Endpoint } from "../../Configuration/Settings";
import { Utr_APIs } from "../../API/Utr_Api";
import axios from "axios";

// Components
import Loader from "../../components/Loader/Loader";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import DepositMainCard from "../../components/Deposit/DepositMainCard";
import DepositPopup from "../../components/Deposit/DepositPopup";

// Styles
import "../../css/deposite.css";
import GeneralTextfield from "../../components/Textfields/GeneralTextfield";

const Deposit = ({ lat, lan }) => {
    const isTabOrMobile = window.matchMedia("(max-width: 1025px)").matches;
    const [showDepositPopup, setShowDepositPopup] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showAmount, setShowAmount] = useState("");
    const [upiMode, setUpiMode] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorUpiMode, setErrorUpiMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const { User, Set_User } = useContext(User_Context);
    const [paymentName, setPaymentName] = useState([]);
    const [state, setState] = useState([]);
    const [postcode, setPostcode] = useState("");
    const [gatewayName, setGatewayName] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const dataUrl = new URLSearchParams(location.search).get("data");
    const userToken = new URLSearchParams(location.search).get("usertoken");
    const userid = new URLSearchParams(location.search).get("userid");
    const username = new URLSearchParams(location.search).get("name");
    const usermobile = new URLSearchParams(location.search).get("mobile");
    const useremail = new URLSearchParams(location.search).get("email");
    const usercity = new URLSearchParams(location.search).get("city");
    const userstate = new URLSearchParams(location.search).get("state") || state;
    const userLat = new URLSearchParams(location.search).get("lat");
    const userLng = new URLSearchParams(location.search).get("lng");
    const [depositType, setDepositType] = useState(isTabOrMobile ? "automatic" : "manual");
    const [selectUtr, setSelectUtr] = useState(false);
    const { Language, Set_Language } = useContext(Language_Context);
    const [accountData, setAccountData] = useState([]);

    const [depositUtr, setDepositUtr] = useState("");
    const [depositUtrError, setDepositUtrError] = useState(false);
    const [depositUtrHelperText, setDepositUtrHelperText] = useState("");

    const [utrAmount, setUtrAmount] = useState("");
    const [amountUtrError, setAmountUtrError] = useState(false);
    const [amountUtrHelperText, setAmountUtrHelperText] = useState("");

    useEffect(() => {
        setIsLoading(true);
        if (User.token) {
            Promise.all([
                Payment_APIs.Get_Address(
                    {
                        lat: lat,
                        lng: lan,
                    },
                    User.token
                ),
                Payment_APIs.Get_Payment_Methods({}, User.token),
            ])
                .then((response) => {
                    const state = decryptData(response[0].data.data).payload.state;
                    const postcode = decryptData(response[0].data.data).payload.postcode;
                    const gateway = decryptData(response[1].data.data).payload;
                    setPaymentName(gateway);
                    setState(state);
                    setPostcode(postcode);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error("Please allow location");
                    if (error?.response?.status === 401) {
                        navigate("/");
                        setIsLoading(false);
                    }
                });
        } else if (userToken) {
            Promise.all([
                Payment_APIs.Get_Address(
                    {
                        lat: lat,
                        lng: lan,
                    },
                    userToken
                ),
                Payment_APIs.Get_Payment_Methods({}, userToken),
            ])
                .then((response) => {
                    const state = decryptData(response[0].data.data).payload.state;
                    const gateway = decryptData(response[1].data.data).payload;
                    const postcode = decryptData(response[0].data.data).payload.postcode;
                    setPostcode(postcode);
                    setPaymentName(gateway);
                    setState(state);
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error("Please allow location");
                    setIsLoading(false);
                    if (error?.response?.status === 401) {
                        navigate("/");
                        setIsLoading(false);
                    }
                });
        }
    }, [lat, lan]);

    const deposite = async () => {
        if (showAmount === "" || parseInt(showAmount) < 300 || parseInt(showAmount) >= 50000) {
            setErrorMessage(true);
        } else if (upiMode === "") {
            setErrorUpiMode(true);
        } else {
            setShowDepositPopup(false);
            setIsLoading(true);
            let data;
            if (gatewayName === "A2Z") {
                if (
                    userToken &&
                    userid &&
                    username &&
                    useremail &&
                    usercity &&
                    userstate &&
                    usermobile &&
                    userLat &&
                    userLng
                ) {
                    data = {
                        mode: upiMode,
                        userId: userid,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: username,
                        userEmail: useremail,
                        userMobile: usermobile,
                        userCity: usercity,
                        paymentType: dataUrl,
                        userState: userstate,
                        lat: userLat,
                        lng: userLng,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                } else {
                    data = {
                        mode: upiMode,
                        userId: User.data._id,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: User.data.name,
                        userEmail: User.data.email,
                        userMobile: User.data.mobile,
                        userCity: User.data.city,
                        paymentType: dataUrl,
                        userState: state || User.data.state,
                        lat: lat,
                        lng: lan,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                }
            }
            if (gatewayName === "MOBI-CASH") {
                if (
                    userToken &&
                    userid &&
                    username &&
                    useremail &&
                    usercity &&
                    userstate &&
                    usermobile &&
                    userLat &&
                    userLng
                ) {
                    data = {
                        mode: upiMode,
                        userId: userid,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: username,
                        userEmail: useremail,
                        userMobile: usermobile,
                        userCity: usercity,
                        paymentType: dataUrl,
                        userState: userstate,
                        lat: userLat,
                        lng: userLng,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                } else {
                    data = {
                        mode: upiMode,
                        userId: User.data._id,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: User.data.name,
                        userEmail: User.data.email,
                        userMobile: User.data.mobile,
                        userCity: User.data.city,
                        paymentType: dataUrl,
                        userState: state || User.data.state,
                        lat: lat,
                        lng: lan,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                }
            }
            // if (gatewayName === "ZEN-PAY") {
            // 	data = {
            // 		mode: upiMode,
            // 		userId: User.data._id,
            // 		amount: showAmount,
            // 		paymentGatewayName: gatewayName,
            // 		userName: User.data.name,
            // 		userEmail: User.data.email,
            // 		userMobile: User.data.mobile,
            // 		userCity: User.data.city,
            // 		paymentType: dataUrl,
            // 		userState: User.data.city,
            // 		lat: lat,
            // 		lng: lan,
            // 		platform: (window.navigator.platform === 'iPad' || window.navigator.platform === 'iPhone' || window.navigator.platform === 'iPod') ? 'ios' : 'other'
            // 	};
            // }
            else {
                if (
                    userToken &&
                    userid &&
                    username &&
                    useremail &&
                    usercity &&
                    userstate &&
                    usermobile
                ) {
                    data = {
                        mode: upiMode,
                        userId: userid,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: username,
                        userEmail: useremail,
                        userMobile: usermobile,
                        userCity: usercity,
                        paymentType: dataUrl,
                        userState: userstate,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                } else {
                    data = {
                        mode: upiMode,
                        userId: User.data._id,
                        amount: showAmount,
                        paymentGatewayName: gatewayName,
                        userName: User.data.name,
                        userEmail: User.data.email,
                        userMobile: User.data.mobile,
                        userCity: User.data.city,
                        paymentType: dataUrl,
                        userState: state || User.data.state,
                        postcode: postcode,
                        platform:
                            window.navigator.platform === "iPad" ||
                            window.navigator.platform === "iPhone" ||
                            window.navigator.platform === "iPod"
                                ? "ios"
                                : "other",
                    };
                }
            }

            if (
                userToken &&
                userid &&
                username &&
                useremail &&
                usercity &&
                userstate &&
                usermobile &&
                userLat &&
                userLng
            ) {
                Payment_APIs.Deposit(data, userToken)
                    .then((response) => {
                        const decryptedData = decryptData(response.data.data);
                        if (gatewayName === "AB-UPI" && upiMode === "qr") {
                            navigate(
                                `/mobile-scanner?qr=${encodeURIComponent(
                                    decryptedData.payload.url
                                )}`,
                                { state: { url: decryptedData.payload.url } }
                            );
                        }
                        // window.location.href = decryptedData.payload.url?decryptedData.payload.url:decryptedData.payload.upi_intent_url;
                        else window.location.href = decryptedData.payload.url;
                        setIsLoading(false);
                        setShowAmount("");
                        setUpiMode("");
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.error(error.response.data.message);
                    });
            } else {
                Payment_APIs.Deposit(data, User.token)
                    .then((response) => {
                        const decryptedData = decryptData(response.data.data);
                        if (gatewayName === "AB-UPI" && upiMode === "qr") {
                            navigate("/scanner", { state: { url: decryptedData.payload.url } });
                        }
                        // window.location.href = decryptedData.payload.url?decryptedData.payload.url:decryptedData.payload.upi_intent_url;
                        else window.location.href = decryptedData.payload.url;
                        setIsLoading(false);
                        setShowAmount("");
                        setUpiMode("");
                    })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(error.response.data.message);
                    });
            }
        }
    };

    const handleShowAmount = (e) => {
        setShowAmount(e.target.value);
        if (e.target.value.length !== 0) {
            setErrorMessage(false);
        }
        if (parseInt(e.target.value) < 300 || parseInt(e.target.value) >= 50000) {
            setErrorMessage(true);
        }
    };

    const handleShowUtrAmount = (e) => {
        setUtrAmount(e.target.value);
    };

    const handleClick = (value) => {
        setShowAmount(value);
        setErrorMessage(false);
    };
    const handleClickUtr = (value) => {
        setUtrAmount(value);
        setAmountUtrError(false);
    };
    const handleUpiMode = (value) => {
        setUpiMode(value);
        setErrorMessage(false);
    };

    // const handleOpenDepositAmount = (Payment_Gateway_Name) => {
    // 	setGatewayName(Payment_Gateway_Name);
    // 	setShowDepositPopup(true);
    // };

    const handleOpenDepositAmount = (Payment_Gateway_Name, selectedUpiMode) => {
        setGatewayName(Payment_Gateway_Name);
        setUpiMode(selectedUpiMode);
        setShowDepositPopup(true);
        setErrorMessage(false);
    };

    const handleCloseDepositAmount = () => {
        setErrorMessage(false);
        setShowDepositPopup(false);
        setShowAmount("");
        setSelectedImage(null);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleDepositTypeChange = (event) => {
        setDepositType(event.target.value);
        // setSelectUtr(false)
    };

    const handleScanQr = () => {
        // setSelectUtr(false)
        setShowDepositPopup(true);
        setGatewayName("AB-UPI");
        setUpiMode("qr");
    };

    const handleCopyClick = (value) => {
        navigator.clipboard.writeText(value);
    };

    const handleUtr = () => {
        setSelectUtr(true);
    };

    const handleDepositUtr = (e) => {
        setDepositUtr(e.target.value);
    };
    const getUtrData = () => {
        Utr_APIs.Get_Utr_Data({})
            .then((response) => {
                response.data.data = decryptData(response.data.data);
                setAccountData(response.data.data.payload);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const handleSubmitUtrDeposit = (e) => {
        e.preventDefault();
        if (!depositUtr) {
            setDepositUtrError(true);
            setDepositUtrHelperText("Please enter utr number");
        } else if (!utrAmount) {
            setAmountUtrError(true);
            setAmountUtrHelperText("please enter amount");
        } else {
            setIsLoading(true);
            let data = {
                mode: "UTR",
                userId: User.data._id,
                amount: utrAmount,
                paymentGatewayName: accountData.accountNumber,
                userName: User.data.name,
                userEmail: User.data.email,
                userMobile: User.data.mobile,
                userCity: User.data.city,
                paymentType: dataUrl,
                userState: state || User.data.state,
                platform: "other",
                accountNumber: accountData.accountNumber,
                utrNumber: depositUtr,
            };
            Utr_APIs.Submit_Utr(data, User.token)
                .then((response) => {
                    if (response.data.success === false) {
                        toast.error(response.data.message);
                    } else if (response.data.success === true) {
                        toast.success(response.data.message);
                        setUtrAmount("");
                        setDepositUtr("");
                        setDepositType("automatic");
                        // setSelectUtr(false)
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    // console.log(error);
                    toast.error(error.response.data.message);
                });
        }
    };

    useEffect(() => {
        getUtrData();
    }, []);

    return (
        <>
            {isLoading && <Loader />}

            <div className="white-bg">
                {isTabOrMobile && (
                    <RadioGroup
                        className="radio-grp"
                        row
                        value={depositType}
                        onChange={handleDepositTypeChange}
                    >
                        <FormControlLabel
                            value="automatic"
                            control={<Radio className="bg-none" />}
                            label="Automatic"
                        />
                        {/* <FormControlLabel value="manual" control={<Radio className="bg-none" />} label="Manual" /> */}
                        {paymentName.map((item) => {
                            if (item.name === "AB-UPI" && item.status === true) {
                                return (
                                    <FormControlLabel
                                        value="scanner"
                                        control={<Radio className="bg-none" />}
                                        label="Scanner"
                                    />
                                );
                            }
                        })}
                    </RadioGroup>
                )}

                {isTabOrMobile && depositType === "automatic" && (
                    <div className="row" style={{ margin: "0px" }}>
                        {paymentName?.map((item) => {
                            if (item?.status && item?.state?.includes(state)) {
                                return (
                                    <div className="col-md-4 padtop">
                                        <DepositMainCard
                                            name={item.name}
                                            onClick={handleOpenDepositAmount}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {depositType === "scanner" ? (
                    <div className="btn-manual">
                        <Button onClick={handleScanQr}>Scan QR</Button>
                    </div>
                ) : null}

                {depositType === "manual" ? (
                    <div className="btn-manual">
                        {!isTabOrMobile ? <Button onClick={handleUtr}>UTR</Button> : null}
                        {!isTabOrMobile ? <Button onClick={handleScanQr}>Scan QR</Button> : null}
                    </div>
                ) : null}

                {selectUtr && depositType == "manual" && (
                    <>
                        <div>
                            <Grid className="d-flex justify-content-center" container spacing={2}>
                                <Grid className="depo-info" item xs={12} sm={3}>
                                    <Card className="card-bg">
                                        <CardContent>
                                            <Typography className="text-center mb-3" variant="h5">
                                                <b>Deposit Info</b>
                                            </Typography>

                                            <Typography className="copy">
                                                Account Holder Name: {accountData.accountHolderName}
                                                <Tooltip title="Copy" arrow>
                                                    <IconButton
                                                        className="back"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                accountData.accountHolderName
                                                            )
                                                        }
                                                        size="small"
                                                    >
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                            <Typography className="copy">
                                                Bank Name: {accountData.bankName}
                                                <Tooltip title="Copy" arrow>
                                                    <IconButton
                                                        className="back"
                                                        onClick={() =>
                                                            handleCopyClick(accountData.bankName)
                                                        }
                                                        size="small"
                                                    >
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>

                                            <Typography className="copy">
                                                Account No: {accountData.accountNumber}
                                                <Tooltip title="Copy" arrow>
                                                    <IconButton
                                                        className="back"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                accountData.accountNumber
                                                            )
                                                        }
                                                        size="small"
                                                    >
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>

                                            <Typography className="copy">
                                                IFSC: {accountData.ifsc}
                                                <Tooltip title="Copy" arrow>
                                                    <IconButton
                                                        className="back"
                                                        onClick={() =>
                                                            handleCopyClick(accountData.ifsc)
                                                        }
                                                        size="small"
                                                    >
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid className="d-flex justify-content-center" container spacing={2}>
                                <Grid className="depo-info" item xs={12} sm={3}>
                                    <Card className="card-bg">
                                        <CardContent>
                                            <Typography className="text-center mb-3" variant="h5">
                                                <b>UTR</b>
                                            </Typography>
                                            <TextField
                                                type="number"
                                                onChange={(e) => handleDepositUtr(e)}
                                                value={depositUtr}
                                                error={depositUtrError}
                                                helperText={depositUtrHelperText}
                                                className="white-bg"
                                                fullWidth
                                                id="outlined-basic"
                                                label="Please Enter UTR"
                                                variant="outlined"
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="amount">
                            <Grid className="d-flex justify-content-center" container spacing={2}>
                                <Grid className="depo-info" item xs={12} sm={3}>
                                    <Card className="card-bg">
                                        <CardContent>
                                            <Typography className="text-center mb-3" variant="h5">
                                                <b>Amount</b>
                                            </Typography>
                                            <TextField
                                                onChange={(e) => {
                                                    handleShowUtrAmount(e);
                                                }}
                                                error={amountUtrError}
                                                helperText={amountUtrHelperText}
                                                value={utrAmount}
                                                type="number"
                                                className="white-bg mb-3"
                                                fullWidth
                                                id="outlined-basic"
                                                label="Amount *"
                                                variant="outlined"
                                            />

                                            <div className="btn-align mt-2 col-12">
                                                <Button
                                                    onClick={() => handleClickUtr("300")}
                                                    className="col-3 btn-utr"
                                                >
                                                    300
                                                </Button>
                                                <Button
                                                    onClick={() => handleClickUtr("500")}
                                                    className="col-3 btn-utr"
                                                >
                                                    500
                                                </Button>
                                                <Button
                                                    onClick={() => handleClickUtr("100")}
                                                    className="col-3 btn-utr"
                                                >
                                                    1000
                                                </Button>
                                            </div>
                                            <div className="btn-align mt-2 col-12">
                                                <Button
                                                    onClick={() => handleClickUtr("2000")}
                                                    className="col-3 btn-utr"
                                                >
                                                    2000
                                                </Button>
                                                <Button
                                                    onClick={() => handleClickUtr("5000")}
                                                    className="col-3 btn-utr"
                                                >
                                                    5000
                                                </Button>
                                                <Button
                                                    onClick={() => handleClickUtr("10000")}
                                                    className="col-3 btn-utr"
                                                >
                                                    10000
                                                </Button>
                                            </div>
                                            <div className="d-flex justify-content-center mt-4 mb-2">
                                                <Button
                                                    onClick={(e) => handleSubmitUtrDeposit(e)}
                                                    disabled={
                                                        parseInt(showAmount) < 300 ||
                                                        parseInt(showAmount) >= 50000
                                                    }
                                                    className="col-6 btn-utr"
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                )}

                {loading && (
                    <div className="popup d-flex align-items-center justify-content-center">
                        <div class="spinner-square">
                            <span class="square-1 square"></span>
                            <span class="square-2 square"></span>
                            <span class="square-3 square"></span>
                        </div>
                    </div>
                )}
                {location.pathname !== "/deposit-mobile" && <MobileFooter />}
            </div>

            {showDepositPopup && (
                <DepositPopup
                    handleClick={handleClick}
                    deposite={deposite}
                    handleShowAmount={handleShowAmount}
                    handleCloseDepositAmount={handleCloseDepositAmount}
                    handleUpiMode={handleUpiMode}
                    selectedImage={selectedImage}
                    errorUpiMode={errorUpiMode}
                    errorMessage={errorMessage}
                    value={showAmount}
                />
            )}
        </>
    );
};

export default Deposit;
