import { createContext, useState } from 'react';
import io from "socket.io-client";
import { useContext, useEffect } from 'react';
import { User_Context } from './User';
import { WebSocket_URI } from '../Configuration/Settings';
import { decryptData } from '../Utilities/DecryptData';

export const Web_Socket_Context = createContext();

const Web_Socket_Provider = ({ children }) => {
	const SocketIO_Client = io(WebSocket_URI, { autoConnect: false, path: '/api/socket.io' });
	const { User, Set_User, Previous_User } = useContext(User_Context);
	SocketIO_Client.on('connect', () => {
	});
	SocketIO_Client.on('disconnect', (Reason) => {
		if (Reason === 'io server disconnect') {
			SocketIO_Client.connect();
		}
	});
	SocketIO_Client.on('user_balance_updated', (Data) => {
		const receivedData = decryptData(Data)
		if (receivedData._id === User.data._id) {
			const User_Copy = { ...User };
			let ex = User_Copy.data.exposer + receivedData.amount
			User_Copy.data.balance = receivedData.balance;
			User_Copy.data.exposer = ex;
			Set_User(User_Copy);
		}
	});

	SocketIO_Client.on('user_bonus_balance_updated', (Data) => {
		const receivedData = decryptData(Data)

		if (receivedData._id === User.data._id) {
			const User_Copy = { ...User };
			User_Copy.data.bonusWalletBalance = receivedData.balance;
			Set_User(User_Copy);
		}
	});


	useEffect(() => {
		if (User.token === '' && Previous_User !== undefined) {
			SocketIO_Client.disconnect();
		}
		if (User.token !== '') {
			SocketIO_Client.connect();
		}
		return () => {
			if (SocketIO_Client.connected) {
				SocketIO_Client.disconnect();
				SocketIO_Client.off('connect');
				SocketIO_Client.off('disconnect');
				SocketIO_Client.off('user_balance_updated');
			}
		}
	}, [User.token]);

	return <Web_Socket_Context.Provider value={SocketIO_Client}>{children}</Web_Socket_Context.Provider>;
}

export default Web_Socket_Provider;
