import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, FreeMode, Pagination } from "swiper/modules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Grid } from "@mui/material";
import { Image } from "react-bootstrap";
import { User_Context } from "../../Contexts/User";

import Logout_User from "../../Utilities/Logout";
import { Satta_Market_API } from "../../API/Satta_Market_API";
import { decryptData } from "../../Utilities/DecryptData";

//assert
import Clock from "../../Assets/Images/home-page-satta-market/clock.png";

// style
import "../../components/GameSattaMarket/GameSattaMarket.css";

// Components
import Loader from "../../components/Loader/Loader";
import SearchBox from "../../components/SearchBox/SearchBox";

// Styles
import "../TopGames/TopGames.css";

function SattaMarket() {
    const [searchGames, setSearchGames] = useState('');
    const { User, Set_User } = useContext(User_Context);
    const [loading, setLoading] = useState(false);
    const [marketList, setMarketList] = useState([]);

    const formatTime = (rawTime) => {
      const [hours, minutes] = rawTime.split(':');
      const formattedTime = new Date(0, 0, 0, hours, minutes);
      return formattedTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };
    const inferTimer = (day, fixedDateStr) => {
      try {
        const fixedDateTime = new Date(`${day} ${fixedDateStr}`);
        const nowDate = new Date();

        let duration = fixedDateTime.getTime() - nowDate.getTime();

        if (duration < 0) {
          const nextDay = new Date(fixedDateTime);
          nextDay.setDate(nextDay.getDate() + 1);
          duration = nextDay.getTime() - nowDate.getTime();
        }

        return duration;
      } catch (error) {
        console.error(error);
        return 0;
      }
    };
    function useInterval(callback,delay) {
      const callbackRef = useRef(callback);

      useEffect(() => {
        callbackRef.current = callback;
      }, [callback]);

      useEffect(() => {
        function tick() {
          if (callbackRef.current) {
            callbackRef.current();
          }
        }
        if (delay !== null) {
          const id = setInterval(tick, delay);
          return () => clearInterval(id);
        }
      }, [delay]);
    }
    function calculateRemainingTime(remainingSeconds) {
      const hours = Math.floor(remainingSeconds / 3600);
      const minutes = Math.floor((remainingSeconds % 3600) / 60);
      const seconds = remainingSeconds % 60;
      return `${hours}:${minutes}:${seconds}s`;
    }

  const getGames = async () => {
    setLoading(true);
    try {
      const response = await Satta_Market_API.Get_Satta_Maket({}, User.token);
      const { data, success } = response.data ? response.data : {};
      if (success) {
        // const finalDataList = decryptData(data)
        setMarketList(data);
      }
    } catch (error) {
      setLoading(false);
      setMarketList([]);
      if (error.response && error.response.status === 401) {
        Logout_User(Set_User);
        navigate("/");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getGames();
  }, []);

  const updateInterval = 1000;

  useInterval(() => {
    if (marketList.length > 0) {
      const updatedMarketList = marketList.map((data) => {
        const openDay = data.oD[0];
        const fixedOpenTime = data.open_time;
        const closeDay = data.cD[0];
        const fixedCloseTime = data.close_time;

        const openRemainingTime = inferTimer(openDay, fixedOpenTime);
        const closeRemainingTime = inferTimer(closeDay, fixedCloseTime);

        // Convert milliseconds to hours, minutes, and seconds
        const convertToTime = (remainingTime) => {
          const hours = Math.floor(remainingTime / (1000 * 60 * 60));
          const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
          return { hours, minutes, seconds };
        };

        return {
          ...data,
          openRemainingTime: convertToTime(openRemainingTime),
          closeRemainingTime: convertToTime(closeRemainingTime),
        };
      });

      setMarketList(updatedMarketList);
    }
  }, updateInterval);





  const navigate = useNavigate();
  const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;

  return (
    <>
      {loading && <Loader />}
      <div className="casino-container">
        <div className="casino-inner-container">
          <div style={{ marginTop: "0.5rem" }}>
            <SearchBox
              searchGames={searchGames}
              handleSearchGames={(event) => setSearchGames(event.target.value)}
            />
          </div>

          <div className="casinoMainDv">
            <div className=" md-rwo tps colsp crvimgrst indTp">
              {isTabOrMobile ? (
                <Grid container>
                  {marketList.length > 0 ? (
                    marketList.map((item, index) => (
                      <Grid key={index} md={4} sm={6} xs={6}>
                        <Card sx={{ maxWidth: 345 }} className="game-lobby-row">
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={item.bazar_image}
                              alt="green iguana"
                            />
                            <CardContent style={{ marginTop: "0.5rem" }}>
                              <Grid container>
                                <Grid md={8} sm={9.5} xs={9.5}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    className="bazar-content-mob content-color text-left"
                                  >
                                    {item.bazar_name}
                                  </Typography>
                                  <Typography className="bazar-content-mob text-left">
                                    {item.result}
                                  </Typography>
                                  <p className="sub-content text-left">
                                    Running For Tomorrow
                                  </p>
                                </Grid>
                                <Grid
                                  md={4}
                                  sm={2.5}
                                  xs={2.5}
                                  className="sub-container"
                                >
                                  <div className="img-div">
                                    {item.icon_status1 == 1 ? (
                                      <Image
                                        src={item.image1}
                                        width={20}
                                        height={20}
                                        alt="Live TV Result"
                                      />
                                    ) : (
                                      <Image
                                        src={item.image2}
                                        width={20}
                                        height={20}
                                        alt="Holiday"
                                      />
                                    )}
                                  </div>
                                  <div className="img-div">
                                    <span className="bazar-content-mob content-color">
                                      LIVE TV RESULT
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions className="action-card-container">
                              <Grid container className="grid-container">
                                <Grid md={4} sm={5} xs={5}>
                                  <Typography className="action-content text-center">
                                    {formatTime(item.open_time)}
                                  </Typography>
                                  <Typography className="action-content text-center">
                                    Open
                                  </Typography>
                                  <Typography className="action-time-content text-center">
                                  {item.openRemainingTime?.hours || 0}:{item.openRemainingTime?.minutes || 0}:{item.openRemainingTime?.seconds || 0}s
                                  </Typography>
                                </Grid>
                                <Grid md={4} sm={2} xs={2}>
                                  <Image src={Clock} width={15} height={15} />
                                </Grid>
                                <Grid md={4} sm={5} xs={5}>
                                  <Typography className="action-content text-center">
                                    {formatTime(item.close_time)}
                                  </Typography>
                                  <Typography className="action-content text-center">
                                    CLOSE
                                  </Typography>
                                  <Typography className="action-time-content text-center">
                                  {item.closeRemainingTime?.hours || 0}:{item.closeRemainingTime?.minutes || 0}
                        :{item.closeRemainingTime?.seconds || 0}s
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardActions>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <LazyLoadImage />
                  )}
                </Grid>
              ) : (
                <Grid container>
                  {marketList.map((item, index) => (
                    <Grid key={index} md={4} sm={6} xs={6} marginBottom={8}>
                      <Card sx={{ maxWidth: 345 }} className="game-lobby-row">
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="140"
                            image={item.bazar_image}
                            alt="green iguana"
                          />
                          <CardContent style={{ marginTop: "0.5rem" }}>
                            <Grid container>
                              <Grid md={8} sm={9} xs={9}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="bazar-content content-color text-left"
                                >
                                  {item.bazar_name}
                                </Typography>
                                <Typography className="bazar-content text-left">
                                  {item.result}
                                </Typography>
                                <p className="sub-content text-left">
                                  Running For Tomorrow
                                </p>
                              </Grid>
                              <Grid
                                md={4}
                                sm={3}
                                xs={3}
                                className="sub-container"
                              >
                                <div className="img-div">
                                  {item.icon_status1 == 1 ? (
                                    <Image
                                      src={item.image1}
                                      width={20}
                                      height={20}
                                      alt="Live TV Result"
                                    />
                                  ) : (
                                    <Image
                                      src={item.image2}
                                      width={20}
                                      height={20}
                                      alt="Holiday"
                                    />
                                  )}
                                </div>
                                <div className="img-div">
                                  <span className="bazar-content content-color ">
                                    LIVE TV RESULT
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions className="action-card-container">
                            <Grid container className="grid-container">
                              <Grid md={4} sm={5} xs={5}>
                                <Typography className="action-content text-center">
                                  {" "}
                                  {formatTime(item.open_time)}
                                </Typography>
                                <Typography className="action-content text-center">
                                  Open
                                </Typography>
                                <Typography className="action-time-content text-center">
                                {item.openRemainingTime?.hours || 0}:{item.openRemainingTime?.minutes || 0}:{item.openRemainingTime?.seconds || 0}s
                                </Typography>
                              </Grid>
                              <Grid md={4} sm={2} xs={2}>
                                <Image src={Clock} width={15} height={15} />
                              </Grid>
                              <Grid md={4} sm={5} xs={5}>
                                <Typography className="action-content text-center">
                                  {formatTime(item.close_time)}
                                </Typography>
                                <Typography className="action-content text-center">
                                  CLOSE
                                </Typography>
                                <Typography className="action-time-content text-center">
                                {item.closeRemainingTime?.hours || 0}:{item.closeRemainingTime?.minutes || 0}
                        :{item.closeRemainingTime?.seconds || 0}s
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SattaMarket;
