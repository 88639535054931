import { createContext, useState } from 'react';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { Use_Previous_Value } from '../Hooks/Use_Previous_Value';
import { User_APIs } from '../API/User_APIs';
import { decryptData } from '../Utilities/DecryptData';
import { encryptData } from '../Utilities/EncryptData';

const Cookie = new Cookies();

const Initial_User =
{
	User:
	{
		token: Cookie.get('jwt-authorization') ? decryptData(Cookie.get('jwt-authorization')) : '',
		data: Cookie.get('userData') ? decryptData(Cookie.get('userData')) :
			{
				_id: '',
				subAdminId: '',
				name: '',
				kyc: false,
				mobile: '',
				email: '',
				state: '',
				city: '',
				balance: 0,
				exposer: 0,
				deviceType: 'M',
				bonusWalletBalance: 0,
				firstDepositDone: false,
				referredUserId: '',
				referredCode: '',
				createdOn: '',
				updatedOn: '',
				aadhaarNumber: "",
				accountNumber: "",
				bankName: "",
				ifsc: "",
				fireBaseToken: "",
				supportExecutiveDp_Id: ""
			}
	},
	Set_User: () => { },
	redirectPath: '/',
	setRidirectPath: (path) => { }
}

export const User_Context = createContext(Initial_User);

const User_Provider = ({ children }) => {
	const [User, Set_User] = useState(Initial_User.User);
	const Previous_User = Use_Previous_Value(User);
	const [redirectPath, setRedirectPath] = useState('/');
	useEffect(() => {
		if (Initial_User.User.data._id) {
			User_APIs.Get_User_Balance({ _id: Initial_User.User.data._id }, Initial_User.User.token).then(API_Response => {
				API_Response.data.data = decryptData(API_Response.data.data);
				Initial_User.User.data.balance = API_Response.data.data.payload.balance;
				Initial_User.User.data.exposer = API_Response.data.data.payload.exposer;
				Initial_User.User.data.bonusWalletBalance = API_Response.data.data.payload.bonusWalletBalance;
				Cookie.set('userData', encryptData(Initial_User.User.data));
				Set_User(Initial_User.User);
			})
		}
	}, [])
	return <User_Context.Provider value={{ User, Set_User, redirectPath, setRedirectPath, Previous_User }}>{children}</User_Context.Provider>;
}

export default User_Provider;