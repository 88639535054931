import React, { useContext, useState } from 'react'
import "./LiveSession.css"
import ScoreCard from './ScoreCard'
import WatchLiveMatch from "./WatchLiveMatch"
import tvIconBlue from "../../../../../Assets/new-exchange/Exchange-sports-images/liveTvIconBlue.png"
import { useLocation } from 'react-router-dom'
import MatchTeamsInfoCard from '../../../ExchangeReusableCards/MatchTeamsInfoCard/MatchTeamsInfoCard'

const LiveSession = ({marketData,isIframe,evId}) => {
    const [activeScorcardTab, setActiveScorcardTab] = useState("MATCH_STAT")
    const { search} = useLocation()
    
    return <>

        <div className="live-session-section" style={{marginTop:isIframe?"0px":"0px"}}>
            {
                isIframe && <MatchTeamsInfoCard/>
            }
           
            <div className="live-session-section-header">
                <div className="live-session-header-heading"  >Scorecard</div>
                <div className="live-session-navigation-tabs">
                    <div className="match-states-tab" 
                    id={activeScorcardTab==="MATCH_STAT"?"active-tab":""}
                        onClick={() => setActiveScorcardTab("MATCH_STAT")}>Match Stats</div>
                    <div   id={activeScorcardTab==="WATCH_LIVE"?"active-tab":""} className="watch-match-tab" onClick={() => setActiveScorcardTab("WATCH_LIVE")}>
                        Watch Live
                    <img className='tv-img' src={tvIconBlue} alt="loading" /> </div>
                </div>
            </div>

            <div className="scorcard-dynamic-outlet-section">

                {
                    activeScorcardTab === 'MATCH_STAT' ? <ScoreCard  evId={evId} /> 
                    : <WatchLiveMatch  evId={evId}/>
                }


            </div>


        </div>

    </>
}

export default LiveSession
