import { API_Handler } from "./API_Handler";
import { encryptData } from "../Utilities/EncryptData";

export const Bonus_Wallet_APIs = {
    getBonusWalletHistory: async (Request, Token) => await API_Handler.post(`/bonus-wallet/getUserBonusWalletHistory`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    getBonusWalletReferralHistory: async (Request, Token) => await API_Handler.post(`/bonus-wallet/getUserBonusWalletHistoryReferral`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    createTransferRequest: async (Request, Token) => await API_Handler.post(`/bonus-wallet/create-transfer-request`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    getTransferRequests: async (Request, Token) => await API_Handler.post(`/bonus-wallet/get-transferRequest`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    cancelTransferRequests: async (Request, Token) => await API_Handler.post(`/bonus-wallet/update-transferRequest`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    referralCodeCheck: async (Request, Token) => await API_Handler.post(`/bonus-wallet/referral-check`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
}