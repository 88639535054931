import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Casino_APIs } from '../../API/Casino_APIs';
import { User_Context } from '../../Contexts/User';
import { useNavigate } from 'react-router-dom';
import Logout_User from '../../Utilities/Logout';
import { decryptData } from '../../Utilities/DecryptData';
import { Game_Context } from '../../Contexts/Games';

// Components
import CasinoCarousel from '../Carousel/CasinoCarousel';
import Loader from '../Loader/Loader';
import QTechGameCard from '../QTechGameCard/QTechGameCard';
import Game_Card from '../Game_Card/Game_Card';

// Styles
import './TopGamesSlider.css'
import { SessionHelper } from '../../Utilities/SessionHelper';


function TopGamesSlider() {
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    const [gameList, setGameList] = useState([]);
    const [loading, setLoading] = useState(false);

    const getGames = async () => {
        setLoading(true)
        try {
            const response = await Casino_APIs.Qtech_Get_Top_Games({}, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const finalDataList = decryptData(data)
                setGameList(finalDataList.payload)
            }


        } catch (error) {
            setLoading(false)
            setGameList([])
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate('/')
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getGames();
    }, []);


    const renderItem = ({ item, index }) => {
        if (activeCasinoProvider === "QTECH") {
            if (item?.images?.[2]) {
                return (
                    <QTechGameCard
                        key={index}
                        data={item}
                        isCarousel={true}
                        user={User}
                    />
                )
            } else {
                return null
            }
        } else {
            return (
                <Game_Card
                    Game_Item={item}
                    key={item._id}
                    casino={true}
                />
            );
        }
    } //, [activeCasinoProvider])

    return (
        <div>
            {loading && <Loader />}
            {!loading &&
                <div className='top-games-slider-header'>
                    <p className='top-games-slider-header-text'>Top Games</p>

                    {/* <div className='top-games-slider-show-more-button'>
                    <p className='top-games-slider-button-text'>Show More</p>
                </div> */}
                </div>
            }
            {!loading &&
                <CasinoCarousel
                    data={gameList}
                    noModules={false}
                    children={renderItem}
                />
            }



        </div>
    )
}

export default TopGamesSlider;
