import React from 'react'
import "./SportNewsComponent.css"
const SportNewsComponent = () => {
  return <>
  
  <section className="sport-news-section">
    <div className="sport-news-section-container">
        <h2 style={{fontSize:"1.3rem",textAlign:"center",padding:"100px"}}>No news Available</h2>
    </div>
  </section>
  
  </>
}

export default SportNewsComponent
