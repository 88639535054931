import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logout_User from "../../Utilities/Logout";
import { Casino_APIs } from "../../API/Casino_APIs";
import { decryptData } from "../../Utilities/DecryptData";
import { Game_Context } from "../../Contexts/Games";
import Game_Card from "../../components/Game_Card/Game_Card";
import { default as BTopGames } from "../../components/TopGames/TopGames";

// Context
import { User_Context } from "../../Contexts/User";

// Components
import Loader from "../../components/Loader/Loader";
import QTechGameCard from "../../components/QTechGameCard/QTechGameCard";
import SearchBox from "../../components/SearchBox/SearchBox";

// Styles
import "./TopGames.css";
import { SessionHelper } from "../../Utilities/SessionHelper";

function TopGames() {
    const { User, Set_User } = useContext(User_Context);
    const activeCasinoProvider = SessionHelper.getActiveExchangeProvider();

    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const { topGames } = gameState;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchGames, setSearchGames] = useState("");

    const [gameList, setGameList] = useState([]);

    const getGames = async () => {
        setLoading(true);
        try {
            const response = await Casino_APIs.Qtech_Get_Top_Games({}, User.token);
            const { data, success } = response.data ? response.data : {};
            if (success) {
                const finalDataList = decryptData(data);
                setGameList(finalDataList.payload);
            }
        } catch (error) {
            setLoading(false);
            setGameList([]);
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User);
                navigate("/");
            }
        }
        setLoading(false);
    };

    // useEffect(() => {
    //   getGames();
    // }, []);

    return <BTopGames data={topGames} showfullScreen={true} />;
}

export default TopGames;

{
    /* {/* {loading && <Loader />}
      <div className="casino-container">
        <div className="casino-inner-container">
          <SearchBox
            searchGames={searchGames}
            handleSearchGames={(event) => setSearchGames(event.target.value)}
          /> */
}
{
    /* <QTechCasinoTabs
                        changeCategory={changeCategory}
                        selectedCategory={category}
                        categories={categories}
                    /> */
}
{
    /* {activeCasinoProvider === "QTECH" ? (
            <div className="casinoMainDv">
              <div className="row md-rwo tps colsp crvimgrst indTp">
                {!loading &&
                  gameList.length > 0 &&
                  gameList.map((item, index) => {
                    if (item.images[2]) {
                      return (
                        <QTechGameCard key={index} data={item} user={User} />
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          ) : (
            <div className="casinoMainDv">
              <div className="row md-rwo tps colsp crvimgrst indTp">
                {!loading &&
                  gameList.length > 0 &&
                  gameList.map((item, index) => {
                    return <Game_Card Game_Item={item} key={index} />;
                  })}
              </div>
            </div>
          )}
        </div> */
}
{
    /* </div>
    </> */
}
