import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './Carousel.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


function CasinoCarousel({ children, slidesPerGroup, noModules, data, loading, onClick }) {

    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;

    const isSmallDesktop = window.matchMedia("(max-width: 110px)").matches;

    return (
        <div className='swiper-container'>
            <Swiper
                id='game-swiper'
                slidesPerView={isTabOrMobile ? 2 : isSmallDesktop ? 4 : 6}
                slidesPerColumn={isTabOrMobile ? 2 : isSmallDesktop ? 4 : 6}
                className='myGameSwiper'
                freeMode={false}
                loop={false}
                slidesPerGroup={isTabOrMobile ? 2 : isSmallDesktop ? 4 : 6}
                navigation={true}
                modules={[FreeMode, Navigation]}
            >
                {data?.length > 0 &&
                    data.map((item, index) => {
                        return (
                            <SwiperSlide key={index} style={{ maxWidth: '220px' }}>
                                {children({ item, index, loading })}
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </div>
    )
}

export default CasinoCarousel;
