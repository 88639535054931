import React, { useContext } from "react";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";

// Components
import GeneralTextfield from "../Textfields/GeneralTextfield";
import GeneralButton from "../Buttons/GeneralButton";

// Assets
import { ReactComponent as Close_Icon } from "../../Assets/Icons/Close_Icon.svg";
// import paytmimg from "../../images/images/PAYTM-1.svg";
// import gpayimg from "../../images/images/GPAY-1.svg";
// import phnpeimg from "../../images/images/PHONEPE.svg";

// Styles
import "../../css/deposite.css";

function DepositPopup({
    value,
    handleShowAmount,
    handleClick,
    handleUpiMode,
    deposite,
    selectedImage,
    errorUpiMode,
    handleCloseDepositAmount,
    errorMessage,
}) {
    const { Language, Set_Language } = useContext(Language_Context);
    return (
        <div className="popup d-flex align-items-center justify-content-center">
            <div className="popup-content2">
                <div className="labelotp2">
                    <span className="enterotp2">
                        <span style={{ visibility: "hidden" }}>Amount</span>
                        <span className="crosssvg" onClick={handleCloseDepositAmount}>
                            <Close_Icon />
                        </span>
                    </span>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-center mt-1 mx-4">
                            {!errorMessage ? (
                                <GeneralTextfield
                                    label={Translation_Table.amount[Language] + " * "}
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                        handleShowAmount(e);
                                    }}
                                />
                            ) : (
                                <GeneralTextfield
                                    error={true}
                                    label={Translation_Table.enteramount[Language] + " * "}
                                    type="number"
                                    value={value}
                                    onChange={(e) => {
                                        handleShowAmount(e);
                                    }}
                                    helperText={
                                        parseInt(value) < 300
                                            ? "Minimum amount is INR 300"
                                            : parseInt(value) >= 50000
                                            ? "Maximum amount is INR 49999"
                                            : ""
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="innerPay InnerSub">
                    <div className="row-cust justify-content-center mt-2">
                        <label className="col-2 potion potion-money">
                            <span
                                className="btn btn-primary depo-money"
                                onClick={() => handleClick("300")}
                            >
                                300
                            </span>
                        </label>
                        <label className="col-2 potion potion-money">
                            <span
                                className="btn btn-primary depo-money"
                                onClick={() => handleClick("500")}
                            >
                                500
                            </span>
                        </label>
                        <label className="col-2 potion potion-money">
                            <span
                                className="btn btn-primary depo-money"
                                onClick={() => handleClick("1000")}
                            >
                                1000
                            </span>
                        </label>
                        <label className="col-2 potion potion-money">
                            <span
                                className="btn btn-primary depo-money"
                                onClick={() => handleClick("2000")}
                            >
                                2000
                            </span>
                        </label>
                    </div>
                </div>
                {/* <div className="innerPay InnerSub">
                    <div className="row-cust justify-content-center mt-2">
                        <label className="col-2 potion">
                            <img
                                onClick={() => handleUpiMode("paytm")}
                                className={`potion1 ${selectedImage === "paytmimg" ? "selected" : ""
                                    }`}
                                src={paytmimg}
                            />
                        </label>
                        <label className="col-2 potion">
                            <img
                                onClick={() => handleUpiMode("gpay")}
                                className={`potion1 ${selectedImage === "gpayimg" ? "selected" : ""
                                    }`}
                                src={gpayimg}
                            />
                        </label>
                        <label className="col-2 potion">
                            <img
                                onClick={() => handleUpiMode("phonepe")}
                                className={`potion1 ${selectedImage === "phnpeimg" ? "selected" : ""
                                    }`}
                                src={phnpeimg}
                            />
                        </label>
                    </div>
                </div>
                {errorUpiMode && (<div className="mt-2">
                    <p className="error-throw">please select the amount and upi mode</p>
                </div>)} */}
                <div className="floatdepo">
                    <GeneralButton
                        disabled={parseInt(value) < 300 || parseInt(value) >= 50000}
                        showText={true}
                        text={Translation_Table.submit[Language]}
                        onClick={deposite}
                    />
                </div>
            </div>
        </div>
    );
}

export default DepositPopup;
