export const onEmailInputKeyDown = (event) => {
    const forbiddenChars = /[%$^&*()#!=+,]/;
    const multipleAtSigns = /@@*/
    const currentValue = event.target.value;

    if ((event.key === '@') && multipleAtSigns.test(currentValue)) {
        event.preventDefault();
    }

    if (forbiddenChars.test(event.key)) {
        event.preventDefault();
    }
}


export const onNumerInputKeyDown = (event) => {
    if (event.key === "e" || event.key === "-" || event.key === "+") event.preventDefault();
}
