import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User_Context } from "../../Contexts/User";
import Logout_User from "../../Utilities/Logout";
import { Feedback_APIs } from "../../API/Feedback_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";
import { toast } from "react-toastify";


// Components
import Header from "../../components/AppHeader/Header";
import Loader from "../../components/Loader/Loader";
import GeneralButton from "../../components/Buttons/GeneralButton";
import GeneralTextfield from "../../components/Textfields/GeneralTextfield";

import '../../css/feedback.css'

function Feedback(props) {
	const [loading, setLoading] = useState(false);
	const { User, Set_User } = useContext(User_Context);

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);

	const [name, setName] = useState("");
	const [mobNumber, setMobNumber] = useState("");
	const [message, setMessage] = useState("");

	const [nameError, setNameError] = useState(false);
	const [mobError, setMobError] = useState(false);
	const [messageError, setMessageError] = useState(false);

	const [nameHelperText, setNameHelperText] = useState("");
	const [mobHelperText, setMobHelperText] = useState("");
	const [messageHelperText, setMessageHelperText] = useState("");

	const { Language, Set_Language } = useContext(Language_Context)

	const navigateToProfile = () => {
		navigate("/profile");
	};

	const submitFeedback = async () => {
		if (!name) {
			setNameError(true);
			setNameHelperText(Translation_Table.pleaseentername[Language]);
		} else if (!mobNumber) {
			setMobError(true);
			setMobHelperText(Translation_Table.pleaseentermobile[Language]);
		} else if (!message) {
			setMessageError(true);
			setMessageHelperText(
				Translation_Table.pleaseentermessage[Language]
			);
		} else {
			let data = {
				name: name,
				mobile: mobNumber,
				message: message,
			};
			Feedback_APIs.Submit_Feedback(data, User.token).then((response) => {
				setName("");
				setMobNumber("");
				setMessage("");
				setLoading(false);
				toast.success(response.data.message)
				navigate("/?data=feedback");
			}).catch((error) => {
				toast.error(error.response.data.message)
				setLoading(false);
				if (error.response.status === 401) {
					Logout_User(Set_User)
					navigate("/")
				}
			});
		}
	};

	return (
		<div>
			{loading && <Loader />}
			<div className="container ">
				<div className="feedback-web">
					<div className="col-12">
						<div className="row">
							<div className="col-12">
								<div className="mt-4">
									<GeneralTextfield
										onChange={(e) => {
											setName(e.target.value);
											setNameError(false);
											setNameHelperText("");
										}}
										error={nameError}
										helperText={nameHelperText}
										value={name}
										type={"text"}
										label={Translation_Table.name[Language] + " * "}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className=" mt-3">
									<GeneralTextfield
										onChange={(e) => {
											const inputMobNumber = e.target.value;
											if (inputMobNumber.length <= 10) {
												setMobNumber(inputMobNumber);
												setMobError(false);
											}
										}}
										error={mobError}
										helperText={mobHelperText}
										value={mobNumber}
										type={"number"}
										label={Translation_Table.mobileNumber[Language] + " * "}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className=" mt-3">

									<GeneralTextfield
										onChange={(e) => {
											setMessage(e.target.value);
											setMessageError(false);
											setMessageHelperText("");
										}}
										error={messageError}
										helperText={messageHelperText}
										value={message}
										type={"text"}
										label={Translation_Table.message[Language] + " * "}
										multiline={true}
										rows={4}
									/>

								</div>
							</div>
						</div>
					</div>
					<div className="mt-4 d-flex justify-content-center">
						<GeneralButton
							onClick={submitFeedback}
							showText={true}
							text={Translation_Table.submit[Language]}
							className={"button-login"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Feedback;