import React, { useContext, useEffect, useState } from 'react';
import "./OpenBetsComponent.css";
import Loader from '../../../../Loader/Loader';
import { ExchangeContexts } from '../../../../../Contexts/ExchangeContext';
import OpenBetsTable from './OpenBetsTable';
import OpenBetsDataTable from './OpenBetsDataTable';

const OpenBetsComponent = ({ gameId }) => {
    const [marketBets, setMarketBets] = useState([]);
    const [fancyBets, setFancyBets] = useState([]);

    const { openBets, getOpenBets } = useContext(ExchangeContexts);

    useEffect(() => {
        getOpenBets();
    }, [])
    // Filter bets with gameId
    useEffect(() => {
        if (openBets) {
            const filteredFancy = openBets.fancyBets?.filter((bet) => bet.gameId === gameId) || [];
            const filteredMarket = openBets.marketBets?.filter((bet) => bet.gameId === gameId) || [];
            setFancyBets(filteredFancy);
            setMarketBets(filteredMarket);
        }
    }, [openBets, gameId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            {/* <OpenBetsTable isAllBets={false} filteredBets={{ fancyBets, marketBets }} /> */}
            <OpenBetsDataTable isAllBets={false} filteredBets={{fancyBets,marketBets}}/>
        </>
    );
};

export default OpenBetsComponent;
