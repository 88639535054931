import React, { useEffect, useState } from 'react'
import { ReactComponent as Playbtn } from "../../Assets/new-exchange/play-button.svg";
import { ReactComponent as Info } from "../../Assets/new-exchange/info.svg";
import { Box, Grid, Pagination, Stack, Tab, Tabs, } from "@mui/material";
import "./Exchange.css"
import MarketComponent from './ExchangeSections/ExchangeDataSection/MarketComponent/MarketComponent';
import OpenBetsComponent from "./ExchangeSections/ExchangeDataSection/OpenBetsComponent/OpenBetsComponent"
import LiveSession from './ExchangeSections/ExchangeDataSection/LiveSession/LiveSession';
import { useLocation, useParams } from 'react-router-dom';
import { Exchange_APIs } from '../../API/Exchange_APIs';
import { ExchangeMarketDataContext } from "../../Contexts/ExchangeMarketContext"
import { useContext } from 'react';
import Loader from "../Loader/Loader"
import { toast } from 'react-toastify';
import { User_Context } from '../../Contexts/User';
import { ExchangeContexts } from '../../Contexts/ExchangeContext';
import MatchTeamsInfoCard from './ExchangeReusableCards/MatchTeamsInfoCard/MatchTeamsInfoCard';
import SportNewsComponent from './ExchangeSections/ExchangeDataSection/SportNewsComponents/SportNewsComponent';
function ExchangeCard() {
    const location = useLocation();
    const [Selected_Tab, Set_Selected_Tab] = useState(location.state && location.state.selectedTab ? location.state.selectedTab : "MARKET");
    const [loadig, setLoading] = useState(false);
    const [marketData, setMarketData] = useState([])
    const { code } = useParams();

    const { gameId, match } = location.state
    const { isInplay, setIsInplay } = useContext(ExchangeContexts);

    // import context for global state
    const useMarketContext = useContext(ExchangeMarketDataContext)
    const { User, Set_User } = useContext(User_Context);
    // callig api and render odds
    const getMatchOdds = () => {
        setLoading(true);
        // request for fetching match odds
        const API_RESPONSE = Exchange_APIs.GET_MATCH_ODDS(code, User.token);
        API_RESPONSE.then((response) => {
            
            // updating loader states
            setLoading(false);
            if (response.data) {
                useMarketContext.setMarketData(response.data.data)
            }
            if (response.data) {
                setMarketData(response.data.data)
            }
        })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message)
            })
    }

    useEffect(() => {
        getMatchOdds();
    }, [])



    const handleTabChange = (event, newValue) => {
        Set_Selected_Tab(newValue);
    };

    const TabPanel = ({ children, value, index }) =>
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>

    return (
        <>
            {
                loadig && <Loader />
            }

            <MatchTeamsInfoCard isInplay={isInplay} Set_Selected_Tab={Set_Selected_Tab} match={match} />

            {/* <div className='outer-bg'>
                <div className='outer-cont'>
                    {
                        !isInplay && <Playbtn onClick={()=>Set_Selected_Tab("LIVE")} className='svg-btn' />
                    }
                    <div className='play-spn'>
                        <span>
                            {match?.team1} vs {match?.team2}
                        </span>
                        <br />
                        <span className='svg-info'>
                            <Info />
                        </span>
                        <span className='spn-play'>
                            {match?.statsTitle}
                        </span>
                    </div>
                </div>
            </div> */}

            <div className="exchange-data-tabs">
                <button className="exchange-tab_"
                    onClick={() => Set_Selected_Tab("MARKET")} id={Selected_Tab === "MARKET" ? "active" : ""} >Market</button>
                <button className="exchange-tab_"
                    onClick={() => Set_Selected_Tab("NEWS")}
                    id={Selected_Tab === "NEWS" ? "active" : ""} >News</button>

                {!isInplay && <button className="exchange-tab_"
                    onClick={() => Set_Selected_Tab("LIVE")}
                    id={Selected_Tab === "LIVE" ? "active" : ""}  >Live
                </button>}

                <button className="exchange-tab_" onClick={() => Set_Selected_Tab("OPEN_BETS")}
                    id={Selected_Tab === "OPEN_BETS" ? "active" : ""} >
                    Open Bets
                </button>
            </div>


            {
                Selected_Tab === "MARKET" &&
                <div>
                    <MarketComponent gameId={gameId} gameCode={code} marketData={marketData} />
                </div>
            }

            {
                Selected_Tab === "NEWS" &&
                <SportNewsComponent />
            }


            {
                Selected_Tab === "LIVE" &&
                <div style={{ paddingTop: '0px' }}>
                    <LiveSession evId={match?.gameId} marketData={marketData} />
                </div>
            }
            {
                Selected_Tab === "OPEN_BETS" &&
                <div style={{ paddingTop: '0px' }}>
                    <OpenBetsComponent gameId={match?.gameId}  />
                </div>
            }







        </>
    )
}

export default ExchangeCard