import { API_Handler } from "./API_Handler";
import { encryptData } from "../Utilities/EncryptData";

export const Payment_APIs =
{
    Get_Payment_Methods: async (Request, Token) => await API_Handler.post('/payinAccounts/getAll', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Address: async (Request, Token) => await API_Handler.post('/transaction/getAddress', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Deposit: async (Request, Token) => await API_Handler.post(`/transaction/${Request.paymentGatewayName}`, { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Withdraw: async (Request, Token) => await API_Handler.post('/transaction/createWithdrawlRequest', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Transactions: async (Request, Token) => await API_Handler.post('/transaction/fetch', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    CancelWithdrawal: async (Request, Token) => await API_Handler.post('/transaction/cancel-withdrawal', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
}

