import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Casino_Game_Categories_Enums } from "../../Configuration/Enums/Casino_Game_Categories";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";

// Components
import MobileFooter from "../MobileFooter/MobileFooter";
import Login from "../Modals/Login";
import { Game_Context } from "../../Contexts/Games";

// Assets
import Envolope from "../../images/envolop.png";
import Whatsapp from "../../images/whatsapp-green.png";
import Telegram from "../../images/telegram-blue.png";
import Twitter from "../../images/twitter.png";
import Instagram from "../../images/instagram.png";
import Foot from "../../images/footer.png";

// Styles
import "../../css/footer1.css";

function AppFooter({ showSideNav }) {
    const [modalShow1, setModalShow1] = useState(false);
    const { Language, Set_Language } = useContext(Language_Context)
    const location = useLocation();
    const { pathname } = location ? location : {}
    const [obj, setState] = useState({
        home: true,
        satta: false,
        exchange: false,
        casino: false,
        slots: false,
    });
    const navigate = useNavigate();
    const { state: gameState, Set_State: setGameState } = useContext(Game_Context);
    const {
        whatsapp,
        telegram,
        instagram,
        twitter,
    } = gameState;

    useEffect(() => {
        let path = window.location.pathname;
        switch (path) {
            case "/": {
                setState({
                    home: true,
                    satta: false,
                    exchange: false,
                    casino: false,
                    slots: false,
                });
                break;
            }
            case "/satta": {
                setState({
                    home: false,
                    satta: true,
                    exchange: false,
                    casino: false,
                    slots: false,
                });
                break;
            }
            case "/exchange": {
                setState({
                    home: false,
                    satta: false,
                    exchange: true,
                    casino: false,
                    slots: false,
                });
                break;
            }
            case "/casino": {
                setState({
                    home: false,
                    satta: false,
                    exchange: false,
                    casino: true,
                    slots: false,
                });
                break;
            }
            default: {
                setState({
                    home: false,
                    satta: false,
                    exchange: false,
                    casino: false,
                    slots: true,
                });
            }
        }
    }, [0]);

    const navigateTo = (path) => {
        navigate(path);
    };



    const navigator = (path) => {
        navigate(`${path}`);
    };

    return (
        <div>
            <section id="pricing" className="pricing section-bg btmfootere">
                <footer>
                    <div className="mb-footer clearfix">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <h3>FAIRBETS VIP</h3>
                                <p>
                                    This website fairbets.co is owned and operated by Magicsystem Ltd.
                                    registration number: 152581, registered address: 9 Barrack Road, Belize City, Belize..

                                </p>
                                <p>
                                    Contact us info@fairbets.co. fairbets.co is licensed and regulated by the Government of the Autonomous Island of Anjouan,
                                    Union of Comoros and operates under License No.
                                </p>
                                <p>
                                    ALSI. fairbets.co has passed all regulatory compliance and is legally authorized to conduct gaming operations
                                    for any and all games of chance and wagering.
                                </p>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 social-footer">
                                <h5>CONTACT FAIRBETS</h5>
                                <p style={{ display: "block" }}>
                                    <a href="mailto:support@fairbets.co">
                                        <img alt="envelope" src={Envolope} style={{ width: 28 }} />{" "}
                                        support@fairbets.co
                                    </a>
                                </p>
                                <p className="social-inline">
                                    <a target="_blank" href={whatsapp}>
                                        <img alt="whatsapp" src={Whatsapp} className="soc-icon" />{" "}
                                        WhatsApp
                                    </a>
                                </p>
                                <p className="social-inline">
                                    <a target="_blank" href={telegram}>
                                        <img alt="telegram" src={Telegram} className="soc-icon" />{" "}
                                        Telegram
                                    </a>
                                </p>
                                <p className="social-inline">
                                    <a target="_blank" href={twitter}>
                                        <img alt="Twitter" src={Twitter} /> Twitter
                                    </a>
                                </p>
                                <p className="social-inline">
                                    <a target="_blank" href={instagram}>
                                        <img alt="Instagram" src={Instagram} className="soc-icon" />{" "}
                                        Instagram
                                    </a>
                                </p>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6">
                                <h5>RULES AND REGULATIONS</h5>
                                <ul className="rulr">
                                    <li>
                                        <a onClick={() => navigateTo("/terms-and-conditions")}>
                                            Terms and Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => navigateTo("/aml-policy")}>
                                            AML Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => navigateTo("/responsible-gaming")}>
                                            Responsible Gaming
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => navigateTo("/privacy-policy")}>
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => navigateTo("/terms-and-condition-netent")}>
                                            Terms and Conditions NetEnt
                                        </a>
                                    </li>
                                </ul>
                                <div
                                    data-apg-image-size={128}
                                    data-apg-image-type="basic-small"
                                    data-apg-seal-id="95481486-2e31-454b-bc94-b0c32624a772"
                                    id="apg-95481486-2e31-454b-bc94-b0c32624a772"
                                >
                                    <div
                                        style={{
                                            display: "block",
                                            position: "relative",
                                            overflow: "hidden",
                                            maxWidth: 128,
                                            minWidth: 32,
                                            backgroundImage:
                                                "url(https://95481486-2e31-454b-bc94-b0c32624a772.snippet.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png)",
                                        }}
                                    >
                                        <a
                                            target="_blank"
                                            rel="nonoopener"
                                            href="https://validator.antillephone.com/validate?domain=fairbets.co&seal_id=2f958e3b07ab0a9d02b9c189f30607a36103cd0282df3348228cb23cdd67242ccc56b77e798af4b09537dad9608d732d&stamp=5ab0c8dbf295ac4889c1f7448a327883"
                                        >
                                            <img
                                                className="footerimg"
                                                alt=""
                                                style={{ width: "100%", height: "auto" }}
                                                src={Foot}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6" />
                        </div>
                    </div>
                </footer>
            </section>
            {pathname !== '/deposit-mobile' &&
                <MobileFooter />
            }
        </div>
    );
}

export default AppFooter;