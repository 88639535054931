import React, { useContext, useEffect, useState } from 'react';
import "./PlaceMatchBetCard.css";
import Place_exchange_Bet_amounts from '../../../../Configuration/Enums/Place_exchange_Bet_amounts';
import { ExchangeContexts } from '../../../../Contexts/ExchangeContext';
import { Exchange_APIs } from '../../../../API/Exchange_APIs';
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { User_Context } from '../../../../Contexts/User';
import { Switch } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';

const PlaceMatchBetCard = ({ openedMatchPlaceBetSection, betData, match }) => {
    let location = useLocation();
    let gameId = location.state.gameId;
    const { User } = useContext(User_Context);

    const [odds, setOdds] = useState(0.1);
    const [stake, setStake] = useState(0);
    const [loading, setLoading] = useState(false);
    const [betConform, setBetConform] = useState(Cookies.get('betConform') === 'true');
    const [showConformBox, setShowConformbox] = useState(false);

    const usePlaceBetContext = useContext(ExchangeContexts);

    const handleConform = (event) => {
        const isChecked = event.target.checked;
        setBetConform(isChecked);
        Cookies.set('betConform', isChecked);
    };

    const onTogglePlaceMatchBetSection = (section) => {
        usePlaceBetContext.setActiveMatchPlaceBetSection({
            [section]: !usePlaceBetContext.activeMatchPlaceBetSection[section],
        });
    };



    const onBetPlacing = () => {
        if (betConform) {
            setShowConformbox(true);
        } else {
            setShowConformbox(false);
            onPlaceBet();
        }
    };

    const onPlaceBet = () => {
        if (!isNaN(odds) && stake !== 0 && odds !== '') {

            if (User.data.balance < stake) {
                setShowConformbox(false);
                toast.error("Insufficient balance to place the bet");
               
            } else {
                // if user balance is graterthen take then place bet
                setShowConformbox(false);
                let betDetails = null;
                if (betData.match?.marketType === "FANCY") {
                    betDetails = {
                        userId: User.data._id,
                        gameId: gameId,
                        amount: Number.parseInt(stake),
                        rate: odds,
                        isBack: betData?.isBack,
                        runner: betData.match?.runnerName,
                        marketType: betData.match?.marketType,
                        id: betData.match?.id,
                        marketName: betData.match?.runnerName,
                        run: betData.run
                    };
                } else {
                    betDetails = {
                        gameId: gameId,
                        userId: User.data._id,
                        amount: Number.parseInt(stake),
                        rate: odds,
                        id: betData.match?.id,
                        run: betData.run,
                        isBack: betData?.isBack,
                        marketType: betData.match?.marketType,
                        runner: betData.match?.runnerName,
                        marketName: betData.match?.runnerName
                    };
                }
                
                setLoading(true);
                let API_RESPONSE = betData.match?.marketType === "FANCY" ? Exchange_APIs.PLACE_FANCY_BET(betDetails, User.token) : Exchange_APIs.PLACE_MATCH_BET(betDetails, User.token);
                API_RESPONSE.then((response) => {
                    if (response.data.success) {
                        setLoading(false);
                        toast.success("match placed successfully");
                        onTogglePlaceMatchBetSection(openedMatchPlaceBetSection);
                    } else {
                        setLoading(false);
                        toast.error("something went wrong");
                    }
                }).catch((error) => {
                   
                    setLoading(false);
                    toast.error(error.message);
                    onTogglePlaceMatchBetSection(openedMatchPlaceBetSection);
                });
            }
        } else {
            setShowConformbox(false);
            if (odds === '' && stake === 0) {
                toast.info('Please enter match stake and odds');
            } else {
                if (isNaN(odds)) {
                    toast.info('Please enter valid match odds');
                } else if (stake === 0) {
                    toast.info('Please enter match stake');
                }
            }
        }
    };


    const handleIncrease = () => {
        if (odds < 1) {
            setOdds(prevOdds => Math.round((prevOdds + 0.1) * 10) / 10);
        }
    };

    const handleDecrease = () => {
        if (odds > 0.1) {
            setOdds(prevOdds => Math.round((prevOdds - 0.1) * 10) / 10);
        }
    };


    return <>
        {
            loading && <Loader />
        }
        <div className="place-match-bet-card-section">
            {
                showConformBox &&
                <div className="conform-box">
                    <div className="conform-message">Place Bet</div>
                    <div className="buttons">
                        <button onClick={onPlaceBet}>yes</button>
                        <button onClick={() => setShowConformbox(!showConformBox)}>No</button>
                    </div>
                </div>
            }

            <div className="place-match-bet-card-section-container">
                <div className="place-match-odds-and-match-stack-bet-fields">
                    <div className="odds-input-section">
                        <div className="odds-input-field-header">
                            <div className="odds-input-field-label">Odds</div>
                        </div>
                        <div className="odds-input-field">
                            <div className="odds-decrease-btn">
                                <button className='odds-decrease-button' onClick={handleDecrease}>-</button>
                            </div>
                            <div className="odds-bet-input">
                                <input
                                    type="number"
                                    placeholder='odds'
                                    value={odds}
                                    onChange={(e) => setOdds(parseFloat(e.target.value))} />
                            </div>
                            <div className="odds-increase-btn">
                                <button className='odds-increase-button' onClick={handleIncrease}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className="stack-input-section">
                        <div className="stack-input-section-labels-header">
                            <div className="stack-input-label">stake</div>
                            <div className="max-mkt-amount">Max Mkt: <span>25,00,000</span></div>
                        </div>
                        <div className="stake-input-field">
                            <input type="number"
                                placeholder='Max: 5,00,000'
                                value={stake}
                                onChange={(e) => setStake(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="bet-other-amounts-section">
                    <div className="bet-other-amounts-btns-container">
                        {Place_exchange_Bet_amounts.map((amount) => {
                            return <><button className='other-amount-button' onClick={() => setStake(amount.value)}>{amount.value}</button></>
                        })}
                    </div>
                </div>

                <div className="place-match-bet-buttons">
                    <div className="cancle-bet">
                        <button onClick={() => onTogglePlaceMatchBetSection(openedMatchPlaceBetSection)} className='cancle-bet-button'>cancle</button>
                    </div>
                    <div className="place-bet">
                        <button className='place-bet-button' onClick={onBetPlacing}>
                            <div className="place-bet-btn-labl">Place Bet</div>
                            <div className="place-bet-amount">Liability {stake}</div>
                        </button>
                    </div>
                </div>

                <div className="conform-bet-before-placing-message">
                    <div className="conform-bet-message">Confirm bet before placing</div>
                    <div class="form-check form-switch " style={{ marginTop: "10px" }}>
                        <input class="form-check-input custom-switch" checked={betConform} value={betConform} onChange={handleConform} type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                    </div>
                    {/* <div className="switch"><Switch checked={betConform} onChange={handleConform} disableRipple={true} /></div> */}
                </div>
            </div>
        </div>
    </>
};

export default PlaceMatchBetCard;
