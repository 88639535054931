import React from 'react';

// Assets
import { ReactComponent as ArrowRight } from "../../images/menu-icons/arrow-right.svg";

// styles
import './ComponentHeading.css'

function ComponentHeading({ title }) {
    return (
        <div className="topgms">
            <div className='component-heading-block' />
            <span className="spngame">
                {title?.toUpperCase()}
            </span>
        </div>
    )
}

export default ComponentHeading;