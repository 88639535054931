import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Logout_User from '../../Utilities/Logout';
import { Casino_APIs } from '../../API/Casino_APIs';
import usePageBottom from '../../Hooks/usePageBottom';
import { decryptData } from "../../Utilities/DecryptData";

// Context
import { User_Context } from '../../Contexts/User';

// Components
import Loader from '../../components/Loader/Loader';
import QTechCasinoTabs from '../../components/CasinoTabs/QTechCasinoTabs';
import QTechGameCard from '../../components/QTechGameCard/QTechGameCard';
import SearchBox from '../../components/SearchBox/SearchBox';
import Mini_Loader from '../../components/Mini_Loader/Mini_Loader';

// Styles
import './QTechCasino.css'

const itemsPerPage = 24;

function QTechCasinoOld() {
    const { User, Set_User } = useContext(User_Context);
    const isBottom = usePageBottom();
    const location = useLocation();
    const categoryFromQueryParameters = new URLSearchParams(location.search).get('data');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchGames, setSearchGames] = useState('');
    const [category, setCategory] = useState(categoryFromQueryParameters ? categoryFromQueryParameters.toUpperCase() : 'LIVECASINO');
    const [categories, setCategories] = useState([])

    const [gameList, setGameList] = useState([]);
    const [pageNo, setPageNo] = useState(1)
    const [nextListLoading, setNextListLoading] = useState(false);

    const changeCategory = (category) => {
        setNextListLoading(false)
        setPageNo(1)
        setCategory(category === null ? 'SLOT' : category)
        setGameList([])
        getGames(1, category === null ? 'SLOT' : category)
    }

    const getCategories = async () => {
        setLoading(true)
        try {
            const response = await Casino_APIs.Get_Qtech_Casino_Categories({}, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const finalData = decryptData(data)
                setCategories(finalData.payload)
                getGames(1, category === "LVIE-CASINO" ? "LIVECASINO" : category === "SLOTS" ? "SLOT" : "LIVECASINO");
            }
        } catch (error) {
            setLoading(false)
            setCategories([]);
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate('/')
            }
        }

    }

    const getGames = async (pageNumber, selectedCategory) => {
        setLoading(true)
        try {
            let payload = {
                pageNo: pageNumber,
                itemPerPage: itemsPerPage,
                category: selectedCategory
            }
            const response = await Casino_APIs.Get_Qtech_Casino_Games(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const finalDataList = decryptData(data)
                setGameList(finalDataList.payload.games)
                setPageNo(2)
            }


        } catch (error) {
            setLoading(false)
            setGameList([])
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate('/')
            }
        }
        setLoading(false)
    }

    const getNextListOfGames = async () => {
        setNextListLoading(true)
        try {
            let payload = {
                pageNo: pageNo,
                itemPerPage: itemsPerPage,
                category: category
            }
            const response = await Casino_APIs.Get_Qtech_Casino_Games(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                const finalDataList = decryptData(data)
                if (finalDataList.payload.games.length > 0) {
                    setNextListLoading(false)
                    setGameList([...gameList, ...finalDataList.payload.games])
                    setPageNo(pageNo + 1)
                } else {
                    setNextListLoading(false)
                }
                setNextListLoading(false)
            }

        } catch (error) {
            setNextListLoading(false)
            setLoading(false)
            setGameList([])
            if (error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate('/')
            }
        }
        setNextListLoading(false)
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (isBottom) {
            getNextListOfGames();
        }
    }, [isBottom])

    return (
        <>
            {loading && <Loader />}
            <div className='casino-container'>
                <div className='casino-inner-container'>
                    <SearchBox
                        searchGames={searchGames}
                        handleSearchGames={(event) => setSearchGames(event.target.value)}
                    />
                    <QTechCasinoTabs
                        changeCategory={changeCategory}
                        selectedCategory={category}
                        categories={categories}
                    />
                    <div className='casinoMainDv'>
                        <div className='row md-rwo tps colsp crvimgrst indTp'>
                            {!loading &&
                                gameList.length > 0 &&
                                gameList.map((item, index) => {
                                    if (item.images[2]) {
                                        return (
                                            <QTechGameCard
                                                key={index}
                                                data={item}
                                                user={User}
                                            />
                                        )
                                    } else {
                                        return null
                                    }

                                })
                            }

                        </div>
                    </div>
                    {nextListLoading && <Mini_Loader />}
                </div>
            </div>
        </>
    )
}

export default QTechCasinoOld;