import React from 'react';
import OtpInput from "otp-input-react";

// Components
import GeneralButton from '../Buttons/GeneralButton';

// Styles
import '../../css/signup.css'
import '../../css/otpform.css'

function OTPModal({ onChange, value, validOtp, onClick, remainingMinutes, remainingSeconds, closeOtpForm, closeButton, kyc }) {
    return (
        <div className="popup d-flex align-items-center justify-content-center">
            <div className="popup-content">
                <div className="labelotp1">
                    <span className="enterotp">Enter Your OTP</span>
                    {closeButton && <span onClick={closeOtpForm} className="closemodal">X</span>}
                </div>
                <div className="container">
                    <OtpInput
                        onChange={onChange}
                        value={value}
                        OTPLength={kyc ? 6 : 4}
                        otpType="number"
                        disabled={false}
                        autoFocus
                        className="opt-container otpinput "
                    ></OtpInput>
                    {validOtp ? (
                        <div></div>
                    ) : (
                        <div className="valid">
                            <span className="spnvalid">
                                Enter a valid OTP
                            </span>
                        </div>
                    )}
                    <div className="otpverify">
                        <GeneralButton
                            showText={true}
                            text={'Verify OTP'}
                            onClick={onClick}
                            className={"button-login"}
                        />
                    </div>
                    <div className="expires">
                        <span className="spnexp">
                            OTP expires in{" "}
                            <span className="clrspan">
                                {`${remainingMinutes}:${remainingSeconds}`}{" "}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OTPModal;