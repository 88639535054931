export const QTechUrlLobbyTypes = Object.freeze({
    Evolution: 'EVO-hindispeedbaccarat',
    Ezugi: 'EZU-rouletteportomaso2',
    SaGaming: 'SAG-lobby',
    Spribe: 'SPB-aviator',
    Beter: 'BTL-lobby',
    SevenMojos: '7ML-baccarata',
    Vivo: 'VGL-lobby',
    BetGames: 'BTV-lobby',
})

export const WacsUrlLobbyTypes = Object.freeze({
    Evolution: "EVOLUTION-topgames-lobby",
    Ezugi: "EZUGI-lobby",
    Spribe: 'SPRIBE-aviator',
    SevenMojos: '7MOJOS-bj-1',
    Vivo: 'VIVO-lobby-0',
    BetGames: 'BGAMES-betgames',
})
