import React from 'react';

// Assets
import ProfileIcon from '../../Assets/Icons/Profile_Icon.svg';
import ProfileIconMobile from '../../Assets/Icons/Profile-Header-Icon.svg'

// Styles
import './Buttons.css'

function ProfileButton({ text, onClick }) {

    const isTabOrMobile = window.matchMedia("(max-width: 600px)").matches;

    return (
        <div>
            {!isTabOrMobile &&
                <div
                    className='profile-button'
                    onClick={onClick}
                >
                    <p className='profile-button-text'>₹ {text}</p>
                    <img src={ProfileIcon} alt="fairbets" className='profile-button-icon' />
                </div>
            }
            {isTabOrMobile &&
                <div
                    className='profile-button-mobile'
                    onClick={onClick}
                >
                    <img src={ProfileIconMobile} alt='fairbets' className='profile-button-icon-mobile' />
                    <p className='profile-button-text-mobile'>₹ {text}</p>
                </div>
            }
        </div>

    )
}

export default ProfileButton;