import { API_Handler } from "./API_Handler";
import { encryptData } from "../Utilities/EncryptData";

export const Casino_APIs =
{
    Get_Lobby: async (Request, Token) => await API_Handler.post('/lobby', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Log_In_to_Jetfair: async (Request, Token) => await API_Handler.post('/jetfair/Login', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Bet_History: async (Request, Token) => await API_Handler.post('/User/bet-history', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Casino_Categories: async (Request, Token) => await API_Handler.post('/categorization', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_All_Games: async (Request, Token) => await API_Handler.post('/wacs/games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Games_from_Specific_Category: async (Request, Token) => await API_Handler.post('/wacs/games-category', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Log_In_to_Falcon: async (Request, Token) => await API_Handler.post('/falcon/login', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),

    Get_Qtech_Casino_Categories: async (Request, Token) => await API_Handler.post('/Qtech/fetch-category', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Get_Qtech_Casino_Games: async (Request, Token) => await API_Handler.post('/Qtech/fetch-games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Qtech_Game_Launch: async (Request, Token) => await API_Handler.post('/Qtech/game-launch', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Qtech_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),

    Evolution_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/evolution-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Ezugi_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/ezugi-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Sa_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/sAGamesLobby-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Spribe_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/spribe-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Beter_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/beter-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Mojos_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/mojos-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Vivo_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/vivo-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    BetGames_Lobby_URL: async (Request, Token) => await API_Handler.post('/Qtech/betGames-lobby-url', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),

    Qtech_Get_Game: async (Request, Token) => await API_Handler.post('/Qtech/games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Qtech_Get_Top_Games: async (Request, Token) => await API_Handler.post('/Qtech/top-games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Qtech_Get_Indian_Games: async (Request, Token) => await API_Handler.post('/Qtech/indian-games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),
    Qtech_Get_HighEarning_Games: async (Request, Token) => await API_Handler.post('/Qtech/high-earning-games', { token: encryptData(Request) }, { headers: { Authorization: `Bearer ${Token}` } }),

}