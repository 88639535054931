import React, { useContext } from 'react';
import { Translation_Table } from '../../Configuration/Translation';
import { Language_Context } from '../../Contexts/Language';

// Components
import GeneralButton from '../../components/Buttons/GeneralButton';
import LanguageDropdown from '../../components/LanguageDropdown/LanguageDropdown';

// styles
import './BonusWalletTerms.css'
import { useNavigate } from 'react-router-dom';

function BonusWalletTerms2() {
    const { Language, Set_Language } = useContext(Language_Context);
    const isTabOrMobile = window.matchMedia("(max-width: 650px)").matches;
    const navigate  = useNavigate();

    const handleOnClick = () => navigate('/deposit');
    const showStartEarningButton = !window?.location?.href?.includes('mobile');

    return (
        <div className={isTabOrMobile ? 'mx-3' : 'mx-5 mb-5'}>
            <div className='language-dropper-container'>
                <LanguageDropdown />
            </div>
            <div className='bonus-wallet-terms-header'>
                <p className='bonus-wallet-terms-header-text'>{Translation_Table.TermsandConditions[Language]?.toUpperCase() ?? ''}</p>
            </div>

            <div className='bonus-wallet-terms-body'>
                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms1[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms2[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms3[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms4[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms5[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.bonusFirstTerms6[Language]}</p>

                <p className='bonus-wallet-terms-text'>{Translation_Table.rules[Language]}</p>
                <p className='bonus-wallet-terms-text'>{Translation_Table.rules_1[Language]}</p>
                <p className='bonus-wallet-terms-text'>{Translation_Table.rules_2[Language]}.</p>
            </div>

            {
                showStartEarningButton ? 
                <div className='bonus-wallet-terms-button-container'>
                    <GeneralButton
                        onClick={handleOnClick}
                        showText={true}
                        text={'START EARNING'}
                    />
                </div>
                :
                null
            }
        </div>
    )
}

export default BonusWalletTerms2;