import React, { useContext } from 'react';
import { User_Context } from '../../Contexts/User';
import { Card, CardContent } from "@mui/material";
import config from '../../Utilities/Data_Formatting';
import { useNavigate } from 'react-router-dom';
import Logout_User from '../../Utilities/Logout';
import { Bonus_Wallet_APIs } from '../../API/Bonus_Wallet_API';
import { toast } from "react-toastify";

// Components
import GeneralButton from '../Buttons/GeneralButton';

// Styles
import './BonusWalletCard.css';


function BonusWalletCard({ item, showButton }) {
    const { _id, type, amount, status, firstDepositPercentage, bonusWalletClosingBalance, bonusWalletOpenBalance, referredBy, referralPercentage, createdOn } = item ? item : {}
    const { User, Set_User } = useContext(User_Context);
    const navigate = useNavigate();

    const cancelRequest = async () => {
        try {
            let payload = {
                userId: User.data?._id,
                _id: _id,
                amount: amount,
                status: 'reject',
                updatedBy: {
                    name: User.data.name,
                    _id: User.data?._id,
                    status: 'reject'
                }
            }
            const response = await Bonus_Wallet_APIs.cancelTransferRequests(payload, User.token)
            const { data, success } = response.data ? response.data : {}
            if (success) {
                toast.success(`Sucessfully Cancelled`);
                navigate('/profile')
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                Logout_User(Set_User)
                navigate("/")
            }
        }
    }
    return (
        <Card>
            <CardContent className="content-bet">
                <div className="bethis-card">
                    <div className="loss-bet">
                        <span className="text-bethis mx-2">{type === 'referralFirstDeposit' ? 'First Deposit Referral' : type === "userFirstDepositBonus" ? 'First Deposit Bonus' : type === "userBonusOtherDeposit" ? 'Bonus Other Deposit' : type === "referralOtherDeposit" ? 'Referral Other Deposit' : type === "transaction" ? 'Transaction' : ''}</span>
                    </div>
                </div>
                <div className="amount-bethis">
                    <span> {amount}</span>
                </div>

                <div className="bet-content-container">


                    <p className='bet-market-name mb-3'>Bonus Wallet Details:</p>

                    {/* <p className='bet-market-name'>{`First Deposit Percentage: ${firstDepositPercentage}%`}</p> */}

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Wallet Opening Balance: ${bonusWalletOpenBalance}`}</p>
                    </div>

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Wallet Closing Balance: ${bonusWalletClosingBalance}`}</p>
                    </div>

                    {/* <div className='mt-3'>
                        <p className='bet-market-name'>{`Referral Percentage: ${referralPercentage}%`}</p>
                    </div> */}

                    <div className='mt-3'>
                        <p className='bet-market-name'>{`Date: ${config.dateTimeComplete(config.Convert_Timestamp_to_the_Indian_Standard_Tome(createdOn))}`}</p>
                    </div>

                    {/* {referredBy &&
                        <div className='mt-3'>
                            <p className='bet-market-name'>{`Referred By: ${referredBy}`}</p>
                        </div>
                    } */}

                    {showButton &&
                        status !== "reject" && status !== "approve" && status !== "remove" &&
                        <div className='mt-3' style={{ marginInlineStart: '-7px' }}>
                            <GeneralButton
                                showText={true}
                                showSmall={true}
                                text={'Cancel Request'}
                                onClick={() => cancelRequest()}
                            />
                        </div>

                    }

                    {showButton &&
                        status === "reject" &&
                        <div className='mt-3' style={{ marginInlineStart: '-7px' }}>
                            <GeneralButton
                                showText={true}
                                showSmall={true}
                                Type={'Destructive_Action'}
                                text={'Request Cancelled'}
                            // onClick={() => cancelRequest()}
                            />
                        </div>

                    }

                    {showButton &&
                        status === "approve" &&
                        <div className='mt-3' style={{ marginInlineStart: '-7px' }}>
                            <GeneralButton
                                showText={true}
                                showSmall={true}
                                Type={'Approved'}
                                text={'Request Approved'}
                            />
                        </div>

                    }

                    {showButton &&
                        status === "remove" &&
                        <div className='mt-3' style={{ marginInlineStart: '-7px' }}>
                            <GeneralButton
                                showText={true}
                                showSmall={true}
                                Type={'Removed'}
                                text={'Request Rejected'}
                            />
                        </div>

                    }


                </div>

            </CardContent>
        </Card >
    )
}

export default BonusWalletCard;
