import { API_Endpoint } from "../Configuration/Settings";
import Axios from "axios";
import { clientName, getPlatform } from "../Utilities/Get_Platform_details";

export const API_Handler = Axios.create ({ baseURL: API_Endpoint });



// Add a request interceptor
API_Handler.interceptors.request.use(
    (config) => {
    
    
      // Modify the config to add custom headers
      config.headers['device-id'] = '';
      config.headers['app-version'] = '';
      config.headers['client-name'] = clientName
      config.headers['platform']=getPlatform();
      
      return config;
    },
    (error) => {
      // Handle the error
      return Promise.reject(error);
    }
  );

  