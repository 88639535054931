import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { User_Context } from "../../Contexts/User";
import { toast } from "react-toastify";
import Logout_User from "../../Utilities/Logout";
import { User_APIs } from "../../API/User_APIs";
import { Payment_APIs } from "../../API/Payment_APIs";
import { Language_Context } from "../../Contexts/Language";
import { Translation_Table } from "../../Configuration/Translation";

// Components
import GeneralButton from "../Buttons/GeneralButton";
import GeneralTextfield from "../Textfields/GeneralTextfield";
import WithdrawalWarning from "../WithdrawalWarning/WithdrawalWarning";
import Loader from "../Loader/Loader";

// Assets
import { ReactComponent as Crosssmall } from "../../images/svg/cross-svgrepo-com.svg";

// Styles
import "../../css/withdrawmodal.css";

const CancelWithdrawal = (props) => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    // const { User, Set_User } = useContext(User_Context);
	const { Language, Set_Language } = useContext(Language_Context)

    const handleModalClose = () => {
		props.onHide();
		
	}

  return (
   <>
   	<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="clsbtn">
					<span className="withd">
						{Translation_Table.withdrawalRequest[Language]}
					</span>
					<Crosssmall onClick={handleModalClose} className="cross-kyc" />
				</Modal.Header>
				<Modal.Body className="modal-withdraw">
					<div>
						{loading && <Loader />}
						<div class="modal-body payMain ouerMainDv" style={{ padding: "0" }}>
							<div className="tab-pane " id="withdraw" role="tabpanel">
								<div className="tab-content-tsp tab-table-data tabpne-with tpmar">
									<div className="withdraw_div">
										<div name="withdrawal_form_fill" className="withdrawal-popup-modal">
											<div className="col-12">
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															{/* <GeneralTextfield
																onChange={(e) => {
																	setWithdrawAmount(e.target.value);
																	setWithdrawError(false);
																	setWithdrawHelperText("");
																}}
																error={withdrawError}
																helperText={withdrawHelpertext}
																value={withdrawAmount}
																label={Translation_Table.WithdrawalAmount[Language]}
															/> */}
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															{/* <GeneralTextfield
																value={User.data.name}
																disabled={true}
															/> */}
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															{/* <GeneralTextfield
																value={User.data.bankName ? User.data.bankName : ""}
																disabled={true}
															/> */}

														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															{/* <GeneralTextfield
																value={User.data.accountNumber ? User.data.accountNumber : ""}
																disabled={true}
															/> */}
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-12">
														<div className="username-input mt-3">
															{/* <GeneralTextfield
																value={User.data.ifsc ? User.data.ifsc : ""}
																disabled={true}
															/> */}
														</div>

													</div>
												</div>
											</div>
											<div className="mt-3">
												{/* <GeneralButton
													onClick={withdrawal}
													showText={true}
													text={Translation_Table.submit[Language]}
												/> */}
											</div>
										</div>

									
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
   </>
  )
}

export default CancelWithdrawal
