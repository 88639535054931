import React from 'react'
import "./WatchLiveMatch.css"
import LiveSession from './LiveSession'
import staticLiveImage from "../../../../../Assets/new-exchange/Exchange-sports-images/liveMatch.png"
import ScoreCard from './ScoreCard'
import { useLocation, useParams } from 'react-router-dom'
const WatchLive = ({ evId,isIframe }) => {
  const location = useLocation();
  const { eventid } = useParams();
  // Determine the eventId based on the priority
  const eventId = evId || eventid || location.state?.eventId;
  let liveTVUri = `https://dpmatka.in/dcasino/nntv.php?MatchID=${eventId}`;
  return <>
    <div className="watch-live-section" style={{marginTop:isIframe?"80px":""}}>
      <div className="live-match-iframe">
        <iframe src={liveTVUri} frameborder="0"></iframe>
      </div>
      {/* <ScoreCard hideMatchScore={true} /> */}
    </div>
  </>
}

export default WatchLive
