import React from 'react'
// import Gif from "../images/GIF-1.gif";
import "../../css/loader.css"

const Loader = () => {
  return (
    <div className="loading-container">
      <div className="logo">FB</div>
      <div className="progress-circle"></div>
    </div>
  )
}

export default Loader