import React, { useState, useRef, useContext } from 'react';
import { Language_Context } from '../../Contexts/Language';
import Cookies from "universal-cookie";

// Assets
import { ReactComponent as LanguageIcon } from "../../Assets/Images/language.svg";
import { ReactComponent as DropperIcon } from "../../Assets/Images/dropper.svg";

// Styles
import './LanguageDropdown.css'

const TNC_LANG_LIST = {
    English: {
        title: "English",
        langCode: "en"
    },
    Hindi: {
        title: "Hindi",
        langCode: 'hi'
    },
    Telugu: {
        title: "Telugu",
        langCode: "te"
    },
    Tamil: {
        title: "Tamil",
        langCode: "ta"
    }
}

function LanguageDropdown() {
    const Cookie = new Cookies();
    const { Language, Set_Language } = useContext(Language_Context);
    const [openDropper, setOpenDropper] = useState(false);
    const langRef = useRef(null)

    const changeLanguage = (languageCode) => {
        Set_Language(languageCode);
        document.documentElement.lang = languageCode;
        Cookie.set('language', languageCode);
    }

    const LanguageListView = () => {
        return(
            <div>
                {
                    Object.keys(TNC_LANG_LIST).map(lang => {
                        const handleOnClick = () => changeLanguage(TNC_LANG_LIST[lang].langCode);
                        return(
                            <p onClick={handleOnClick} className={`language-text-alt ${TNC_LANG_LIST[lang].langCode === Language ? 'active-language' : ''}`}>{lang}</p>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className='language-dropper' ref={langRef}>
            <div className='language-dropper-inner' onClick={() => setOpenDropper(!openDropper)}>
                <LanguageIcon style={{ width: '25px' }} />
                <p className='language-text'>Language</p>
                <DropperIcon style={{ width: '40px' }} />
            </div>
            {openDropper ? <LanguageListView /> : null }
        </div>
    )
}

export default LanguageDropdown;